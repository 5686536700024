import apiTools from '../api-tools/index'

const apiEndpoint = 'listing'

import buildGetListingsWithOffset from './get-listings-with-offset_fetch'
import buildGetUserListings from './get-user-listings_fetch'
import buildGetSingleListing from './get-single-listing_fetch'
import buildPostListing from './post-listing_fetch'
import buildPatchListing from './patch-listing_fetch'
import buildPatchListingAccess from './patch-listing-access_fetch'
import buildRemoveListing from './remove-listing_fetch'

const getListingsWithOffset = buildGetListingsWithOffset({
    apiEndpoint,
    apiTools
})
const getUserListings = buildGetUserListings({
    apiEndpoint,
    apiTools
})
const getSingleListing = buildGetSingleListing({
    apiEndpoint,
    apiTools
})
const postListing = buildPostListing({
    apiEndpoint,
    apiTools
})
const patchListing = buildPatchListing({
    apiEndpoint,
    apiTools
})
const patchListingAccess = buildPatchListingAccess({
    apiEndpoint,
    apiTools
})
const removeListing = buildRemoveListing({
    apiEndpoint,
    apiTools
})

const listingApi = {
    getListingsWithOffset,
    getUserListings,
    getSingleListing,
    postListing,
    patchListing,
    patchListingAccess,
    removeListing
}

export default listingApi