import apiTools from '../api-tools/index'

const apiEndpoint = 'shop'

import buildPostShopInquiry from './post-shop-inquiry_fetch.js'
import buildPostShopData from './post-shop-data_fetch.js'
import buildPostInquiryConfirm from './post-inquiry-confirm_fetch.js'

const postShopInquiry = buildPostShopInquiry({
    apiEndpoint,
    apiTools
})
const postShopData = buildPostShopData({
    apiEndpoint,
    apiTools
})
const postInquiryConfirm = buildPostInquiryConfirm({
    apiEndpoint,
    apiTools
})

const shopInquiryApi = {
    postShopInquiry,
    postShopData,
    postInquiryConfirm
}

export default shopInquiryApi