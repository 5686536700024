export default function buildGetShopWallet({
    apiEndpoint,
    apiTools
}) {
    return async function getShopWallet(args) {
        const url = apiTools.resolveUrl({
            ...args,
            apiEndpoint: `${ apiEndpoint }/shop` 
        })
       
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json'
            },
            credentials: 'include',
        });
        return await res.json();
    }
}