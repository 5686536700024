export default function buildGetUserDeals({
    apiEndpoint,
    apiTools
}) {
    return async function getUserDeals(args = {}) {
        const url = apiTools.resolveUrl({
            ...args,
            apiEndpoint: `${ apiEndpoint }/user` 
        })
        
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json'
            },
            credentials: 'include',
        });
        return await res.json();
    }
}

// "isPaidCount": 29,
// "isShippedCount": 0,
// "isDeliveredCount": 0,
// "isNotFeedbackGivenCount": 19,
// "isFinalizedCount": 29,
// "isDisputedCount": 14,
// "isRejectedCount": 3,
// "isCancelledCount": 14