<template>
<div class="componentWrapper" v-on-clickaway="clickAway">
  <nav role="navigation">
    <div id="menuToggle">
      <input type="checkbox" @click="roundtripRegulator()" v-model="isActive"/>

      <span></span>
      <span></span>
      <span></span>

      <ul id="menu">
        <router-link 
            :to="{ path: '/pood/'+idShop }" 
            v-if="isUserFromGroup('seller')"
        >
            <div class="profileButton" @click="clickAway()">
                <img 
                    height="80px" 
                    width="80px" 
                    v-if="coverImg" 
                    :src="fetchIp+'/static/images/shops/'+coverImg" 
                    alt=""
                >
                <div class="name">
                    <p>{{shopName}}</p>
                    <a>Vaata enda poodi</a>
                </div>
            </div>
        </router-link>

        <router-link 
            :to="{ path: '/toolaud/pealeht' }" 
            v-if="isUserFromGroup('seller')"
        >
            <div class="dashboard" @click="clickAway()">
                <div class="iconCircle">
                    <img 
                        height="32px" 
                        width="32px" 
                        :src="require('@/assets/icons/menus/main/seller/dashboard.svg')"
                    />
                </div>
                <div class="dashName">
                    <p>Töölaud</p>
                    <a>Halda tellimusi, tooteid & kandeid</a>
                </div>
            </div>
        </router-link>

        <router-link 
            :to="{ path: '/toolaud/pealeht' }" 
            v-if="!isUserFromGroup('guest') && !isUserFromGroup('seller')"
        >
            <div class="dashboard" id="dashboardNone" @click="clickAway()">
                <div class="iconCircle">
                    <img 
                        height="32px" 
                        width="32px" 
                        :src="require('@/assets/icons/menus/main/dashboard.svg')"
                    />
                </div>
                <div class="dashName">
                    <p>Töölaud</p>
                    <a>Halda tellimusi, kandeid & teavitusi</a>
                </div>
            </div>
        </router-link>

        <router-link 
            :to="{ path: '/' }" 
            v-if="isUserFromGroup('guest')"
        >
            <div class="dashboard" id="dashboardNone" @click="clickAway(), $emit('routeChangeToHome')">
                <div class="iconCircle">
                    <img 
                        height="32px" 
                        width="32px" 
                        :src="require('@/assets/icons/menus/main/market.svg')"
                    />
                </div>
                <div class="dashName">
                    <p>Turg</p>
                    <a>Sirvi tooteid & avasta kauplejaid</a>
                </div>
            </div>
        </router-link>

        <router-link 
            :to="{ path: '/' }"
            v-if="!isUserFromGroup('guest') && !isUserFromGroup('seller')"
        >
            <div class="profileBoxBtn" @click="clickAway(), $emit('routeChangeToHome')">
                <div class="iconCircle">
                    <img 
                        height="30px" 
                        width="30px" 
                        :src="require('@/assets/icons/menus/main/market.svg')" 
                    />
                </div>
                <p>Turg</p>
            </div>
        </router-link>

        <router-link 
            :to="{ path: '/nimistu/poed' }"
            v-if="!isUserFromGroup('seller')"
        >
            <div class="profileBoxBtn" @click="clickAway()">
                <div class="iconCircle">
                    <img 
                        height="32px" 
                        width="32px" 
                        :src="require('@/assets/icons/menus/main/shopList.svg')"
                    />
                </div>
                <p>Poodide nimistu</p>
            </div>
        </router-link>

        <router-link 
            :to="{ path: '/orbiit' }"
            v-if="!isUserFromGroup('seller')"
        >
            <div class="profileBoxBtn" @click="clickAway()">
                <div class="iconCircle">
                    <img 
                        height="32px" 
                        width="32px" 
                        :src="require('@/assets/icons/menus/main/orbit.svg')" 
                    />
                </div>
                <p>Orbiit</p>
            </div>
        </router-link>

        <router-link 
            :to="{ path: '/tehingud/vastuvotmata' }"
            v-if="isUserFromGroup('seller')"
        >
            <div class="profileBoxBtn" @click="clickAway()">
                <div class="iconCircle">
                    <img 
                        height="32px" 
                        width="32px" 
                        :src="require('@/assets/icons/menus/main/seller/orders.svg')" 
                    />
                </div>
                <p>Tellimused</p>
            </div>
        </router-link>

        <router-link 
            :to="{ path: '/toolaud/pood' }"
            v-if="isUserFromGroup('seller')"
        >
            <div class="profileBoxBtn" @click="clickAway()">
                <div class="iconCircle">
                    <img 
                        height="32px" 
                        width="32px" 
                        :src="require('@/assets/icons/menus/main/seller/shopSettings.svg')" 
                    />
                </div>
                <p>Poe seaded</p>
            </div>
        </router-link>

        <router-link 
            :to="{ path: '/tooted/avalikud' }"
            v-if="isUserFromGroup('seller')"
        >
            <div class="profileBoxBtn" @click="clickAway()">
                <div class="iconCircle">
                    <img 
                        height="32px" 
                        width="32px" 
                        :src="require('@/assets/icons/menus/main/seller/products.svg')" 
                    />
                </div>
                <p>Minu tooted</p>
            </div>
        </router-link>

        <a 
            @click="$emit('openSupportPopup')"
            v-if="isUserFromGroup('seller')"
        >
            <div class="profileBoxBtn" @click="clickAway()">
                <div class="iconCircle">
                    <img 
                        height="32px" 
                        width="32px" 
                        :src="require('@/assets/icons/menus/main/seller/support.svg')"
                    />
                </div>
                <p>Kasutajatugi</p>
            </div>
        </a>

        <router-link 
            :to="{ path: '/sisselogimine' }"
            v-if="isUserFromGroup('guest')"
        >
            <div class="profileBoxBtn" @click="clickAway()">
                <div class="iconCircle">
                    <img 
                        height="32px" 
                        width="32px" 
                        :src="require('@/assets/icons/menus/main/login.svg')" 
                    />
                </div>
                <p>Minu kasutaja</p>
            </div>
        </router-link>

        <div 
            class="logoutBtn"
            v-if="!isUserFromGroup('guest')"
            @click="logout"
        >
            <p>Logi Välja</p>
        </div>
      </ul>
    </div>
  </nav>
</div>
</template>

<script>
import logoutFetch from '@/ApiFetches/logoutFetch'
import { mixin as clickaway } from 'vue-clickaway'

import userProfile from '@/ApiFetches/userProfile'
import core from '../../../api/index'
import roundtrip from '@/ApiFetches/roundtripRegulator'

export default {
  name: 'burgerMenuLogged',
  mixins: [ clickaway ],
  props: {
    role: {
      required: false
    },
  },
  data() {
    return {
      isActive: false,
      subCategoryActive: false,
      response: '',

      userGroups: [],
      idUser: null,
      shopName: null,
      idProducer: '',
      idShop: null,
      coverImg: null
    }
  },

  methods: {
    clickAway() {
        if(this.isActive) {
            this.isActive = false
        }
    },

    logout() {
      logoutFetch()
      .then((res) => {
          const respo = Object.values(res)
          this.response = respo
          this.isActive = false
          this.$router.push('/sisselogimine')
          this.$emit('reload')
          this.roundtripRegulator()
          this.clickAway()
      })
    },

    isUserFromGroup(group) {
      if(this.userGroups.some(grupp => grupp === group)) {
        return true
      } else {
        return false
      }
    },

    roundtripRegulator: async function() {
      roundtrip(this.$options.name)
      .then((res) => {
        this.userGroups = res.userGroups
        this.idUser = res.idUser
        if (this.isUserFromGroup('seller') == true) {
            core.shopApi.getUserShops()
            .then((res) => {
                if(res.data) {
                    this.shopName = res.data.shops[0].shopName
                    this.idShop = res.data.shops[0].idShop
                    this.coverImg = res.data.shops[0].coverImg
                }
            })
        } 
        if (!this.isUserFromGroup('seller') && !this.isUserFromGroup('guest') && !this.isUserFromGroup('producer')) {
            userProfile(this.idUser)
            .then((res) => {
                if(res.data) {
                    this.shopName = res.data.profile.displayName
                    this.coverImg = res.data.profile.coverImg
                }
            })
        }
        if(this.isUserFromGroup('producer')) {
            core.producerApi.getUserProducerData()
            .then((res) => {
                this.idProducer = res.data.producer.idProducer
                this.shopName = res.data.producer.name
                this.coverImg = res.data.producer.coverImg
            })
        }
      })
      .catch((err) => {
        this.catchError = err
      }) 
    },
  },

  created() {
      this.roundtripRegulator()
  }
}
</script>

<style scoped>
#menuToggle {
  display: block;
  position: absolute;
  top: 14px;
  z-index: 10000;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}
#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -6px;
  cursor: pointer;
  opacity: 0;
  z-index: 2; 
}
#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #697078;
  border-radius: 3px;
  z-index: 1;
  -webkit-transform-origin: 4px 0px;
      -ms-transform-origin: 4px 0px;
          transform-origin: 4px 0px;
  -webkit-transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  -o-transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}
#menuToggle span:first-child
{
  -webkit-transform-origin: 0% 0%;
      -ms-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}
#menuToggle span:nth-last-child(2)
{
  -webkit-transform-origin: 0% 100%;
      -ms-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}
#menuToggle input:checked ~ span
{
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
      -ms-transform: rotate(45deg) translate(-2px, -1px);
          transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
      -ms-transform: rotate(0deg) scale(0.2, 0.2);
          transform: rotate(0deg) scale(0.2, 0.2);
}
#menuToggle input:checked ~ span:nth-last-child(2)
{
  -webkit-transform: rotate(-45deg) translate(0, -1px);
      -ms-transform: rotate(-45deg) translate(0, -1px);
          transform: rotate(-45deg) translate(0, -1px);
}
#menu
{
  position: absolute;
  top: 20px;
  right: -284px;
  width: 340px;
  font-family: 'Baloo 2', cursive;
  overflow: auto;
  padding: 0px 0px 0px 0px;
  z-index: 30000;
  background: rgb(248, 248, 248);
  border-left: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
  border-radius: 0px 0px 0px 10px;
  /* -webkit-box-shadow: -4px 5px 3px 0px rgba(0, 0, 0, 0.157);
          box-shadow: -4px 5px 3px 0px rgba(0, 0, 0, 0.157); */
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  -webkit-transform-origin: 0% 0%;
      -ms-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transform: translate(125%, 0);
      -ms-transform: translate(125%, 0);
          transform: translate(125%, 0);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  -o-transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}
#menuToggle input:checked ~ ul {
  -webkit-transform: none;
      -ms-transform: none;
          transform: translate(-80%, 0);
}
.profileButton {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 10px;
    cursor: pointer;
}
.profileButton:hover {
    background: rgb(236, 236, 236);
}
.profileButton img {
    border-radius: 100px;
    margin-left: 14px;
    margin-top: 18px;
}
.profileButton p {
    font-size: 1.2rem;
    margin-top: 30px;
    margin-left: -30px;
    margin-bottom: -10px;
    color: rgb(30, 88, 93);
}
.profileButton a {
    margin-left: -30px;
    color: rgb(144, 144, 144);
}
.dashboard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    margin: 0 auto;
    border-top: 1px solid rgb(218, 218, 218);
    border-bottom: 1px solid rgb(218, 218, 218);
    padding-bottom: 10px;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
#dashboardNone {
    border-top: none;
}
.dashboard:hover {
    background: rgb(236, 236, 236);
    width: 100%;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    opacity: 1;
}
.dashboard p {
    font-size: 1.2rem;
    margin-left: 10px;
    margin-top: 18px;
    margin-bottom: -5px;
    color: rgb(79, 102, 102);
}
.dashboard a {
    font-size: .9rem;
    margin-left: 11px;
    color: rgb(155, 155, 155);
    font-family: 'DM sans', sans-serif;
}
.iconCircle {
    position: relative;
    background: rgb(222, 231, 235);
    border-radius: 60px;
    height: 40px;
    width: 50px;
    padding-top: 10px;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
}
.iconCircle img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    opacity: 1;
}
.name {
    text-align: center;
    width: 210px;
    margin-top: 10px;
    word-wrap: break-word;
}
.name p {
    word-wrap: break-word;
    width: 210px;
    margin: 20px auto;
    margin-bottom: 0px;
}
.name a {
    margin: -30px auto;
}
.profileBoxBtn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: rgb(79, 102, 102);
    font-size: 1.2rem;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 5px;
}
.profileBoxBtn p {
    margin-left: 10px;
    margin-top: 23px;
}
.profileBoxBtn a {
    color: rgb(79, 102, 102);
}
.profileBoxBtn:hover {
    background: rgb(238, 238, 238);
    border-radius: 10px;
    cursor: pointer;
}
.profileBoxBtn img {
    opacity: 1;
}
.logoutBtn {
    color: rgb(136, 136, 136);
    border-top: 1px solid rgb(204, 204, 204);
    width: 80%;
    height: 57px;
    margin: 5px auto;
    margin-bottom: 0px;
    text-align: center;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.logoutBtn:hover {
    background: rgb(238, 238, 238);
    width: 100%;
    border-radius: 0px 0px 0px 7px;
    cursor: pointer;
}
.logoutBtn img {
    margin-left: 5px;
    margin-top: 10px;
    margin-right: 15px;
    opacity: 0.6;
}
</style>
