<template>
    <div class="componentWrapper">
        <div 
            class="notifications"
            :class="{ 'notificationsSeller': isUserFromGroup('seller'), 'notificationsGuest': isUserFromGroup('guest') }"
            v-if="isIconActive"
        >
            <img 
                @click="notif"
                v-if="!notIcon"
                height="30px" 
                width="30px" 
                style="opacity: 1"
                :src="require('@/assets/icons/menus/notificationIco.svg')"
            />
            <img 
                @click="notif"
                v-if="notIcon"
                height="30px" 
                width="30px" 
                style="opacity: 1"
                :src="require('@/assets/icons/menus/notificationIcoActive.svg')"
            />
            <div 
                @click="notif"
                class="newUpdateNotifs" 
                v-if="newNotificationList.length > 0"
            >   <p>{{this.newNotificationList.length}}</p></div>
            
        </div>
        <div class="popupContainer" :style="{ 'position': containerPosition, 'top': topMargin, 'right': rightMargin }" v-if="isNotifPopupActive" v-on-clickaway="clickAway">
            <div class="tabHeader" v-if="newNotificationList.length">
                <p v-if="this.newNotificationList.length == 1">{{this.newNotificationList.length}} Lugemata Teavitus</p>
                <p v-if="this.newNotificationList.length > 1">{{this.newNotificationList.length}} Lugemata Teavitust</p>
            </div>
            <div class="allMessagesBox" :class="{ 'allMessagesBoxEmpty':  !newNotificationList.length}">
                <img 
                    v-if="!newNotificationList.length"
                    height="120px" width="120px" 
                    style="margin-top: 35px; margin-bottom: -95px"
                    :src="require('@/assets/icons/menus/notificationSilver.svg')"
                />
                <h2 
                    v-if="!newNotificationList.length" 
                    style="text-align: center; font-size: 1.8rem; color: silver; margin: 70px auto; margin-top: 120px; margin-bottom: 0px"
                >   Lugemata Teavitused Puuduvad</h2>
                <button 
                    class="viewAllBtn"
                    @click="notif(); $router.push({'name': 'notifications'})"
                    v-if="!newNotificationList.length && !isUserFromGroup('guest')" 
                >   Vaata kõiki teavitusi</button>
                <div 
                    class="firstMessage" 
                    v-for="element of newNotificationList" 
                    @click="clearNotifs(element.idNotification)"
                    :key='element.id'
                >
                    <p class="subject">
                        <b>{{element.subject}}</b>
                    </p>
                    <p 
                        class="notificationBody">
                        {{element.text}}
                    </p>
                    <div class="date">
                        {{$dateLibrary.formatDate(element.timestamp, 'D. MMMM (HH:mm)')}}
                    </div>
                </div>
            </div>
            <p 
                class="allMessages" 
                v-if="newNotificationList.length && !isUserFromGroup('guest')"
                @click="clearAllNotifs()"
            >   Märgi kõik loetuks</p>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import core from '../../../api/index'
import markForwarded from '@/ApiFetches/markForwarded'

export default {
    name: 'notificationPopup',
    mixins: [ clickaway ],
    props: {
        isMobileActive: {
            default: false
        },

        containerPosition: {
            default: 'absolute'
        },

        topMargin: {
            default: '70px'
        },

        rightMargin: {
            default: '7%'
        },

        isIconActive: {
            default: true
        },

        isNotifPopupActive: {
            required: true
        },

        userGroups: {
            default: false
        }
    },
    data() {
        return {
            notifications: false,
            guestNotification: false,
            notIcon: false,
            notifMessages: '',
            notificationCount: 1,
            notificationId: null,
            idNotifications: [],
            newNotifMessage: [],
            newNotsActive: false,
            notifMessageActive: false,
            newNotificationList: [],
            slicedNotificationList: [],
            isOptionsMenuActive: false
        }
    },

    methods: {
        clickAway() {
            if(this.guestNotification && this.isNotifPopupActive) {
                this.addToLocalstorage()
                this.newNotificationList = []
            }
            this.notifications = false
            this.notIcon = !this.notIcon

            this.$emit('openPopup', 'notification')
        },

        notif() {
            this.$emit('openPopup', 'notification')
            this.notIcon = !this.notIcon
        },

        getUserNots() {
            this.newNotificationList = []
            this.slicedNotificationList = []
            if(!this.isUserFromGroup('guest')) {
                core.notificationApi.getUserNotifications({
                    queryParams: {
                        isForwarded: false
                    }
                })
                .then((res) => {
                    if(res.data.notifications) {
                        this.newNotificationList = res.data.notifications
                        this.$emit('notificationsCount', this.newNotificationList.length)
                    }
                    this.newNotificationList.sort()
                })
            }
        },

        getNotification(data) {
            var audio = new Audio(require('@/assets/sounds/juntos.mp3'));
            audio.play();
            this.$notify({
                group: 'notification',
                type: 'success',
                title: `${data.subject}`,
                text:  `${data.text}`
            });
            this.getUserNots()         
        },

        clearNotifs(idNotification) {
            if(this.isUserFromGroup('guest')) {
                if(this.guestNotification) {
                    this.addToLocalstorage()
                    this.newNotificationList = []
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Teadaanne märgitud loetuks!',
                    });
                    this.$emit('notificationsCount', 0)
                }
            } else {
                markForwarded([idNotification])
                .then((res) => {
                    if(res.status === 'success') {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            title: 'Teadaanne märgitud loetuks!',
                        });
                        this.getUserNots()
                    }
                })
            }
        },

        clearAllNotifs() {
            let unreadNotifs = this.newNotificationList.map((notif) => notif.idNotification)
            markForwarded(unreadNotifs)
            .then((res) => {
                if(res.status === 'success') {
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Kõik teadanded märgitud loetuks!',
                    });
                    this.getUserNots()
                    this.isOptionsMenuActive = false
                }
                if(res.status == 'error') {
                    this.$notify({
                        group: 'foo',
                        type: 'warn',
                        title: 'Teadanded on juba loetud!',
                    });
                    this.isOptionsMenuActive = false
                }
            })
        },

        addToLocalstorage() {
            localStorage.setItem('notificationSeen', true)
        },

        isUserFromGroup(group) {
            if(this.userGroups.some(grupp => grupp === group)) {
                return true
            } else {
                return false
            }
        },
    },

    created() {
        if(this.isUserFromGroup('guest')) {
            let isNotificationSeen
            isNotificationSeen = localStorage.getItem('notificationSeen')
    
            if(isNotificationSeen == 'true') {
                this.newNotificationList = []
            } else {
                this.guestNotification = true
                this.newNotificationList = [{
                    subject: 'Tere tulemast Omatoodang.ee platvormile',
                    text: 'Avasta kodumaist toodangut ning telli tooteid otse tootjalt.',
                    timestamp: Date.now()
                }]
                this.$emit('notificationsCount', 1)
            }
        } else {
            this.getUserNots()
        }
    }
       
}
</script>

<style scoped>
.componentWrapper {
    position: relative;
    z-index: 100000;
}
.popupContainer {
    position: relative;
    border-radius: 15px;
    width: 650px;
    height: 359px;
    top: 75px;
    right: 7%;
    background: white;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    z-index: 140;
    overflow: hidden;
}
.viewAllBtn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    margin: auto;
    height: 35px;
    padding-top: 1px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #3d405600;
    border-radius: 3px;
    background: #3D4056;
    color: #EEEEEE;
    font-size: 1rem;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
}
.viewAllBtn:hover {
    background: #3D4056;
    color: #EEEEEE;
    opacity: .9;
}
.allMessagesBox {
    height: 275px;
    overflow-y: scroll;
    scrollbar-width: thin;
}
.allMessagesBox h2 {
    padding-left: 15px;
    padding-right: 15px;
}
.allMessagesBoxEmpty {
    text-align: center;
}
.firstMessage {
    padding-bottom: 5px;
    padding-left: 15px;
    padding-top: 10px;
    background: white;
    border-bottom: 0.1px solid rgb(240, 240, 240);
}
.firstMessage:hover {
    background: rgb(247, 247, 247);
    cursor: pointer;
}
.firstMessage:hover .showMoreBtn {
    opacity: 1;
}
.subject {
    color: rgb(3, 124, 114);
    font-family: 'DM sans', sans-serif;
    font-weight: 300;
    font-size: 1.05rem;
    padding-right: 10px;
    margin: 0;
}
.date {
    margin: 0;
    margin-top: 5px;
    font-size: .9rem;
    padding-right: 10px;
    color: rgb(190, 190, 190);
    text-align: end;
}
.notificationBody {
    font-size: 1rem;
    color: rgb(75, 75, 75);
    margin: 0;
    margin-top: 5px;
    padding-right: 10px;
    word-break: inherit;
    font-weight: normal;
    overflow-wrap: break-word;
    font-family: 'DM sans', sans-serif;
}
.allMessages {
    position: absolute;
    z-index: 100;
    text-align: center;
    color: rgb(120, 156, 156);
    background: white;
    margin-top: 0px;
    bottom: -18px;
    width: 100%;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid rgb(231, 231, 231);
}
.notifications img {
    position: absolute;
    top: 57px;
    right: 97px;
    cursor: pointer;
}
.newUpdateNotifs {
    position: absolute;
    right: 92px;
    top: 49px;
    height: 18px;
    width: 18px;
    border: 1px solid rgba(73, 100, 98, 0);
    background: rgba(255, 82, 82, 1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    -webkit-animation: pulse-red 2s infinite;
            animation: pulse-red 2s infinite;
    border-radius: 50%;
    text-align: center;
    color: white;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}
.notificationsSeller img {
    right: 58px;
}
.notificationsSeller .newUpdateNotifs {
    right: 53px;
}
.notificationsGuest img {
    right: 98px;
}
.notificationsGuest .newUpdateNotifs {
    right: 93px;
}
.newUpdateNotifs p {
    margin: 0;
    margin-top: -3px;
    font-weight: bold;
    font-size: 1.1rem;
}
.tabHeader {
    position: relative;
    border-bottom: 1px solid #62bd9d;
    background: #62bd9d;
    height: 40px;
}
.tabHeader p {
    margin: 0;
    padding-top: 6px;
    text-align: center;
    color: white;
    font-size: 22px;
    font-family: 'Lato', sans-serif;
}
.messages {
    margin: 25px 20px 0px 0px;
    opacity: 1
}
.messages:hover {
    opacity: 1;
    cursor: pointer;
    -webkit-transition: .1s ease-out;
    -o-transition: .1s ease-out;
    transition: .1s ease-out
}

@-webkit-keyframes pulse-red {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-red {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
@media (max-width: 1000px) {
    .popupContainer {
        left: -530px
    }
}
@media (max-width: 750px) {
    .popupContainer {
        width: 95%;
        left: 0px;
        border-radius: 15px;
        left: 50%;
        -webkit-transform: translate(-50%,0%);
        -ms-transform: translate(-50%,0%);
            transform: translate(-50%,0%);
    }
}
</style>
