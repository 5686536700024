import getUrl from './getUrl.js';

export default function loginFetch(username, password) {
  return fetch(`${getUrl()}/v2/auth/password`, {
    method: 'POST',
    headers: {
      'Content-type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({"email": username, "password": password})
  }).then(res => res.json())
}