<template>
    <div :class="{'footerMainHidden': isFooterHidden, 'footerMain': !isFooterHidden}">
        <div class="copyright">
            <p>©{{new Date().getFullYear()}} Storium</p>
        </div>
        <div style="display: flex" v-if="!isApplicationPageActive">
            <div class="dot"></div>
            <div class="singleButton">
                <router-link :to="{ path: '/tingimused/privaatsus' }"><p>Privaatsus</p></router-link>
            </div>
            <div class="dot"></div>
            <div class="singleButton">
                <router-link :to="{ path: '/tingimused/kasutus' }"><p>Tingimused</p></router-link>
            </div>
            <div class="dot" id="mainPageDot"></div>
            <div class="singleButton" id="mainPageBtn">
                <router-link :to="{ path: '/' }"><p @click="routeChangeToHome">Turg</p></router-link>
            </div>
        </div>
        <div class="rightButtons" v-if="!isApplicationPageActive">
            <div class="navigation" @click="isNavigationsActive = true; $emit('activateBackground')">
                <p>Navigatsioonid</p>
                <img 
                    class="downArrow"
                    height="20px"
                    width="20px"
                    :src="require('@/assets/icons/categories/down-arrow(2).svg')" 
                    alt=""
                >
            </div>
        </div>

        <div :class="{ 'navigationsVisible': isNavigationsActive, 'navigations': !isNavigationsActive }">
            <img 
                class="originalLogo"
                :src="require('@/assets/icons/footer/minLogo.svg')" 
                alt=""
            >
            <div class="columnWrapper">
                <div class="column">
                    <h2 style="margin-bottom: 10px">Kontakt</h2>
                    <a>OÜ Omatoodang.ee</a>
                    <br>
                    <a>Savi 3 86501, Pärnumaa</a>
                    <br>
                    <a>info@omatoodang.ee</a>
                </div>
                <div class="column">
                    <h2>Storium</h2>
                    <router-link :to="{ path: '/tingimused/kasutus' }"><p @click="closeNavigations">Kasutustingimused</p></router-link>
                    <router-link :to="{ path: '/tingimused/privaatsus' }"><p @click="closeNavigations">Privaatsustingimused</p></router-link>
                </div>
                <div class="column" v-if="isUserFromGroup('guest')">
                    <h2>Kasutaja</h2>
                    <router-link :to="{ path: '/sisselogimine' }"><p @click="closeNavigations">Logi sisse</p></router-link>
                    <router-link :to="{ path: '/registreeru' }"><p @click="closeNavigations">Registreeru</p></router-link>
                </div>
                <div class="column" v-if="!isUserFromGroup('guest')">
                    <h2>Kasutaja</h2>
                    <router-link :to="{ path: '/toolaud/pealeht' }"><p @click="closeNavigations">Töölaud</p></router-link>
                    <router-link :to="{ path: '/toolaud/tehingud' }"><p @click="closeNavigations">Tellimused</p></router-link>
                    <a><p @click="closeNavigations(); userSupportPopup = true">Kasutajatugi</p></a>
                </div>
                <div class="column">
                    <h2>Navigeeri</h2>
                    <router-link :to="{ path: '/' }"><p @click="closeNavigations, routeChangeToHome">Turg</p></router-link>
                    <router-link :to="{ path: '/orbiit' }"><p @click="closeNavigations">Orbiit</p></router-link>
                </div>
            </div>
            <img 
                class="closeBtn"
                @click="closeNavigations"
                height="20px"
                width="20px"
                :src="require('@/assets/icons/categories/close(2).svg')" 
                alt=""
            >
        </div>
        <supportPopup
            v-if="userSupportPopup"
            @closeContainer="userSupportPopup = false"
        />
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import supportPopup from '@/views/orbit/components/supportPopup.vue'

export default {
    name: 'smallFooter',
    components: {
        supportPopup,
    },
    mixins: [ clickaway ],
    props: {
        userGroups: {
            required: true
        },
        isApplicationPageActive: {}
    },
    data() {
        return {
            isFooterHidden: true,
            isNavigationsActive: false,
            userSupportPopup: false
        }
    },
    methods: {
        closeNavigations() {
            this.isNavigationsActive = false
            this.$emit('deactivateBackground')
        },

        routeChangeToHome() {
            if(this.$route.path === '/') {
                this.$emit('homeNavigation')
            }
        },

        isUserFromGroup(group) {
            if(this.userGroups.some(grupp => grupp === group)) {
                return true
            } else {
                return false
            }
        },
    },

    created() {
        setTimeout(() => {
            this.isFooterHidden = false
        }, 1000)
    }
}
</script>
<style scoped>
.footerMain {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 99901;
    bottom: 0;
    height: 40px;
    width: 100%;
    background: rgb(252,252,252);
    border-top: 1px solid rgb(235,235,235);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.footerMainHidden {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 1000;
    bottom: -50px;
    height: 40px;
    width: 100%;
    background: rgb(252,252,252);
    border-top: 1px solid rgb(235,235,235);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.copyright {
    position: relative;
    margin-left: 25px;
    width: 180px;
}
.copyright p {
    position: absolute;
    color: rgb(120,120,120);
    height: 22px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 14px;
}
.dot {
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 18px;
    width: 3px;
    height: 3px;
    border-radius: 100px;
    background: rgb(150, 150, 150);
}
.singleButton p {
    margin: 0;
    font-size: 14px;
    margin-top: 9.4px;
    color: rgb(120, 120, 120);
    font-weight: bold;
    cursor: pointer;
}
.singleButton p:hover {
    text-decoration: underline;
}
.rightButtons {
    position: absolute;
    right: 25px;
}
.navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    margin-top: 9px;
}
.navigation p {
    margin: 0;
    margin-top: 0px;
    font-size: 16px;
    color: rgb(120, 120, 120);
    font-weight: bold;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
}
.navigation img {
    rotate: 180deg;
    margin-left: 10px;
    margin-top: -1px;
    opacity: .8;
    cursor: pointer;
}

.navigations {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    bottom: -550px;
    z-index: 10000;
    background: white;
    opacity: 0;
    width: 100%;
    border-radius: 30px 30px 0px 0px;
    padding-top: 40px;
    padding-bottom: 70px;
    -webkit-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
}
.navigationsVisible {
    position: absolute;
    z-index: 10000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    bottom: 0;
    background: white;
    width: 100%;
    border-radius: 30px 30px 0px 0px;
    padding-top: 40px;
    padding-bottom: 70px;
    -webkit-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
}
.originalLogo {
    position: absolute;
    left: 135px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 150px;
    opacity: .0;
}
.closeBtn {
    position: absolute;
    top: 25px;
    right: 35px;
}
.closeBtn:hover {
    cursor: pointer;
    opacity: .8;
}
.columnWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 1000px;
    margin: 0 auto;
}
.column {
    margin: 0 auto;
}
.column h2 {
    margin: 0;
    margin-bottom: -11px;
    font-size: 1.1rem;
    color: rgb(35,35,35);
}
.column p {
    margin: 0;
    margin-bottom: -2px;
    margin-left: 1px;
    font-size: 15px;
    color: rgb(75,75,75);
}
.column p:hover {
    text-decoration: underline;
    cursor: pointer;
}
.column a {
    margin-bottom: 7px;
    margin-left: 1px;
    font-size: 15px;
    color: rgb(100,100,100);
}

.background {
    position: absolute;
    bottom: 0;
    left: 0;
    background: black;
    height: 100%;
    width: 100%;
}

@media (max-width: 750px) {
    .footerMain {
        position: relative;
        z-index: 0;
    }
    .rightButtons {
        display: none;
    }
    .copyright {
        margin-left: 20px;
    }
    .dot {
        margin-right: 8px;
        margin-left: 8px;
    }
    
}
@media (max-width: 500px) {
    #mainPageBtn {
        display: none;
    }
    #mainPageDot {
        display: none;
    }
}
@media (max-width: 430px) {
    .copyright {
        margin-left: 15px;
        width: 185px;
    }
    .dot {
        margin-right: 6px;
        margin-left: 6px;
    }
}
@media (max-width: 390px) {
    .singleButton {
        display: none;
    }
    .dot {
        display: none;
    }
    .copyright {
        position: absolute;
        right: 0px;
        bottom: 0;
        top: 0;
        margin: auto;
        left: auto;
        width: 165px;
    }
}
</style>
