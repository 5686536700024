import apiTools from '../api-tools/index'

import buildPostShopChat from './post-shop-chat'
import buildGetUserChats from './get-user-chats'


const apiEndpoint = 'chat'

const postShopChat = buildPostShopChat({
    apiEndpoint,
    apiTools
})
const getUserChats = buildGetUserChats({
    apiEndpoint,
    apiTools
})


const chatApi = {
    postShopChat,
    getUserChats
}

export default chatApi