<template>
    <body>
        <div id="app">
            <loading 
                :active.sync="$root.loading" 
                :can-cancel="false" 
                :opacity='1'
                :loader='"dots"'
                :color='"rgb(24, 110, 117)"'
                :is-full-page="true"></loading>
            
            <div class="nonVerified" v-if="isUserFromGroup('non-verified') && nonVerifiedNotice">
                <p>Palun kinnita oma e-posti aadress, klikkides kinnitamise nupul mille saatsime su postkasti.</p>
                <img 
                    class="cross"
                    @click="closeNonVerifiedNotice"
                    height="20px"
                    width="20px"
                    :src="require('@/assets/utility/cross.svg')" 
                    alt=""
                >
            </div>
                <mobileHeadNav
                    :role='role'
                    :currentPage="this.$route.name"
                    :userGroups="userGroups"
                    :idUser="idUser"
                    :isApplicationPageActive="isApplicationPageActive"
                    ref="mobileHeadNav"
                    class="mobileHeadNav"
                    @reload='roundtripRegulator()'
                    @activateBackground='isMobileOrderPopupActive = true'
                    @deactivateBackground='isMobileOrderPopupActive = false'
                    @openSupportPopup='userSupportPopup = true'
                    @homeNavigation='homeNavigation'
                    v-if="isMobileActive && userGroups.length > 0"
                />
    
            <div class="small-nav" v-if="!isMobileActive">
                <topMenuLogged 
                    ref='topMenu'
                    @activateBackground='isOrderPopupActive = true'
                    @deactivateBackground='isOrderPopupActive = false'
                    @listingDeletedFromOrder='listingDeletedFromOrder' 
                    @reload='roundtripRegulator()'
                    @openSupportPopup='userSupportPopup = true'
                    @homeNavigation='homeNavigation'
                    :fixedScrollPos="fixedScrollPos"
                    :hideMenuScrollPos="hideMenuScrollPos"
                    :isFixedMenuActive="isFixedMenuActive"
                    :isApplicationPageActive="isApplicationPageActive"
                    :currentActivePage="currentActivePage"
                    :categoryType="categoryType"
                    v-if="(isUserFromGroup('base'))" 
                ></topMenuLogged>

                <smallNavBarMain 
                    ref="topMenuGuest"
                    @activateBackground='isOrderPopupActive = true'
                    @deactivateBackground='isOrderPopupActive = false'
                    @homeNavigation='homeNavigation'
                    :fixedScrollPos="fixedScrollPos"
                    :hideMenuScrollPos="hideMenuScrollPos"
                    :isFixedMenuActive="isFixedMenuActive"
                    :currentActivePage="currentActivePage"
                    :categoryType="categoryType"
                    v-if="(isUserFromGroup('guest'))" 
                ></smallNavBarMain>
            </div>
            
            <router-view :key="$route.fullPath"
                v-if="!$root.loading"
                ref="routerView"
                :isListingDeletedFromOrder='isListingDeleted'
                :isHomeNavActive="isHomeNavActive"
                @viewOrderListing='viewOrder'
                @emitMenuReload='emitMenuReload()'
                @emitMenuNotifReload='emitMenuNotifReload()'
                @emitMenuRoundtrip='emitMenuRoundtrip()'
                @emitMenuChatsReload='emitMenuChatsReload()'
                @reload='roundtripRegulator()'
                @openSupportPopup='userSupportPopup = true'
                @openOrderPopup="openOrderPopup"
             />
            <notifications group="foo" animation-type="velocity" position='top center' />
            <notifications group="warnings" animation-type="velocity" position='top center' />
            <notifications group="order" animation-type="velocity" position='top center' />      
            <notifications group="notification" :duration='10000' width='350' animation-type="velocity" position='top center' /> 
            <notifications group="notificationMobile" :duration='10000' width='350' animation-type="velocity" position='top center' /> 
    
            <div>
                <cookieConsent v-if="!cookieConsent" @cookieConsentAccepted='cookieConsent = true' />
            </div>
    
            <div>
                <pagefooter
                    ref="pageFooter"
                    @activateBackground='isNavigationsActive = true'
                    @deactivateBackground='isNavigationsActive = false'
                    @homeNavigation='homeNavigation'
                    :userGroups='userGroups'
                    :isApplicationPageActive="isApplicationPageActive"
                />  
            </div>

            <div :class="{ 'darkBackgroundHidden': !isNavigationsActive, 'darkBackground': isNavigationsActive  }" @click="deactivateFooterNav"></div>
            <div :class="{ 'blurBackgroundHidden': !isOrderPopupActive, 'blurBackground': isOrderPopupActive  }" @click="deactivateOrderPopup"></div>
            <div :class="{ 'blurBackgroundHidden': !isMobileOrderPopupActive, 'blurBackground': isMobileOrderPopupActive  }"></div>
            <div :class="{ 'blurBackgroundHidden': !isNotifPopupActive, 'blurBackground': isNotifPopupActive  }" @click="deactivateNotifPopup"></div>
        </div>
        <supportPopup
            v-if="userSupportPopup"
            @closeContainer="userSupportPopup = false"
        />
    </body>
</template>
<script>
import topMenuLogged from '@/views/menus/topMenuLogged.vue'
import cookieConsent from '@/views/menus/components/cookieConsent.vue'
import smallNavBarMain from '@/views/menus/smallNavBarMain.vue'
import pagefooter from '@/views/menus/footers/smallFooter.vue'
import supportPopup from '@/views/orbit/components/supportPopup.vue'

import roundtrip from '@/ApiFetches/roundtripRegulator'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import mobileHeadNav from '@/views/menus/mobile/mobileHeadNav.vue'

export default {
    name: 'app',
    watch:{
        $route (to){
            this.activePageName = to.name
            if(to.meta.tracker != false) {
                this.pageTracking(to)
            }
            this.currentActivePage = to.name
            if(to.name == 'category' || to.name == 'browseShops' || to.name == 'home') {
                this.isFixedMenuActive = true
            } else {
                this.isFixedMenuActive = false
            }
            if(to.name == 'becomeSeller') {
                this.isApplicationPageActive = true
            } else {
                this.isApplicationPageActive = false
            }
        }
    }, 
    metaInfo() {
        return {
            title: 'Storium'
        }
    },
    components: {
        topMenuLogged,
        smallNavBarMain,
        pagefooter,
        mobileHeadNav,
        supportPopup,
        cookieConsent,
        Loading
    },
    data() {
        return {
            userGroups: [],
            accessAllowed: Boolean,
            currentActivePage: null,
            catchError: '',
            role: '',
            idUser: '',
            isApplicationPageActive: false,
            isNavigationsActive: false,
            isOrderPopupActive: false,
            isMobileOrderPopupActive: false,
            isNotifPopupActive: false,
            isMobileActive: false,
            nonVerifiedNotice: false,
            cookieConsent: null,
            isListingDeleted: false,
            isHomeNavActive: 0,

            fixedScrollPos: -1,
            hideMenuScrollPos: -1,
            isFixedMenuActive: false,
            categoryType: null,
            userSupportPopup: false
        }
    },  
    methods: {
        homeNavigation() {
            this.isHomeNavActive ++
        },
        
        deactivateFooterNav() {
            this.isNavigationsActive = false
            this.$refs.pageFooter.isNavigationsActive = false
        },
        deactivateOrderPopup() {
            this.isOrderPopupActive = false
            if(!this.isMobileActive && !this.isUserFromGroup('guest')) {
                this.$refs.topMenu.isOrderPopupActive = false
            }
            if(!this.isMobileActive && this.isUserFromGroup('guest')) {
                this.$refs.topMenuGuest.isOrderPopupActive = false
            }
        },

        deactivateNotifPopup() {
            this.isNotifPopupActive = false
        },

        listingDeletedFromOrder() {
            this.isListingDeleted = true
            setTimeout(() => {
                this.isListingDeleted = false
            }, 200)
        },

        emitMenuReload() {
            if(!this.isMobileActive && !this.isUserFromGroup('guest')) {
                this.$refs.topMenu.fetchOrders()
            }
            if(!this.isMobileActive && this.isUserFromGroup('guest')) {
                this.$refs.topMenuGuest.fetchOrders()
            }
            if(this.isMobileActive) {
                this.$refs.mobileHeadNav.fetchOrders()
            }
        },

        openOrderPopup(idShop) {
            if(!this.isMobileActive) {
                if(this.$refs.topMenu) {
                    this.$refs.topMenu.openOrder(idShop)
                    this.$refs.topMenu.containerPosition = 'fixed'
                    this.$refs.topMenu.topMargin = '70px'
                    this.$refs.topMenu.rightMargin = '21.1%'
                } else {
                    this.$refs.topMenuGuest.openOrder(idShop)
                    this.$refs.topMenuGuest.containerPosition = 'fixed'
                    this.$refs.topMenuGuest.topMargin = '70px'
                    this.$refs.topMenuGuest.rightMargin = '21.1%'
                }
            } else {
                this.$refs.mobileHeadNav.openOrder(idShop)
                this.$refs.mobileHeadNav.containerPosition = 'fixed'
                this.$refs.mobileHeadNav.topMargin = window.scrollY+75+'px'
                this.$refs.mobileHeadNav.rightMargin = '21.1%'
            }
        },

        viewOrder(idOrder, idListing) {
            if(!this.isMobileActive) {
                window.scrollTo({top: 0, behavior: 'smooth'})
                if(this.$refs.topMenu) {
                    this.$refs.topMenu.openOrder(idOrder, idListing)
                } else {
                    this.$refs.topMenuGuest.openOrder(idOrder, idListing)
                }
            } else {
                this.$refs.mobileHeadNav.openOrder(idOrder, idListing)
            }
        },

        emitMenuNotifReload() {
            if(!this.isMobileActive) {
                this.$refs.topMenu.getUserNots()
            } else {
                this.$refs.mobileHeadNav.getUserNots()
            }
        },

        emitMenuRoundtrip() {
            if(!this.isMobileActive) {
                this.$refs.topMenu.roundtripRegulator()
            }
        },

        emitMenuChatsReload() {
            if(!this.isMobileActive) {
                this.$refs.topMenu.getUserChats()
            } else {
                this.$refs.mobileHeadNav.getUserChats()
            }
        },

        pageTracking(data) {
            if(data.meta.customTrackerName) {
                if(data.name == 'dashboard') {
                    this.$track.customView(`dashboard (${data.params.activeGroup})`)
                } 
                else if(data.name == 'supportPage') {
                    this.$track.customView(`supportPage (${data.params.pageName})`)
                } 
                else if(data.name == 'legalEntity') {
                    this.$track.customView(`legalEntity (${data.params.currentPage})`)
                } 
                else if(data.name == 'communicationMain') {
                    this.$track.customView(`communication (${data.params.pageName})`)
                } else {
                    this.$track.customView(data.meta.customTrackerName)
                }
            } 
            else if(data.meta.fullPath) {
                this.$track.customView(data.path)
            }
            if(!data.meta.customTrackerName && !data.meta.fullPath) {
                this.$track.customView(data.matched[0].path)
            }
        },

        closeNonVerifiedNotice() {
            this.nonVerifiedNotice = false
            localStorage.setItem('nonVerifiedNotice', this.nonVerifiedNotice)
        },

        onResize() {
            if (window.innerWidth < 750) {
                this.isMobileActive = true
            } else {
                this.isMobileActive = false
            }
        },

        isUserFromGroup(group) {
            if(this.userGroups.some(grupp => grupp === group)) {
                return true
            } else {
                return false
            }
        },

        roundtripRegulator() {
            roundtrip(this.$options.name)
            .then((res) => {
                this.userGroups = res.userGroups
                this.accessAllowed = res.accessAllowed
                this.idUser = res.idUser
                if(this.isMobileActive) {
                    this.$nextTick(() => {
                        if(this.$refs.mobileNav) {
                            this.$refs.mobileNav.getUserChats()
                        }
                    })
                }
                if(this.accessAllowed == false) {
                    this.$router.push('/')
                }
                if(this.isUserFromGroup('seller')) {
                    this.role = 'seller'
                } else {
                    this.role = 'base'
                }
                if(this.isUserFromGroup('guest')) {
                    this.role = 'guest'
                }
            })
            .catch((err) => {
                this.catchError = err
            }) 
        },

    },
    // updated() {
    //     if(this.$refs.topMenu) {
    //         if(!this.isMobileActive) {
    //             this.$refs.topMenu.activePage()
    //         }
    //     }
    // },
    created() {
        this.roundtripRegulator()
        this.onResize()
        this.nonVerifiedNotice = localStorage.getItem('nonVerifiedNotice')
        if(this.nonVerifiedNotice == null) {
            this.nonVerifiedNotice = true
        }
        if(this.nonVerifiedNotice == 'false') {
            this.nonVerifiedNotice = false
        }
        if(this.nonVerifiedNotice == 'true') {
            this.nonVerifiedNotice = true
        }
        this.cookieConsent = localStorage.getItem('cookieConsent')
        if(this.cookieConsent == 'true') {
            this.cookieConsent = true
        }
    },
    
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
}
</script>

<style>
@font-face {
    font-family: 'unseen';
    src: url('~@/assets/fonts/unseen.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Palanquin+Dark&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Muli:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Krub&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500&display=swap');

.darkBackground {
    position: fixed;
    z-index: 99000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: .5s;
}
.darkBackgroundHidden {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    transition: .5s;
}
.blurBackground {
    position: fixed;
    z-index: 4998;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.25);
    transition: .5s;
}
.blurBackgroundHidden {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    transition: .5s;
}
.radial {
    position: fixed;
    left: 6px;
    bottom: 6px;
    z-index: 100300;
}
.testEnvNotice {
    display: none;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 20000;
    top: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    background: #4C9091;
    opacity: .8;
}
.testEnvNotice p {
    margin: 0;
    color: white;
    padding-right: 15px;
    padding-left: 15px;
}
#app {
    overflow: hidden;
    padding-top: 64px;
    padding-bottom: 40px;
}
.mobileHeadNav {
    position: relative;
    z-index: 200;
}
.mobileNavigation {
    width: 100%;
}
.nonVerified {
    width: 100%;
    z-index: 100000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 0;
    background: red;
    opacity: .7;
}
.nonVerified p {
    color: white;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 5px 0px 5px 0px;
}
.nonVerified img {
    position: absolute;
    cursor: pointer;
    right: 25px;
    top: 5px
}
a {
    text-decoration: none;
    color: rgb(95, 94, 94);
}
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    /* min-height: 95vh; */
}

h1 {
    font-family: 'Quicksand', sans-serif;
    color: rgb(68, 68, 68);
    font-size: 2.7rem; 
}

h2 {
    font-family: 'Quicksand', sans-serif;
    color: rgb(48, 48, 48);
    font-size: 1.7rem;  
}

/* FAT TITLE TEXT */
h5 {
    font-family: 'Palanquin Dark', sans-serif
}
textarea, input {
    font-family: 'Open Sans', sans-serif;
}

/* ////////// BUTTONS \\\\\\\\\\ */
    button {
        font-family: 'Lato', sans-serif;
        font-weight: 400;
    }

/* ////////// NUMBERS \\\\\\\\\\ */
    /* PRICE OF LISTINGS */
    .listingPrice {
        font-family: 'Noto Sans', sans-serif;
    }
    /* STATISTICS NUMBERS */
  
  
/* ////////// STATUS BOXES(LABELS) \\\\\\\\\\ */
    /*DASHDEALS SINGLE DEAL STATUS */
    .dealStatus p {
        font-family: 'DM Sans', sans-serif;
    }
    /* DASHBOARD MAIN PAGE */ 
    .singleOrderStatus p {
        font-family: 'DM Sans', sans-serif;
    }
    /* SHOP PRODUCTS STATUS */
    .statusContainer p {
        font-family: 'DM Sans', sans-serif;
    }

/* ////////// USER INPUT TEXT \\\\\\\\\\ */
    /* DESCRIPTION TEXTS */
    .descriptionText {
        font-family: 'Jaldi', sans-serif;
    } 
    /* SHOP NAME */
    .userSlogan h2, .userSlogan p {
        font-family: 'Lora', serif;
    }

/* ////////// 5 TYPES OF MENUS \\\\\\\\\\ */
    /* MEGAMENU DROPDOWN TITLES */
    nav.mega-menu .menutitles {
        font-family: 'Baloo 2', cursive;
    }
    nav.mega-menu ul.menu-area ul li a, nav.mega-menu ul.menu-area-2 ul li a {
        font-family: 'Open Sans', sans-serif;
    }

    /* MAIN TOP MENU */
    .profileBoxMenu {
        font-family: 'Baloo 2', cursive;
    }
    .profileButton a {
        font-family: 'Hind Siliguri', sans-serif;
    }
    .profileBoxBtn {
        font-family: 'Raleway', sans-serif;
    }
    .dashboard p {
        font-family: 'Raleway', sans-serif;
    }
    .dashboard a {
        font-family: 'Hind Siliguri', sans-serif;
    }

    /* SHOP MENU */
    .shopMenu p {
        font-family: 'Raleway', sans-serif;
    }
    .shopMenu ul>li {
        font-family: 'Baloo 2', cursive;
    }
    .contentMenu ul>li {
        font-family: 'Baloo 2', cursive;
    }

    /* DASHBOARD MENU BUTTONS */
    .dashboardMenu a {
        font-family: 'Muli', sans-serif;
    }

    /* LOGGED OUT MENU */
    nav.small-menu-nav ul li a {
        font-family: 'Krub', sans-serif;
    }

    /* PAGE FOOTER BUTTONS */ 
    .footer-section-items a {
        font-family: 'Muli', sans-serif;
    }

/* ////////// CUSTOM FONTS \\\\\\\\\\ */
    /* PAGE LOGO */ 
    .logo p {
        font-family: 'Cookie', 'Quicksand', sans-serif;
    }

@media (max-width: 800px) {
    .nonVerified p {
        padding: 5px 30px 5px 30px;
    }
    .nonVerified img {
        right: 5px;
    }
}
@media (max-width: 750px) {
    #app {
        padding-top: 50px;
        padding-bottom: 0px;
    }
}
@media (max-width: 430px) {
    .testEnvNotice p {
        font-size: .8rem;
    }
    .nonVerified p {
        font-size: .8rem;
        padding: 5px 30px 5px 30px;
    }
    .nonVerified img {
        right: 5px;
    }
}
</style>
