import apiTools from '../api-tools/index'
import buildPostDealFeedback from './post-deal-feedback'
import buildGetDealFeedback from './get-deal-feedback'
import buildGetPublicUserFeedbacks from './get-public-user-feedbacks'
import buildGetPublicShopFeedbacks from './get-public-shop-feedbacks'
import buildGetUserFeedbacks from './get-user-feedbacks'
import buildGetShopFeedbacks from './get-shop-feedbacks'


const apiEndpoint = 'feedback'

const postDealFeedback = buildPostDealFeedback({
    apiEndpoint,
    apiTools
})
const getDealFeedback = buildGetDealFeedback({
    apiEndpoint,
    apiTools
})
const getPublicUserFeedbacks = buildGetPublicUserFeedbacks({
    apiEndpoint,
    apiTools
})
const getPublicShopFeedbacks = buildGetPublicShopFeedbacks({
    apiEndpoint,
    apiTools
})
const getUserFeedbacks = buildGetUserFeedbacks({
    apiEndpoint,
    apiTools
})
const getShopFeedbacks = buildGetShopFeedbacks({
    apiEndpoint,
    apiTools
})


const feedbackApi = {
    postDealFeedback,
    getDealFeedback,
    getPublicShopFeedbacks,
    getPublicUserFeedbacks,
    getUserFeedbacks,
    getShopFeedbacks
}

export default feedbackApi