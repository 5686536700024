<template>
    <div class="componentWrapper">
        <div class="searchComponent" :class="{ 'searchComponentHidden': !searchbarActive }">
            <input 
                class="searchInput"
                type="text" 
                v-model="searchWord" 
                :placeholder="placeholder" 
                @keypress.enter="search"
            > 
            <img class="illustration" :src="require('@/assets/illustrations/elements/fowardLinkIll.svg')"/>
            <div class="searchImgContainer">
                <img 
                    class="searchImg"
                    @click="search"
                    :src="require('@/assets/icons/menus/search.svg')" 
                    alt=""
                >
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'searchBar',
    props: {
        
    },
    data() {
        return {
            placeholder: 'Otsi poode ja tooteid..',
            availableCategories: '',
            idListingTagSearch: '',
            category: '',
            searchWord: null,
            searchbarActive: true,
        }
    },

    methods: {
        search() {
            this.$emit('loader')
            if(this.searchWord != '') {
                if(window.location.pathname != '/otsing') {
                    this.$router.push({
                        name: 'searchListings',
                        params: {
                            idListingTag: this.idListingTag,
                            searchWord: this.searchWord,
                            category: this.category
                        }
                    })
                } else {
                    this.$emit('dataFetchListing', this.idListingTag, this.searchWord)
                    this.$emit('dataFetchShop', this.searchWord)
                    this.$emit('dataFetchProducer', this.searchWord)
                }
            } else {
                this.$emit('loaderOff')
            }
        },
    },

    created() {
        
    }
       
}
</script>

<style scoped>
.searchComponent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;
    text-align: center;
    padding-left: 10px;
    border-radius: 10px;
    overflow: hidden;
    height: 35px;
    width: 35%;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    background: rgb(255, 255, 255);
    transition: .3s;
}
.searchComponentHidden {
    width: 40px;
    opacity: 0;
    transition: .3s;
}
.searchComponent:hover {
    background: rgb(252,252,252);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
}
.searchInput {
    position: relative;
    z-index: 20;
    border: 0;
    border-right: 0;
    border-radius: 10px;
    height: 35px;
    width: 100%;
    background: transparent;
    margin-left: -10px;
    padding-left: 10px;
    color: rgb(100, 100, 100);
    font-size: 1rem;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
input:focus {
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.12);
            box-shadow:  0px 0px 5px 1px rgba(0, 0, 0, 0.12);
    color: rgb(7, 145, 127);
    outline: none;
}
.searchImgContainer {
    position: absolute;
    z-index: 25;
    right: 0;
    top: 0;
    height:100%;
    width: 70px;
    background: rgb(255, 255, 255);
    -webkit-box-shadow: -2px 0px 4px -2px rgba(0,0,0,0.2);
    box-shadow: -2px 0px 4px -2px rgba(0,0,0,0.2);
    cursor: pointer;
}
.searchImgContainer:hover {
    background: rgb(250,250,250);
}
.searchImg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 20px; 
    cursor: pointer;
}
.illustration {
    position: absolute;
    z-index: 10;
    top: 6px;
    left: 0;
    right: 0;
    margin: auto;
    width: 270px;
    opacity: .07;
}
@media (max-width: 800px) {
    .searchComponent {
        width: 30%;
    }
}
</style>
