import apiTools from '../../api-tools/index'

const apiEndpoint = 'tag/filter'

import buildGetFilterElements from './get-filter-elements_fetch'
import buildPostFilterElement from './post-filter-element_fetch'
import buildPostFilterParam from './post-filter-param_fetch'
import buildPatchFilterElement from './patch-filter-element_fetch'
import buildPatchFilterParam from './patch-filter-param_fetch'
import buildPatchTagFilterElements from './patch-tag-filter-elements_fetch'
import buildRemoveFilterElement from './remove-filter-element_fetch'
import buildRemoveFilterParam from './remove-filter-param_fetch'

const getFilterElements = buildGetFilterElements({
    apiEndpoint,
    apiTools
})
const postFilterElement = buildPostFilterElement({
    apiEndpoint,
    apiTools
})
const postFilterParam = buildPostFilterParam({
    apiEndpoint,
    apiTools
})
const patchFilterElement = buildPatchFilterElement({
    apiEndpoint,
    apiTools
})
const patchFilterParam = buildPatchFilterParam({
    apiEndpoint,
    apiTools
})
const patchTagFilterElements = buildPatchTagFilterElements({
    apiEndpoint,
    apiTools
})
const removeFilterElement = buildRemoveFilterElement({
    apiEndpoint,
    apiTools
})
const removeFilterParam = buildRemoveFilterParam({
    apiEndpoint,
    apiTools
})

const filterApi = {
    getFilterElements,
    postFilterElement,
    postFilterParam,
    patchFilterElement,
    patchFilterParam,
    patchTagFilterElements,
    removeFilterElement,
    removeFilterParam
}

export default filterApi