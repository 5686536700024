import apiTools from '../api-tools/index'
import buildGetShopDeals from './get-shop-deals'
import buildGetUserDeals from './get-user-deals'
const apiEndpoint = 'deal'

const getShopDeals = buildGetShopDeals({
    apiEndpoint,
    apiTools
})
const getUserDeals = buildGetUserDeals({
    apiEndpoint,
    apiTools
})

const dealApi = {
    getShopDeals,
    getUserDeals
}

export default dealApi