import apiTools from '../../api-tools/index'

const apiEndpoint = 'tag/listing-tag'

import buildGetListingTagTree from './get-listing-tag-tree_fetch'
import buildGetListingTagData from './get-listing-tag-data_fetch'
import buildGetTagRequest from './get-tag-request_fetch'
import buildPostListingTag from './post-listing-tag_fetch'
import buildPostListingTagImage from './post-listing-tag_image_fetch'
import buildPostTagRequest from './post-tag-request_fetch'
import buildPatchListingTagData from './patch-listing-tag-data_fetch'
import buildPatchDecideTagRequest from './patch-decide-tag-request_fetch'
import buildDeleteListingTag from './delete-listing-tag_fetch'

const getListingTagTree = buildGetListingTagTree({
    apiEndpoint,
    apiTools
})
const getListingTagData = buildGetListingTagData({
    apiEndpoint,
    apiTools
})
const getTagRequest = buildGetTagRequest({
    apiEndpoint,
    apiTools
})
const postListingTag = buildPostListingTag({
    apiEndpoint,
    apiTools
})
const postListingTagImage = buildPostListingTagImage({
    apiEndpoint,
    apiTools
})
const postTagRequest = buildPostTagRequest({
    apiEndpoint,
    apiTools
})
const patchListingTagData = buildPatchListingTagData({
    apiEndpoint,
    apiTools
})
const patchDecideTagRequest = buildPatchDecideTagRequest({
    apiEndpoint,
    apiTools
})
const deleteListingTag = buildDeleteListingTag({
    apiEndpoint,
    apiTools
})

const tagApi = {
    getListingTagTree,
    getListingTagData,
    getTagRequest,
    postListingTag,
    postListingTagImage,
    postTagRequest,
    patchListingTagData,
    patchDecideTagRequest,
    deleteListingTag
}

export default tagApi