import apiTools from '../api-tools/index'

const apiEndpoint = 'producer'

import buildPostUserProducerApplications from './post-user-producer-applications'
import buildGetUserProducerData from './get-user-producer-data'
import buildGetPublicProducerApplications from './get-public-producer-applications'
import buildGetPrivateProducerApplications from './get-private-producer-applications'
import buildPatchProducerApplications from './patch-producer-applications'
import buildGetSingleProducer from './get-single-producer'
import buildPatchSingleProducer from './patch-single-producer'


const postUserProducerApplications = buildPostUserProducerApplications({
    apiEndpoint,
    apiTools
})
const getUserProducerData = buildGetUserProducerData({
    apiEndpoint,
    apiTools
})
const getPublicProducerApplications = buildGetPublicProducerApplications({
    apiEndpoint,
    apiTools
})
const getPrivateProducerApplications = buildGetPrivateProducerApplications({
    apiEndpoint,
    apiTools
})
const patchProducerApplications = buildPatchProducerApplications({
    apiEndpoint,
    apiTools
})
const getSingleProducer = buildGetSingleProducer({
    apiEndpoint,
    apiTools
})
const patchSingleProducer = buildPatchSingleProducer({
    apiEndpoint,
    apiTools
})

const producerApi = {
    postUserProducerApplications,
    getUserProducerData,
    getPublicProducerApplications,
    getPrivateProducerApplications,
    patchProducerApplications,
    getSingleProducer,
    patchSingleProducer
}

export default producerApi