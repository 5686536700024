<template>
    <div class="componentWrapper">
        <div 
            :class="{ 
                'menuContainer-fixed': isMenuFixed, 
                'menuContainer-fixed-noShadow': isMenuFixed && noShadow, 
                'menuContainer': !isMenuFixed,
                'menuContainer-noShadow': !isMenuFixed && noShadow,
                'menuContainerHidden': hideMenu
            }"
        >
            <div class="wrapper" :class="{ 'wrapperSmall': currentActivePage == 'singleListing' || currentActivePage == 'userShop' || currentActivePage == 'communicationCentral' }">
                <div :class="{ 'logoHidden': loadAnimationLogo, 'logo': !loadAnimationLogo }">
                    <router-link :to="{ path: '/' }">
                        <img 
                            @click="routeChangeToHome()"
                            class="originalLogo"
                            :src="require('@/assets/logos/omatoodangLogo.svg')" 
                            alt=""
                        >
                    </router-link>
                </div>
                <searchBar v-if="
                    currentActivePage == 'userOrders' || 
                    currentActivePage == 'finalOrderPage' || 
                    currentActivePage == 'formalizeOrder'                    
                "/>
                <nav 
                    class="small-menu-nav" 
                    :class="{ 'topMenuHidden': loadAnimationLogo, 'topMenu': !loadAnimationLogo }"
                >   
                    <ul>
                        <li>
                            <orderPopupGuest
                                ref="orderPopup"
                                @orders='orders'
                                @deactivatePopup='deactivatePopup'
                                @loginPopup='loginPopupActive = !loginPopupActive; isOrderPopupActive = false'
                                :isOrderPopupActive='isOrderPopupActive'
                                :isGuest='true'
                                :topMargin="topMargin"
                                :isMenuFixed="isMenuFixed"
                                :containerPosition="containerPosition"
                                :rightMargin="rightMargin"
                            />
                        </li>
                        <li>
                            <div class="notifications">
                                <img 
                                    v-if="!notifications" 
                                    style="opacity: 1"
                                    @click="orders('notification')"
                                    :src="require('@/assets/icons/menus/notificationIco.svg')"
                                />
                                <img 
                                    v-if="notifications" 
                                    @click="orders('notification')"
                                    style="opacity: 1"
                                    :src="require('@/assets/icons/menus/notificationIcoActive.svg')"
                                />
                                <div 
                                    class="newUpdateNotifs" 
                                    @click="orders('notification')"
                                    v-if="newNotificationList.length > 0"
                                >   <p>{{this.newNotificationList.length}}</p></div>
                            </div>
                            <div :class="{ 'notifBox': notifications, 'notifBoxGone': !notifications }" v-if="notifications" v-on-clickaway="clickAway">
                                <div class="tabHeader" v-if="newNotificationList.length">
                                    <p v-if="this.newNotificationList.length == 1">{{this.newNotificationList.length}} Lugemata Teavitus</p>
                                    <p v-if="this.newNotificationList.length > 1">{{this.newNotificationList.length}} Lugemata Teavitust</p>
                                </div>
                                <div class="allMessagesBox" :class="{ 'allMessagesBoxEmpty':  !newNotificationList.length}">
                                    <img 
                                        v-if="!newNotificationList.length"
                                        height="120px" width="120px" 
                                        style="margin-top: 35px; margin-bottom: -95px"
                                        :src="require('@/assets/icons/menus/notificationSilver.svg')"
                                    />
                                    <h2 
                                        v-if="!newNotificationList.length" 
                                        style="text-align: center; font-size: 1.8rem; color: silver; margin: 70px auto; margin-top: 120px; margin-bottom: 0px"
                                    >   Lugemata Teavitused Puuduvad</h2>
                                    <div 
                                        class="firstMessage" 
                                        v-for="element of newNotificationList" 
                                        @click="clickAway()"
                                        :key='element.id'
                                    >
                                        <p class="subject">
                                            <b>{{element.subject}}</b>
                                        </p>
                                        <p 
                                            class="notificationBody">
                                            {{element.text}}
                                        </p>
                                        <div class="date">
                                            {{$dateLibrary.formatDate(element.timestamp, 'D. MMMM (HH:mm)')}}
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-on-clickaway="menuAwayClick">
                            <div 
                                class="menu"
                                v-if="!menuActive" 
                                v-on:click='activateMenu'
                            >
                                <img 
                                    :src="require('@/assets/icons/menus/icons8-sort-down-96(1).png')"
                                >
                            </div>
                            <div 
                                class="menuActive" 
                                v-if="menuActive" 
                                v-on:click='activateMenu'
                            >
                                <img 
                                    :src="require('@/assets/icons/menus/icons8-sort-down-96(1).png')"
                                >
                            </div>
                        </li>
                        <div :class="{ 'menuBox': menuActive, 'menuBoxHidden': !menuActive }">
                            <router-link :to="{ path: '/' }">
                                <div class="dashboard" @click="routeChangeToHome">
                                    <div class="iconCircle">
                                        <img 
                                            height="32px" 
                                            width="32px" 
                                            :src="require('@/assets/icons/menus/main/market.svg')" 
                                        />
                                    </div>
                                    <div class="dashName">
                                        <p>Turg</p>
                                        <a>Sirvi tooteid & avasta kauplejaid</a>
                                    </div>
                                </div>
                            </router-link>
    
                            <router-link 
                                :to="{ path: '/nimistu/poed' }"
                            >
                                <div class="profileBoxBtn">
                                    <div class="iconCircle">
                                        <img 
                                            height="32px" 
                                            width="32px" 
                                            :src="require('@/assets/icons/menus/main/shopList.svg')"
                                        />
                                    </div>
                                    <p>Poodide nimistu</p>
                                </div>
                            </router-link>
                            
                            <router-link 
                                :to="{ path: '/orbiit' }"
                            >
                                <div class="profileBoxBtn">
                                    <div class="iconCircle">
                                        <img 
                                            height="32px" 
                                            width="32px" 
                                            :src="require('@/assets/icons/menus/main/orbit.svg')" 
                                            />
                                        </div>
                                        <p>Orbiit</p>
                                    </div>
                            </router-link>
    
                            <router-link 
                                :to="{ path: '/sisselogimine' }"
                            >
                                <div class="profileBoxBtn">
                                    <div class="iconCircle">
                                        <img 
                                            height="32px" 
                                            width="32px" 
                                            :src="require('@/assets/icons/menus/main/login.svg')"  
                                        />
                                    </div>
                                    <p>Minu kasutaja</p>
                                </div>
                            </router-link>
                        </div>
                    </ul>
                </nav>
            </div>
        </div>
        <loginPopup @closePopup='loginPopupActive = false' v-if="loginPopupActive" />
    </div>
</template>

<script>
import orderPopupGuest from '@/views/menus/components/orderPopupGuest.vue'
import searchBar from '@/views/menus/components/searchBar.vue'
import loginPopup from '@/views/loginAndRegister/loginPopup.vue'
import { mixin as clickaway } from 'vue-clickaway'

export default {
    name: 'smallNavBarMain',
    mixins: [ clickaway ],
    components: {
        loginPopup,
        orderPopupGuest,
        searchBar
    },
    watch: {
        currentActivePage() {
            this.handleScroll()
        }
    },
    props: {
        fixedScrollPos: {},
        hideMenuScrollPos: {},
        isFixedMenuActive: {},
        currentActivePage: {},
        categoryType: {},
    },
    data() {
        return {
            loadAnimationMenu: true,
            loadAnimationLogo: true,
            menuActive: false,
            isMenuFixed: false,
            hideMenu: false,
            noShadow: false,
            containerPosition: 'absolute',
            topMargin: '45px',
            rightMargin: '7%',

            loginPopupActive: false,
            isOrderPopupActive: false,
            notifications: false,
            newNotificationList: [],
        }
    },
    methods: {
        handleScroll() {
            if(this.currentActivePage == 'category' || this.currentActivePage == 'browseShops' || this.currentActivePage == 'userShop' || this.currentActivePage == 'shop' || this.currentActivePage == 'home') {
                this.noShadow = true
            } else {
                this.noShadow = false
            }
            if(this.isFixedMenuActive) {
                if(window.scrollY > this.hideMenuScrollPos) {
                    this.hideMenu = true
                } else {
                    this.hideMenu = false
                }
                if(window.scrollY > this.fixedScrollPos) {
                    this.isMenuFixed = true
                } else {
                    this.isMenuFixed = false
                    this.isOrderPopupActive = false
                    this.$emit('deactivateBackground')
                }
            } else {
                this.isMenuFixed = false
                this.hideMenu = false
            }
        },

        openOrder(idOrder, idListing) {
            this.$refs.orderPopup.openOrder(idOrder, idListing)
            this.$emit('activateBackground')
        },

        routeChangeToHome() {
            if(this.$route.path === '/') {
                this.$emit('homeNavigation')
            }
        },

        orders(type) {
            if(type == 'order') {
                this.isOrderPopupActive = !this.isOrderPopupActive
            }
            if(type == 'notification') {
                this.notifications = !this.notifications
            }
            if(!this.isOrderPopupActive && !this.notifications) {
                this.$emit('deactivateBackground')
            } else {
                this.$emit('activateBackground')
            }
        },

        deactivatePopup() {
            this.isOrderPopupActive = false
            this.$emit('deactivateBackground')
        },

        activateMenu() {
            this.menuActive = !this.menuActive
        },

        menuAwayClick() {
            if(this.menuActive) {
                this.menuActive = false
            }
        },

        isUserFromGroup(group) {
            if(this.userGroups.some(grupp => grupp === group)) {
                return true
            } else {
                return false
            }
        },

        clickAway() {
            if(this.notifications) {
                this.addToLocalstorage()
                this.newNotificationList = []
            }
            this.orders('notification')
        },

        addToLocalstorage() {
            localStorage.setItem('notificationSeen', true)
        },

        fetchOrders() {
            this.$refs.orderPopup.fetchOrders()
        }
    },
    created() {
        this.handleScroll()
        window.addEventListener('scroll', this.handleScroll)

        let isNotificationSeen
        isNotificationSeen = localStorage.getItem('notificationSeen')

        if(isNotificationSeen == 'true') {
            this.newNotificationList = []
        } else {
            this.newNotificationList = [{
                subject: 'Tere tulemast Omatoodang.ee platvormile',
                text: 'Avasta kodumaist toodangut ning telli tooteid otse tootjalt.',
                timestamp: Date.now()
            }]
        }

        setTimeout(() => {
            this.loadAnimationLogo = false
        }, 700)
        setTimeout(() => {
            this.loadAnimationMenu = false
        }, 1100)
    },

    destroyed () {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
</script>

<style scoped>
.menuContainer {
    background: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 64px;
    width: 100%;
    -webkit-box-shadow: 0px 3px 4px -3px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 4px -3px rgba(0,0,0,0.15);
    position: absolute;
    top: 0;
    z-index: 4999;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.menuContainer-noShadow {
    -webkit-box-shadow: none;
            box-shadow: none;
    border-bottom: 1px solid rgb(235,235,235);
}
.menuContainerHidden {
    background: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 64px;
    width: 100%;
    -webkit-box-shadow: 0px 3px 4px -3px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 4px -3px rgba(0,0,0,0.15);
    position: absolute;
    top: -100px;
    z-index: 4999;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.menuContainer-fixed {
    background: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 64px;
    width: 100%;
    -webkit-box-shadow: 0px 3px 4px -3px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 4px -3px rgba(0,0,0,0.15);
    top: 0;
    z-index: 4999;
    position: fixed;
}
.menuContainer-fixed-noShadow {
    -webkit-box-shadow: none;
            box-shadow: none;
    border-bottom: 1px solid rgb(235,235,235);
}
.wrapper {
    position: relative;
    margin: 0 auto;
    width: 1420px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.wrapperSmall {
    width: 1100px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.topMenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: -18px;
    top: 0px;
    z-index: 130;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    
}
.topMenuHidden {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: -800px;
    top: 0px;
    z-index: 130;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
nav.small-menu-nav {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    z-index: 10;
    margin: -15px auto;
    z-index: 1000;
}
nav.small-menu-nav ul {
    margin-top: 8px;
    position: absolute;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
}
nav.small-menu-nav ul li {
   list-style: none;
   margin-right: 0px;
}
nav.small-menu-nav ul li a {
    line-height: 30px;
    color: rgb(110, 134, 132);
    font-weight: bold;
    padding: 5px 7px;
    text-decoration: none;
    font-size: 1rem;
}
nav.small-menu-nav ul li a.active,
nav.small-menu-nav ul li a:hover {
    color: #39c892;
    border-radius: 60px;
}
.logo {
    z-index: 2000;
    margin-left: 0;
    position: absolute;
    margin-top: 16px;
    z-index: 13;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    /* -webkit-animation: swing .9s ease;
    animation: swing .9s ease;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1; */
}
.logoHidden {
    z-index: 2000;
    margin-top: 22px;
    margin-left: -800px;
    position: absolute;
    z-index: 13;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.logoHidden img {
    height: 35px;
}
.logo img {
    height: 35px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.notifications {
    margin: 25px 20px 0px 0px;
    opacity: 1;
}
.notifications img {
    width: 34px;
    height: 34px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.notifications:hover {
    opacity: 1;
    cursor: pointer;
    -webkit-transition: .1s ease-out;
    -o-transition: .1s ease-out;
    transition: .1s ease-out
}
.notifBox {
    border-radius: 10px;
    width: 650px;
    height: 359px;
    position: absolute;
    top: 70px;
    right: 7%;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    background: white;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    z-index: 140;
    overflow: hidden;
}
.notifBoxGone {
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 2px;
    position: absolute;
    top: -550px;
    right: 4%;
    display: none;
    height: 350px;
    width: 500px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background: white;
    opacity: 0;
    z-index: 30;
}
.tabHeader {
    position: relative;
    border-bottom: 1px solid #62bd9d;
    background: #62bd9d;
    height: 40px;
}
.tabHeader p {
    margin: 0;
    padding-top: 6px;
    text-align: center;
    color: white;
    font-size: 22px;
    font-family: 'Lato', sans-serif;
}
.allMessagesBoxEmpty {
    text-align: center;
}
.newUpdateNotifs {
    position: absolute;
    left: 71px;
    top: 17px;
    height: 23px;
    width: 23px;
    border: 1px solid rgba(73, 100, 98, 0);
    background: rgba(255, 82, 82, 1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    -webkit-animation: pulse-red 2s infinite;
            animation: pulse-red 2s infinite;
    border-radius: 50%;
    text-align: center;
    color: white;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}
.newUpdateNotifs p {
    margin: 10;
    margin-top: -2px;
    font-weight: bold;
    font-size: 1.2rem;
}
.newUpdateMsgs {
    position: absolute;
    left: 142px;
    top: 15px;
    height: 23px;
    width: 23px;
    border: 1px solid rgba(73, 100, 98, 0);
    background: rgba(255, 82, 82, 1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    -webkit-animation: pulse-red 2s infinite;
            animation: pulse-red 2s infinite;
    border-radius: 20px;
    text-align: center;
    color: white;
}
.newUpdateMsgs p {
    margin: 0;
    margin-top: -2px;
    font-weight: bold;
    font-size: 1.2rem;
}
.firstMessage {
    padding-bottom: 5px;
    padding-left: 15px;
    padding-top: 10px;
    background: white;
    border-bottom: 0.1px solid rgb(240, 240, 240);
}
.firstMessage:hover {
    background: rgb(247, 247, 247);
    cursor: pointer;
}
.firstMessage:hover .showMoreBtn {
    opacity: 1;
}
.subject {
    color: rgb(3, 124, 114);
    font-family: 'DM sans', sans-serif;
    font-weight: 300;
    font-size: 1.05rem;
    padding-right: 10px;
    margin: 0;
}
.date {
    margin: 0;
    margin-top: 5px;
    font-size: .9rem;
    padding-right: 10px;
    color: rgb(190, 190, 190);
    text-align: end;
}
.notificationBody {
    font-size: 1rem;
    color: rgb(75, 75, 75);
    margin: 0;
    margin-top: 5px;
    padding-right: 10px;
    word-break: inherit;
    font-weight: normal;
    overflow-wrap: break-word;
    font-family: 'DM sans', sans-serif;
}
.allMessages {
    position: absolute;
    width: 650px;
    bottom: -16px;
    z-index: 100;
    text-align: center;
    color: rgb(120, 156, 156);
    background: rgb(250, 250, 250);
    margin-top: 0px;
    cursor: pointer;
    text-transform: uppercase;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid rgb(231, 231, 231);
}

.menu {
    border: 1px solid rgb(217, 222, 223);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
    border-radius: 50px;
    height: 36px;
    width: 36px;
    margin: 21px 20px 0px 0px;
    background: #c8d3d1b9;
    opacity: 1;
    cursor: pointer;
}
.menu:hover{
    opacity: 1;
}
.menu img {
    margin-top: 1px;
    margin-left: 0px;
    opacity: 0.7;
    height: 36px;
    width: 36px;
}
.menuActive {
    border: 1px solid rgb(201, 228, 228);
    border-radius: 50px;
    height: 36px;
    width: 36px;
    margin: 21px 20px 0px 0px;
    background: rgb(213, 235, 235);
    opacity: 0.8;
    cursor: pointer;
}
.menuActive:hover{
    opacity: 1;
}
.menuActive img {
    margin-top: 2px;
    margin-left: 0px;
    opacity: 0.8;
    height: 36px;
    width: 36px;
}
.menuBox {
    border-radius: 10px;
    width: 360px;
    position: absolute;
    top: 70px;
    right: 4%;
    padding-bottom: 10px;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    background: white;
    z-index: 130;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    overflow: hidden;
}
.menuBoxHidden {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    border-radius: 7px;
    position: absolute;
    top: 90px;
    right: 13.5%;
    height: 423px;
    width: 360px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    display: none;
    z-index: 130;
}
.dashboard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(218, 218, 218);
    padding-bottom: 10px;
    padding-top: 5px;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.dashboard:hover {
    background: rgb(236, 236, 236);
    width: 100%;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    opacity: 1;
}
.dashboard p {
    font-size: 1.2rem;
    margin-left: 10px;
    margin-top: 18px;
    margin-bottom: -3px;
    color: rgb(79, 102, 102);
}
.dashboard a {
    font-size: .85rem;
    margin-left: 11px;
    color: rgb(155, 155, 155);
    font-family: 'DM sans', sans-serif;
}
.iconCircle {
    background: rgb(222, 231, 235);
    border-radius: 60px;
    height: 40px;
    width: 50px;
    padding-top: 10px;
    margin-top: 10px;
    margin-left: 14px;
    text-align: center;
}
.iconCircle img {
    opacity: 1;
}
.profileBoxBtn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: rgb(79, 102, 102);
    font-size: 1.2rem;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 5px;
}
.profileBoxBtn p {
    margin-left: 10px;
    margin-top: 23px;
}
.profileBoxBtn a {
    color: rgb(79, 102, 102);
}
.profileBoxBtn:hover {
    background: rgb(238, 238, 238);
    border-radius: 10px;
    cursor: pointer;
}
.profileBoxBtn img {
    opacity: 1;
}

@-webkit-keyframes pulse-red {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-red {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@media (max-width: 1450px) {
    .logo {
        left: 1.5%;
    }
    .topMenu {
        right: 1.5%;
    }
}
</style>
