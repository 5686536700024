import apiTools from '../api-tools/index'

import buildGetWithdrawalReqs from './get-withdrawal-reqs'
import buildExportCsv from './export-csv'


const apiEndpoint = ''

const getWithdrawalReqs = buildGetWithdrawalReqs({
    apiEndpoint,
    apiTools
})
const exportCsv = buildExportCsv({
    apiEndpoint,
    apiTools
})


const orgApi = {
    getWithdrawalReqs,
    exportCsv
}

export default orgApi