import trackingCategories from './trackingCategories'


function customEvent(eventName, category, eventReferral) {
    if(trackingCategories[category].isActive && trackingCategories[category][category][eventReferral].isActive && window.umami) {
        window.umami.track(eventName, { category })
    }
}

function customView(pageUrl, referrer) {
    if(window.umami) {
        window.umami.track(props => ({ ...props, url: pageUrl, referrer }));
    }
}

const tracker = Object.freeze({
    customEvent,
    customView
})

export default tracker