import apiTools from '../api-tools/index'

const apiEndpoint = 'shop'

import buildGetUserShops from './get-user-shops_fetch.js'
import buildGetUserShopApplications from './get-user-shop-applications'

const getUserShops = buildGetUserShops({
    apiEndpoint,
    apiTools
})
const getUserShopApplications = buildGetUserShopApplications({
    apiEndpoint,
    apiTools
})

const shopApi = {
    getUserShops,
    getUserShopApplications
}

export default shopApi