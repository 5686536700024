<template>
    <div class="componentWrapper">

        <div class="background"></div>
        <div :class="{ 'containerClose': closeContainer, 'containerOpen': !closeContainer }" v-on-clickaway="awayClick">
            <img 
                class="closeBtn"
                @click="$emit('closePopup')"
                height="16px"
                width="16px"
                :src="require('@/assets/icons/categories/close(2).svg')" 
                alt=""
            >
            <div class="logo">
                <img 
                    class="originalLogo"
                    :src="require('@/assets/logos/omatoodangLogo.svg')" 
                    alt=""
                >
            </div>
            <div class="socialMediaLogin" v-if="!emailConfirmationSent">
                <button @click="loginWithEmail = true" v-if="!loginWithEmail">
                    <img 
                        class="googleImg"
                        style="opacity: .5"
                        height="25dp" 
                        width="25dp" 
                        :src="require('@/assets/icons/login/email.svg')" 
                        alt=""
                    >
                    <p>Logi sisse emaili kinnitusega</p>
                </button>
                <button @click="loginWithEmail = false" v-if="loginWithEmail">
                    <p>Logi sisse parooliga</p>
                </button>
            </div>
            <div class="loginMain" v-if="!emailConfirmationSent">
                <div 
                    class="errors" 
                    v-show="errors"
                >
                    <p>{{ errors }}</p>
                </div>
                <div 
                    class="messages" 
                    v-show="messages"
                >
                    <p>{{messages}}</p>
                </div>
                <form v-if="!loginWithEmail">
                    <div class="group">
                        <input 
                            v-model="email" 
                            type="text" 
                            id="email" 
                            required
                        >
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Email</label>
                    </div>  
                    <div class="group">
                        <input 
                            v-model="password" 
                            type="password" 
                            id="password" 
                            required
                        >
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Parool</label>
                    </div>  
                    <br><br>
                    <button 
                        class="regBtn"
                        type="submit" 
                        @click="sendData" 
                    >
                    <div 
                        class="loader"
                        v-if="loaderActive" 
                    ></div>
                    <p 
                        v-if="!loaderActive"
                    > Logi Sisse</p></button>    
                </form>
                <div v-if="loginWithEmail">
                    <div class="group">
                        <input 
                            v-model="email" 
                            type="text" 
                            id="email" 
                            required
                        >
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Email</label>
                    </div>
                    <br>
                    <button 
                        class="regBtn"
                        @click="emailConfimartion" 
                    >
                        <div 
                            class="loader"
                            v-if="loaderActive" 
                        ></div>
                        <p 
                            v-if="!loaderActive"
                        > Saada kinnitus emailile</p>
                    </button>    
                </div>
                <div class="claim">
                    <p>Sisse logides nõustun <a @click="$emit('closePopup'); $router.push('/tingimused/kasutus')" >kasutustingimustega</a> 
                    <br> ja kinnitan, et olen lugenud <a @click="$emit('closePopup'); $router.push('/tingimused/privaatsus')">privaatsustingimusi</a></p>
                </div>
                <!-- <p style="color: gray; font-size: .9rem; width: 80%; margin: -40px auto; margin-bottom: 0px">Sisse logides kinnitan, et nõustun <a @click="$router.push('/tingimused/kasutus')" style="cursor: pointer; text-decoration: underline">kasutus</a>- ja <a @click="$router.push('/tingimused/privaatsus')" style="cursor: pointer; text-decoration: underline">privaatsustingimustega</a></p> -->

                <div class="notUser">
                    <p 
                        class="notUserQuestion"
                    > Pole veel kasutajat?<br>
                        <a style="cursor: pointer" @click="$emit('registerActive')"> Liitu Siin</a>
                    </p>
                    <p 
                        class="notUserQuestion"
                    > Unustasid parooli?<br>
                        <a style="cursor: pointer" @click="loginWithEmail = true"> Vajuta Siia</a>
                    </p>
                </div>
            </div>

            <div class="confirmEmail" v-if="emailConfirmationSent">
                <img 
                    :src="require('@/assets/icons/dashboard/order/email.svg')"
                />
                <h2>Kinnita sisselogimine emailil!</h2>
                <p>Saatsime sinu emaili aadressile kinnitamise lingi. Vajutades lingile, logitakse teid automaatselt sisse.</p>
                <p><b>{{email}}</b></p>
                <button @click="emailConfirmationSent = false">Sulge</button>
            </div>
        </div>
    </div>
</template>

<script>
import loginFetch from '@/ApiFetches/loginFetch'
import googleAuth from '@/ApiFetches/googleAuth'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'login',
  mixins: [ clickaway ],
  components: {
  },
  data() {
    return {
        userGroups: [],
        accessAllowed: Boolean,
        email: '',
        password: '',
        errors: null,
        messages: null,
        isAuth: '',
        catchError: '',
        loaderActive: false,
        closeContainer: false,
        loginWithEmail: false,
        emailConfirmationSent: false,

        forgotPasswordActive: false
    }
  },  

    methods: {
        awayClick() {
            this.closeContainer = true
            setTimeout(() => {
                this.$emit('closePopup')
            }, 180)
        },

        emailConfimartion() {
            fetch(this.fetchIp + '/v2/auth/magic-link', {
                method: 'POST',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "email": this.email,
                })
            })
            .then((res) => res.json())
            .then((res) => {
                if(res.status == 'success') {
                    this.emailConfirmationSent = true
                }
                if (res.message === "User with the provided email does not exist.") {
                    this.errors = null
                    this.errors = 'Sellise emailiga kasutajat ei leitud!'
                    this.loaderActive = false
                } 
                if (res.message === "Failed to generate a magic link.") {
                    this.errors = null
                    this.errors = 'Lingi genereerimisel läks midagi valesti, palun proovige uuesti!'
                    this.loaderActive = false
                } 
            })
        },

        sendData: async function(e) {
            e.preventDefault();
            this.loaderActive = true      
            loginFetch(this.email, this.password)
            .then((res) =>  {
                if (res.message === "Wrong password." ) {
                    this.errors = null
                    this.errors = 'Sisestatud parool on vale'
                    this.loaderActive = false
                } 
                if (res.message === "User must have a valid email." ) {
                    this.errors = null
                    this.loaderActive = false
                    this.errors = 'Antud e-mail ei ole korrektne'
                } 
                if (res.message === "User must have a password." ) {
                    this.errors = null
                    this.loaderActive = false
                    this.errors = 'Sisestage soovitud parool'
                } 
                if (res.message === "User with the provided email does not exist." ) {
                    this.errors = null
                    this.loaderActive = false
                    this.errors = 'Antud e-maili andmebaasis ei eksisteeri'
                } 
                if (res.message === "Privileged users are not allowed to authenticate themselves by regular endpoint." ) {
                    this.errors = null
                    this.loaderActive = false
                    this.errors = 'Administraatorid ja moderaatorid ei saa sisse logida tavakasutajana, kasutage otspunkti /admin/login'
                } 
                if (res.message === "Something went wrong fetching google user data from Google." ) {
                    this.errors = null
                    this.loaderActive = false
                    this.errors = 'Midagi läks valesti Googlelt andmete saamisega, palun proovige uuesti'
                } 
                if (res.message === "Error opening a new session with the provided information." ) {
                    this.errors = null
                    this.loaderActive = false
                    this.errors = 'Midagi läks valesti, palun proovige uuesti'
                } 
                if (res.status === "success" ) {
                    this.errors = null
                    this.messages = 'Sisselogimine õnnestus'
                    setTimeout(() => {
                        this.$router.go()
                    }, 500)
                } 
            })
            .catch((err) => {
                this.catchError = err
            }); 
        },

        googleLogin() {
            googleAuth()
            .then((res) => {
                if(res.authUrl) {
                    window.location = res.authUrl
                } else {
                    this.errors = null
                    this.errors = 'Midagi läks valesti, palun proovige registreerida/logida sisse teise meetodiga'
                }
            })
        },

        forgotPassword() {
            fetch(this.fetchIp + '/v2/org/task', {
                method: 'POST',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "name": 'userSupport',
                    "note": `PAROOLIPÄRING ALGATATUD: ${this.email}`
                })
            })
            .then((res) => res.json())
            .then((res) => {
                if(res.status == 'success') {
                    this.closeContainer = true
                    this.$emit('closePopup')
                    this.forgotPasswordActive = false
                    this.$fire({
                        title: "Päring uue parooli saamiseks on edukalt algatatud!",
                        type: 'success',
                        showCancelButton: false,
                        showConfirmButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        timer: 3000
                    })
                }
            })
        },

    },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

a {
  text-decoration: none;
  color: rgb(84, 136, 160)
}
.errors {
    position: absolute;
    border-radius: 0px;
    background: rgb(224, 54, 24);
    color: rgb(255, 255, 255);
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    margin: -60px auto;
    margin-bottom: -30px;
    font-size: 1.1rem;
}
.errors p {
    margin: 0;
}
.messages {
    position: absolute;
    border-radius: 0px;
    background: #64C3A2;
    color: rgb(255, 255, 255);
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    margin: -60px auto;
    margin-bottom: -30px;
    font-size: 1.1rem;
}
.messages p {
  margin: 0;
}
.background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}
.containerOpen {
    position: fixed;
    z-index: 100000;
    top: 100px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    background: #fff;
    opacity: 1;
    width: 470px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 0.3125em;
    text-align: center;
    -webkit-animation: swal2-show .3s;
            animation: swal2-show .3s;
}
.containerClose {
    position: fixed;
    z-index: 100000;
    top: 100px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    background: #fff;
    opacity: 1;
    width: 470px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 0.3125em;
    text-align: center;
    -webkit-animation: swal2-hide .15s forwards;
            animation: swal2-hide .15s forwards;
}
h2 {
  color: #686868;
  margin-top: -50px;
  font-size: 1.1rem;
  font-weight: normal;
}
.closeBtn {
    position: absolute;
    opacity: 1;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
.closeBtn:hover {
    opacity: .7;
}
.logo img {
  margin-top: -45px;
  margin-bottom: 20px;
}
.socialMediaLogin {
  margin: 20px auto;
  padding-bottom: 11px;
  margin-bottom: 0px;
  width: 21rem;
  border-bottom: 1px solid rgb(218, 218, 218);
}
.socialMediaLogin button {
    position: relative;
    width: 300px;
    height: 40px;
    border-radius: 2px;
    border: 0px solid rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.15);
    margin-bottom: 15px;
    font-size: 14px;
    background: #ffffff;
    font-family: 'Roboto', sans-serif;
}
.socialMediaLogin button:hover {
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.22);
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.22);
    cursor: pointer;
}
.socialMediaLogin button p {
    margin: 0;
    margin-left: 11px;
    color: #000000;
    opacity: .54;
}
.googleImg {
    position: absolute;
    left: 12px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.group { 
    width: 300px;
  position:relative; 
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom:-15px;
}
input {
  font-size:16px;
  color: rgb(99, 99, 99);
  padding:10px 10px 10px 5px;
  display:block;
  width:285px;
  border:none;
  border-bottom:1px solid #d3d3d3;
}
input:focus { 
    outline:none;
}
label {
  color:#999; 
  font-size:17px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:1px;
  top:10px;
  -o-transition:0.2s ease all;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
input:focus ~ label, input:valid ~ label {
  top:-20px;
  font-size:14px;
  color:#45b19d;
}
.bar 	{ position:relative; display:block; width:300px; }
.bar:before, .bar:after 	{
  content:'';
  height:2px; 
  width:0;
  bottom:1px; 
  position:absolute;
  background:#52ae9a; 
  -o-transition:0.2s ease all; 
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%; 
}
input:focus ~ .bar:before, input:focus ~ .bar:after {
  width:50%;
}
.highlight {
  position:absolute;
  height:60%; 
  width:100px; 
  top:25%; 
  left:0;
  pointer-events:none;
  opacity:0.5;
}
input:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}
@-webkit-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
/* /////////////////// */
.notUser {
    width: 80%;
  margin: 10px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 7px;
  font-size: 0.9rem;
  border-top: 1px solid rgb(218, 218, 218);
}
.notUser a {
  color: rgb(21, 151, 151);
}
.notUser a:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.notUser p {
  margin-top: 7px;
}
.notUserQuestion {
    width: 50%;
    text-align: center;
    color: rgb(145, 145, 145);
}
.join {
  margin-left: 5px;
  color: rgb(21, 151, 151);
}
.regBtn {
  height: 45px;
  width: 20rem;
  margin-top: 0px;
  margin-bottom: 10px;
  border: 1px solid rgba(255, 255, 255, 0);
  -webkit-box-shadow: 0px 0px 5px 1px rgb(231, 231, 231);
          box-shadow: 0px 0px 5px 1px rgb(231, 231, 231);
  background: #64C3A2;
  border-radius: 3px;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
}
.regBtn p {
  margin-top: 9px;
}
.regBtn:hover {
    opacity: .9;
    cursor: pointer;
}
.forgotPassword {
  color: rgb(21, 151, 151);
  cursor: pointer;
  width: 150px;
  margin-left: 235px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0.9rem;
}
.forgotPassword:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  z-index: 10;
}
.claim p {
    color: rgb(118, 118, 118);
    font-size: 12px; 
    width: 80%; 
    margin: 0px auto; 
    margin-bottom: 10px
}
.claim a {
    cursor: pointer;
    font-weight: bold;
    color: rgb(40, 40, 40);
}
.claim a:hover {
    text-decoration: underline;
}
.logo {
    margin-top: 63px;
    margin-bottom: -10px;
    z-index: 13;
}
.logo img {
    height: 50px;
}
.logo:hover .originalLogo {
    display: none;
}
.logo:hover .hoverLogo {
    display: initial;
}
.hoverLogo {
    display: none;
}
.logo p {
    font-size: 3rem;
    cursor: pointer;
    color: rgb(20, 121, 119);
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-decoration-thickness: 1px;
    -webkit-text-decoration-color: rgb(20, 121, 119);
            text-decoration-color: rgb(20, 121, 119);
}
.confirmEmail h2 {
    color: #64C3A2;
    text-align: center;
    font-size: 2.3rem;
    margin-top: 20px;
    margin-bottom: 10px;
}
.confirmEmail img {
    margin-top: 25px;
}
.confirmEmail p {
    width: 90%;
    margin: 15px auto;
    color: rgb(70, 70, 70);
    font-size: 1.3rem;
}
.confirmEmail button {
    width: 160px;
    height: 28px;
    padding-bottom: 2px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #dddddd00;
    border-radius: 3px;
    background: rgb(255, 255, 255);
    font-size: 1rem;
    cursor: pointer;
    background: #3D4056;
    color: #EEEEEE;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.forgotPwMessage {
    width: 80%;
    margin: 10px auto;
    color: gray;
}
@-webkit-keyframes swal2-show {
  0% {
    -webkit-transform: scale(.7);
            transform: scale(.7);
  }

  45% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }

  80% {
    -webkit-transform: scale(.95);
            transform: scale(.95);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes swal2-show {
  0% {
    -webkit-transform: scale(.7);
            transform: scale(.7);
  }

  45% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }

  80% {
    -webkit-transform: scale(.95);
            transform: scale(.95);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes swal2-hide {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(.5);
            transform: scale(.5);
    opacity: 0;
  }
}

@keyframes swal2-hide {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(.5);
            transform: scale(.5);
    opacity: 0;
  }
}
@media (max-width: 750px) {
    .containerOpen {
        margin: -20px auto;
    }
    .containerClose {
        margin: -20px auto;
    }
}
@media (max-width: 500px) {
    .containerOpen, .containerOpen {
        width: 95%;
    }
    .logo img {
        width: 290px;
    }
}
</style>