import getUrl from './getUrl.js';

export default function googleAuth() {
  return fetch(`${getUrl()}/v2/auth/google`, {
    method: 'GET',
    headers: {
      'Content-type':'application/json'
    },
    credentials: 'include',
  }).then(res => res.json())
}