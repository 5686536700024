import apiTools from '../../api-tools/index'

const apiEndpoint = 'tag/producer-tag'

import buildPostProducerTag from './post-producer-tag_fetch'
import buildPatchProducerTag from './patch-producer-tag_fetch'
import buildGetProducerTags from './get-producer-tags_fetch'
import buildDeleteProducerTag from './delete-producer-tag_fetch'
import buildPatchAssignTags from './patch-assign-tags_fetch'

const postProducerTag = buildPostProducerTag({
    apiEndpoint,
    apiTools
})
const patchProducerTag = buildPatchProducerTag({
    apiEndpoint,
    apiTools
})
const getProducerTags = buildGetProducerTags({
    apiEndpoint,
    apiTools
})
const deleteProducerTag = buildDeleteProducerTag({
    apiEndpoint,
    apiTools
})
const patchAssignTags = buildPatchAssignTags({
    apiEndpoint,
    apiTools
})

const tagApi = {
    postProducerTag,
    patchProducerTag,
    getProducerTags,
    deleteProducerTag,
    patchAssignTags
}

export default tagApi