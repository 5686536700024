import apiTools from '../api-tools/index'

const apiEndpoint = 'order'

import buildGetUserOrders from './get-user-orders_fetch'
import buildGetOrder from './get-order_fetch'
import buildPatchItemQuantities from './patch-item-quantities_fetch'
import buildTrashOrder from './trash-order_fetch'
import buildRemoveItemFromOrder from './remove-item-from-order_fetch'

const getUserOrders = buildGetUserOrders({
    apiEndpoint,
    apiTools
})
const getOrder = buildGetOrder({
    apiEndpoint,
    apiTools
})
const patchItemQuantities = buildPatchItemQuantities({
    apiEndpoint,
    apiTools
})
const trashOrder = buildTrashOrder({
    apiEndpoint,
    apiTools
})
const removeItemFromOrder = buildRemoveItemFromOrder({
    apiEndpoint,
    apiTools
})

const orderApi = {
    getUserOrders,
    getOrder,
    patchItemQuantities,
    trashOrder,
    removeItemFromOrder
}

export default orderApi