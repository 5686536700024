import apiTools from '../api-tools/index'
import buildCloseSession from './close-session_fetch'



const apiEndpoint = 'auth'

const closeSession = buildCloseSession({
    apiEndpoint,
    apiTools
})


const authApi = {
    closeSession
}
export default authApi