<template>
    <div class="searchComponent">
        <input 
            class="searchInput"
            type="text" 
            v-model="searchWord" 
            :placeholder="placeholder" 
            @keypress.enter="search"
        > 
        <img class="illustration" :src="require('@/assets/illustrations/elements/fowardLinkIll.svg')"/>
        <div class="searchImgContainer">
            <img 
                class="searchImg"
                @click="search"
                :src="require('@/assets/icons/menus/search.svg')" 
                alt=""
            >
        </div>
        <p class="categoryText" v-if="searchWord && idListingTag">Otsingu kategooria: {{category}}</p>
        <div class="conMenu">
            <vue-context ref="menu" :lazy='true'>
                <li>
                    <a href="#" @click.prevent="onClick($event.target.innerText)">Kõik kategooriad</a>
                </li>
                <li v-for="element of availableCategories" :key="element.id">
                    <a href="#" @click.prevent="onClick($event.target.innerText, element.idListingTag)">{{element.name}}</a>
                </li>
            </vue-context>
        </div>
    </div>
</template>

<script>
import VueContext from 'vue-context';

export default {
    name: 'searchBoxComponent',
    components: {
        VueContext
    },
    props: {
        buttonsVisible: {
            require: true,
            default: true
        },
        isGuest: {
            default: false
        }
    },

    data() {
        return {
            placeholder: 'Otsi poode ja tooteid..',
            availableCategories: '',
            category: '',
            searchWord: '',
            idListingTag: null,
        } 
    },  
    methods: { 
        search() {
            this.$emit('loader')
            if(this.searchWord != '') {
                if(window.location.pathname != '/otsing') {
                    this.$router.push({
                        name: 'searchListings',
                        params: {
                            idListingTag: this.idListingTag,
                            searchWord: this.searchWord,
                            category: this.category
                        }
                    })
                } else {
                    this.$emit('dataFetchListing', this.idListingTag, this.searchWord)
                    this.$emit('dataFetchShop', this.searchWord)
                    this.$emit('dataFetchProducer', this.searchWord)
                }
            } else {
                this.$emit('loaderOff')
            }
        },

        onClick(name, idListingTag) {
            if(name == 'Kõik kategooriad') {
                this.category = ''
                this.placeholder = 'Otsi tooteid..'
                this.idListingTag = ''
            } else {
                var catName = name
                catName = catName.toLowerCase()
                this.category = catName
                this.idListingTag = idListingTag
                if(window.innerWidth < 550) {
                    this.placeholder = `${catName}`
                } else {
                    this.placeholder = `Otsi ${catName} kategooriast..`
                }
            }
        },

        dataFetchCategories: async function() {
            fetch(this.fetchIp+'/v2/listing/categories?idListingTagParent=0', {
                method: 'GET',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json()) 
            .then((res) => {
                this.availableCategories = res.data.listingsCategories
                this.$emit('emitData', this.availableCategories)
            })
        },
        
    },
    created() {
        this.dataFetchCategories()
    }
}
</script>

<style scoped>
@import '~vue-context/dist/css/vue-context.css';

.searchComponent {
    position: relative;
    text-align: center;
    padding-left: 10px;
    border-radius: 10px;
    overflow: hidden;
    height: 42px;
    width: 100%;
    margin-left: -10px;
    padding-left: 10px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    background: rgb(248, 248, 248);
}
.searchComponent:hover {
    background: rgb(253,253,253);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
}
.searchInput {
    position: relative;
    z-index: 20;
    border: 0;
    border-right: 0;
    border-radius: 10px;
    height: 42px;
    width: 100%;
    background: transparent;
    margin-left: -10px;
    padding-left: 10px;
    color: rgb(100, 100, 100);
    font-size: 1rem;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-appearance: none;
}
input:focus {
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.12);
            box-shadow:  0px 0px 5px 1px rgba(0, 0, 0, 0.12);
    color: rgb(7, 145, 127);
    outline: none;
}
.searchImgContainer {
    position: absolute;
    z-index: 25;
    right: 0;
    top: 0;
    height:100%;
    width: 60px;
    background: rgb(255, 255, 255);
    -webkit-box-shadow: -2px 0px 4px -2px rgba(0,0,0,0.2);
    box-shadow: -2px 0px 4px -2px rgba(0,0,0,0.2);
    cursor: pointer;
}
.searchImgContainer:hover {
    background: rgb(250,250,250);
}
.searchImg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 21px; 
    cursor: pointer;
}
.illustration {
    position: absolute;
    z-index: 10;
    top: 5px;
    left: 0;
    right: 0;
    margin: auto;
    width: 270px;
    opacity: .07;
}
@media (max-width: 500px) {
    .searchComponent {
        width: 100%;
        background: white;
    }
    .illustration {
        opacity: 0.05;
    }
    .searchImgContainer {
        background: rgb(248,248,248);
    }
}
@media (max-width: 750px) {
    .searchComponent {
        height: 30px;
        padding-bottom: 3px;
    }
    .searchInput {
        height: 30px;
        padding-bottom: 3px;
    }
}
</style>