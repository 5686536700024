import Vue from 'vue'
import App from './App.vue'
import router from './router'
import responsive from 'vue-responsive'
import { io } from "socket.io-client";
import VueChatScroll from 'vue-chat-scroll'
import VueLazyload from 'vue-lazyload'
import LoadScript from 'vue-plugin-load-script';
import VueProgress from 'vue-progress'
import VueSimpleAlert from "vue-simple-alert";
import Notifications from 'vue-notification'
import velocity      from 'velocity-animate'
import VueLoadingButton from 'vue-loading-button'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Vue2TouchEvents from 'vue2-touch-events'
import VueMeta from 'vue-meta'
import "primeicons/primeicons.css";

// import 'primevue/resources/themes/saga-blue/theme.css';
import PrimeVue from 'primevue/config/PrimeVue';

// import VueLoadScript from 'vue-load-script-plus'
// Vue.use(VueLoadScript)


Vue.use(VueMeta)

Vue.use(Vue2TouchEvents, {
    disableClick: true
})
Vue.component("v-select", vSelect);

Vue.use(VueLoadingButton)
Vue.use(Notifications, { velocity })

Vue.use(VueSimpleAlert)
Vue.use(VueProgress)

Vue.use(LoadScript)

Vue.use(VueLazyload)
Vue.use(responsive)
Vue.use(VueChatScroll)
Vue.use(PrimeVue)


import eventBus from './eventBus'

import monetaryOperations from './a-helperFunctions/dineroLibrary'
Vue.prototype.$monetaryOperations = monetaryOperations

import dateLibrary from './a-helperFunctions/dateLibrary'
Vue.prototype.$dateLibrary = dateLibrary

import eventTracker from './eventTracker/tracker'
Vue.prototype.$track = eventTracker

let socket = io(process.env.VUE_APP_MAIN_URL, {
    withCredentials: true
})
Vue.prototype.$socket = socket

socket.on('privateMessage', (data, callbackFn) => {
    eventBus.$emit("privateMessage", data, callbackFn)
})
socket.on('disputeMessage', (data, callbackFn) => {
  eventBus.$emit("disputeMessage", data, callbackFn)
})
socket.on('notification', (data) => {
    eventBus.$emit("notification", data)
})

Vue.prototype.$umami = {
    cookie: process.env.VUE_APP_UMAMI_AUTH_COOKIE,
    websiteNr: '1',
    url: process.env.VUE_APP_UMAMI_URL
}

Vue.prototype.fetchIp = process.env.VUE_APP_MAIN_URL

Vue.config.productionTip = false


const app = new Vue({
    data: { loading: false, footerLoading: false },
    router,
    render: h => h(App),
    eventBus
}).$mount('#app')

router.beforeEach(function (to, from, next) {
    app.loading = true
    app.footerLoading = true
    next()
})
router.afterEach(() => {
    setTimeout(() => {
        app.loading = false
    }, 350)
    setTimeout(() => {
        app.footerLoading = false
    }, 1500)
})
