const trackedEvents = {
    addToOrderCount: {
        isActive: true
    },
    orderDeletedCount: {
        isActive: true
    },
    formalizePageVisits: {
        isActive: true
    },
    transportInstructionsConfirmed: {
        isActive: true
    },
    transportInstructionsDeleted: {
        isActive: true
    },
    orderFormalizedCount: {
        isActive: true
    }
}



// how many orders were deleted? 
    // (orderDeletedCount x 100) / addToOrderCount

// how many orders made it to formalize page? 
    // (formalizePageVisits x 100) / addToOrderCount
    
// how many of them had transport instructions confirmed? 
    // (transportInstructionsConfirmed x 100) / formalizePageVisits

// how many of them were formalized?
    // (orderFormalizedCount x 100) / transportInstructionsConfirmed

// how many transport instructions were edited?
    // (transportInstructionsDeleted x 100) / orderFormalizedCount


export default trackedEvents