export default function buildPatchTagFilterElements({
    apiEndpoint,
    apiTools
}) {
    return async function patchTagFilterElements(args) {
        const url = apiTools.resolveUrl({
            ...args,
            apiEndpoint: `${ apiEndpoint }/elements` 
        })
       
        const res = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(args.body)
        });
        return await res.json();
    }
}