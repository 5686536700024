export default function buildPatchListing({
    apiEndpoint,
    apiTools
}) {
    return async function patchListing(args) {
        const url = apiTools.resolveUrl({
            ...args,
            apiEndpoint: `${ apiEndpoint }`
        })
       
        const res = await fetch(url, {
            method: 'PATCH',
            // headers: {
            //     'Content-Type': 'multipart/form-data'
            // },
            credentials: 'include',
            body: args.body
        });
        return await res.json();
    }
}