export default function markChatRead({
  messages,
  idChat,
  idUser,
  socketInstance
}) {
    if(messages.length) {
        console.log(arguments[0])
      const lastMessage = messages[messages.length - 1]
      // console.log(lastMessage.idMessage, 'ilastmastg')
      if(lastMessage.idAuthor != idUser) {
        socketInstance.emit('markChatRead', {
          item: 'message',
          idItem: [ lastMessage.idMessage ],
          idChat: idChat,
          idLastReadMessage: lastMessage.idMessage
        })
      }
    }
}
