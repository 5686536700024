<template>
    <div class="wrapper">
        <div class="background" @click="$emit('closeContainer')"></div>
        <div :class="{ 'methodPopupDeactive': !isContainerOpen, 'methodPopup': isContainerOpen }" v-on-clickaway="clickAway">
            <div class="header">
                <img 
                    class="icon"
                    height="36px" 
                    width="36px" 
                    :src="require('@/assets/icons/menus/main/seller/support.svg')"
                />
                <p class="title">Kontakteeru kasutajatoega</p>
                <img 
                    class="downArrow"
                    @click="isContainerOpen = false; $emit('closeContainer')"
                    v-if="isContainerOpen"
                    height="16px"
                    width="16px"
                    :src="require('@/assets/icons/categories/close(2).svg')" 
                    alt=""
                >
            </div>

            <div class="bodyWrapper">
                <div style="text-align: center" v-if="!isSent">
                    <p></p>
                    <p class="errorMsg" v-if="errors">{{errors}}</p>
                    <textarea name="" v-model="userNote" placeholder="Sisesta sõnum.." cols="30" rows="10"></textarea>
                    <br>
                    <div class="btns">
                        <button 
                            class="sendSupportMessageBtn" 
                            @click="contactSupport()"
                        >   Saada Kiri</button>
                        <button 
                            class="cancelBtn"
                            @click="$emit('closeContainer')" 
                            style="background: rgb(160, 162, 168)"
                        >   Tühista</button>
                    </div>
                </div>
                <div class="sentNotice" v-if="isSent">
                    <h2>Kiri Saadetud!</h2>
                    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" v-if="successIcon">
                        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                    <p>Sinuga võtab ühendust <b style="color: red">{{ assignee.displayName }}</b></p>
                    <div class="letterBox">
                        <a>{{userNote}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
    name: 'supportPopup',
    mixins: [ clickaway ],
    data() {
        return {
            isContainerOpen: true,

            userNote: '',
            isSent: false,
            successIcon: false,
            errors: null,
            assignee: undefined
        }
    },  
    methods: {
        clickAway() {
            this.isContainerOpen = false 
            this.$emit('closeContainer')
        },

        contactSupport() {
            fetch(this.fetchIp + '/v2/org/task', {
                method: 'POST',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "name": 'userSupport',
                    "note": this.userNote
                })
            })
            .then(resp => resp.json())
            .then((resp) => {
                if(resp.status == 'success') {
                    this.assignee = resp.data.assignee
                    this.isSent = true
                    this.successIcon = true
                } 
                if(resp.message == 'Summoners note must be atleast 10 char long.') {
                    this.errors = 'Kiri peab olema vähemalt 10 tähemärki pikk.'
                }
                if(resp.message == 'Summoners note has exceeded maximum allowed length.') {
                    this.errors = 'Kirja pikkus ületab lubatud maksimumi(6000 tähemärki).'
                }
            })
        },
    },
}
</script>
<style scoped>
.background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}
.methodPopup {
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
    background: #ffffff;
    width: 650px;
    height: 450px;
    max-height: 70%;
    padding-top: 58px;
    border-radius: 20px;
    overflow: hidden;
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0; 
    margin: auto; 
    -webkit-animation: swal2-show .3s;
            animation: swal2-show .3s;
}
.methodPopupDeactive {
    width: 650px;
    border-radius: 20px;
    padding-bottom: 7px;
    overflow: hidden;
    background: rgb(245, 245, 245);
    position: fixed;
    z-index: 100000;
    top: 50px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    -webkit-animation: swal2-hide .15s forwards;
            animation: swal2-hide .15s forwards;
}
.header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: none;
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgb(240, 240, 240);
    overflow: hidden;
}
.icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: .7;
    margin-left: 12px;
}
.title {
    position: relative;
    z-index: 10;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 55px;
    font-size: 18px;
    color: #636363;
    font-family: 'Lato', sans-serif;
}
.downArrow {
    position: absolute;
    right: 25px;
    bottom: 0;
    top: 0;
    margin: auto;
    cursor: pointer;
}
#arrow {
    position: absolute;
    right: 7px;
    top: 2px;
}
.bodyWrapper {
    position: relative;
    overflow: auto;
    /* height: fit-content; */
    max-height: 100%;
    width: 100%;
    scrollbar-color: rgb(210, 210, 210) white;
}
.errorMsg {
    position: absolute;
    top: -5px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #d6450b;
    color: white;
    font-family: 'DM sans', sans-serif;
    font-size: 1.1rem;
}
textarea {
    width: 75%;
    margin: 0 auto;
    margin-top: 55px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgb(210,210,210);
    background: rgb(252,252,252);
    resize: none;
}
textarea:focus {
    outline: none;
}
.btns {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
}
button {
    width: 200px;
    height: 34px;
    padding-bottom: 2px;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
    border: 1px solid #dddddd00;
    border-radius: 3px;
    background: rgb(255, 255, 255);
    font-size: 1rem;
    cursor: pointer;
    background: #64C3A2;
    color: #EEEEEE;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
}
button:hover {
    opacity: .9;
}
.sentNotice {
    margin-top: 30px;
    text-align: center;
}
.sentNotice p {
    color: rgb(14, 126, 102);
    font-weight: bold;
}
h2 {
    color: rgb(75,75,75);
}
.letterBox {
    width: 90%;
    margin: 15px auto;
    padding-top: 10px;
    border-top: 1px solid rgb(220,220,220);
    text-align: left;
}
.letterBox a {
    font-size: .9rem;
    word-wrap: break-word;
}
@-webkit-keyframes swal2-show {
  0% {
    -webkit-transform: scale(.7);
            transform: scale(.7);
  }

  45% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }

  80% {
    -webkit-transform: scale(.95);
            transform: scale(.95);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes swal2-show {
  0% {
    -webkit-transform: scale(.7);
            transform: scale(.7);
  }

  45% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }

  80% {
    -webkit-transform: scale(.95);
            transform: scale(.95);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes swal2-hide {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(.5);
            transform: scale(.5);
    opacity: 0;
  }
}

@keyframes swal2-hide {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(.5);
            transform: scale(.5);
    opacity: 0;
  }
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #64C3A2;
    fill: none;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    position: relative;
    left: 0;
    right: 0;
    margin: auto;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #64C3A2;
    stroke-miterlimit: 10;
    -webkit-box-shadow: inset 0px 0px 0px #ffffff;
            box-shadow: inset 0px 0px 0px #ffffff;
    -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@-webkit-keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@-webkit-keyframes scale {
    0%, 100% {
        -webkit-transform: none;
                transform: none;
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes scale {
    0%, 100% {
        -webkit-transform: none;
                transform: none;
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1);
    }
}
@-webkit-keyframes fill {
    100% {
        -webkit-box-shadow: inset 0px 0px 0px 63px #ffffff;
                box-shadow: inset 0px 0px 0px 63px #ffffff;
    }
}
@keyframes fill {
    100% {
        -webkit-box-shadow: inset 0px 0px 0px 63px #ffffff;
                box-shadow: inset 0px 0px 0px 63px #ffffff;
    }
}


@media (max-width: 680px) {
    .methodPopup {
        width: 95%;
    }
}
@media (max-width: 430px) {
    button {
        width: 81%;
    }
}
</style>