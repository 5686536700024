import apiTools from '../api-tools/index'

const apiEndpoint = 'sale-point'

import buildPostSalePoints from './post-sale-points'
import buildPatchSalePoint from './patch-sale-point'
import buildDeleteSalePoint from './delete-sale-point'
import buildGetSalePoints from './get-sale-points'



const postSalePoints = buildPostSalePoints({
    apiEndpoint,
    apiTools
})

const patchSalePoint = buildPatchSalePoint({
    apiEndpoint,
    apiTools
})

const deleteSalePoint = buildDeleteSalePoint({
    apiEndpoint,
    apiTools
})

const getSalePoints = buildGetSalePoints({
    apiEndpoint,
    apiTools
})


const salePointsApi = {
    postSalePoints,
    patchSalePoint,
    deleteSalePoint,
    getSalePoints
    
}

export default salePointsApi