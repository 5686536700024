import getUrl from './getUrl.js';
import router from '../router/index'

export default function roundtrip(originPage) {
    try {
        const pages = {
            app: ['guest', 'seller', 'base', 'organization', 'administrator'], 
            adminViewPanel: ['organization', 'administrator'], 
            adminPanel: ['organization', 'administrator'], 
            adminDisputes: ['organization', 'administrator'], 
            adminSupport: ['organization', 'administrator'], 
            adminProducerApplications: ['organization', 'administrator'], 
            adminWithdrawalReq: ['organization', 'administrator'], 
            adminCategories: ['organization', 'administrator'], 
            adminTagRequests: ['organization', 'administrator'], 
            adminNotifications: ['organization', 'administrator'], 
            adminPasswords: ['organization', 'administrator'], 
            producerTagsAdmin: ['organization', 'administrator'], 
            dataTests: ['organization', 'administrator'], 
            emailTemplates: ['organization', 'administrator'], 
            browseShops: ['guest', 'seller', 'base', 'organization', 'administrator'], 
            browseProducers: ['guest', 'seller', 'base', 'organization', 'administrator'], 
            shopListing: ['guest', 'seller', 'base', 'organization', 'administrator'], 
            pageNotFound: ['guest', 'seller', 'base', 'organization', 'administrator'], 
            underConstruction: ['guest', 'seller', 'base', 'organization', 'administrator'], 
            newListing: ['seller', 'organization', 'administrator'], 
            editListing: ['seller', 'organization', 'administrator'], 
            userShop: ['guest', 'seller', 'base', 'organization', 'administrator'], 
            producerProfile: ['guest', 'seller', 'base', 'organization', 'administrator'], 
            editShop: ['seller', 'organization', 'administrator'], 
            editProducerData: ['producer', 'organization', 'administrator'], 
            salePoints: ['producer', 'seller', 'organization', 'administrator'], 
            shopMarketing: ['seller', 'producer', 'organization', 'administrator'], 
            home: ['guest', 'seller', 'base', 'organization', 'administrator'],
            merchantApplication: ['seller', 'base', 'organization', 'administrator', 'guest'],
            legalMain: ['guest', 'seller', 'base', 'organization', 'administrator'],
            tutorialsMain: ['guest', 'seller', 'base', 'organization', 'administrator'],
            communicationMain: ['guest', 'seller', 'base', 'organization', 'administrator'],
            communicationCentral: ['guest', 'base', 'seller', 'organization', 'administrator'],
            searchListings: ['guest', 'seller', 'base', 'organization', 'administrator'],
            categories: ['guest', 'seller', 'base', 'organization', 'administrator'],
            allCategories: ['guest', 'seller', 'base', 'organization', 'administrator'],
            clientProfile: ['seller', 'base', 'organization', 'administrator'], 
            dashMain:['base', 'seller', 'organization', 'administrator'],
            dashProducts:['seller', 'organization', 'administrator'],
            dashOrders:['base', 'seller', 'organization', 'administrator'],
            supportPage:['guest', 'base', 'seller', 'organization', 'administrator'],
            dashFeedback:['seller', 'organization', 'administrator'],
            dashWallet:['base', 'seller', 'organization', 'administrator'],
            dashDeals:['seller', 'organization', 'administrator'],
            testList:['base', 'seller', 'organization', 'administrator'],
            settingsMain:['base', 'seller', 'organization', 'administrator'],
            userStats:['base', 'seller', 'organization', 'administrator'],
            dashShopInfo:['seller', 'organization', 'administrator'],
            formalizeOrder:['base', 'seller', 'organization', 'administrator', 'guest'],
            orderPayment:['base', 'seller', 'organization', 'administrator'],
            finalOrderPage:['base', 'seller', 'organization', 'administrator'],
            dispute:['base', 'seller', 'organization', 'administrator'],
            fullSellerForm:['organization', 'administrator', 'pre-approved-seller'],
            allConversations: ['base', 'seller', 'organization', 'administrator'],
            mailbox: ['base', 'seller', 'organization', 'administrator'],
            notificationPage: ['base', 'seller', 'organization', 'administrator'],
            editShopApplication: ['base', 'organization', 'administrator'],
            identifyUser: ['base', 'organization', 'administrator'],
            editTransport: ['seller', 'organization', 'administrator'],
            login: ['guest'],
            register: ['guest'],
            
            landingPage: ['guest', 'seller', 'base', 'organization'],
        };
        const allowedGroups = pages[originPage]
        return fetch(`${getUrl()}/v2/roundtrip`, {
            method: 'GET',
            headers: {
                'Content-type':'application/json'
            },
            credentials: 'include',
        })
        .then((res) => res.json())
        .then((res) => {
            try {
                const idUser = res.idUser
                const userGroups = res.userGroups
                const authAsRole = res.authAsRole
                var accessAllowed
                if(userGroups.some(group => group === 'banned')) {
                    router.push({
                        name: 'bannedPage'
                    })
                }
                if(userGroups.some(group => group === 'pre-approved-seller')) {
                    router.push({
                        name: 'becomeSeller'
                    })
                }
                const validGroups = allowedGroups.reduce((initValue, item) => {
                    for(const oneOfUserGroups of userGroups) {
                        if(oneOfUserGroups == item) {
                            return initValue +1
                        }
                    }
                    return initValue
                }, 0)
                accessAllowed = (validGroups > 0) ? true : false
                return {
                    accessAllowed, 
                    authAsRole,
                    userGroups,
                    idUser
                }
            } catch (error) {
                return {
                    accessAllowed: false,
                    idUser: res.idUser,
                    userGroups: res.userGroups
                }
            }
    
        })
    } catch (error) {
        return {
            accessAllowed: false
        }
    }

}