import getUrl from './getUrl.js';

export default function logoutFetch() {
  return fetch(`${getUrl()}/v2/auth/logout`, {
    method: 'POST',
    headers: {
      'Content-type':'application/json'
    },
    credentials: 'include',
  }).then(res => res.json())
}