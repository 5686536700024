<template>
<div class="componentWrapper">
    <div 
        :class="{ 
            'menuContainer-fixed': isMenuFixed && isFixedMenuActive, 
            'menuContainer-fixed-noShadow': isMenuFixed && isFixedMenuActive && currentActivePage == 'category' || isMenuFixed && isFixedMenuActive && currentActivePage == 'home', 
            'menuContainer': !isMenuFixed || !isFixedMenuActive,
            'menuContainer-noShadow': !isMenuFixed || !isFixedMenuActive && currentActivePage == 'category',
            'menuContainerHidden': hideMenu && isFixedMenuActive 
        }"
    >   
        <div class="wrapper" :class="{ 'wrapperSmall': currentActivePage == 'singleListing' || currentActivePage == 'userShop'|| currentActivePage == 'shop'}">
            <div :class="{ 'logoHidden': loadAnimationLogo, 'logo': !loadAnimationLogo }">
                <router-link :to="{ path: '/' }">
                    <img 
                        @click="routeChangeToHome"
                        class="originalLogo"
                        :src="require('@/assets/logos/omatoodangLogo.svg')" 
                        alt=""
                    >
                </router-link>
            </div>
            <searchBar v-if="
                currentActivePage == 'home' || 
                currentActivePage == 'singleListing' ||
                currentActivePage == 'userOrders' || 
                currentActivePage == 'finalOrderPage' || 
                currentActivePage == 'formalizeOrder' || 
                currentActivePage == 'communicationCentral'
                
            "/>

            <div 
                :class="{ 'topMenuHidden': loadAnimationMenu, 'topMenu': !loadAnimationMenu }"
                v-on-clickaway="away"
                v-if="!isApplicationPageActive"
            >
                <orderPopup
                    v-if="userGroups.length > 0 &&!isUserFromGroup('seller')"
                    ref="orderComponent"
                    :isOrderPopupActive='isOrderPopupActive'
                    :isMenuFixed="isMenuFixed"
                    :topMargin="topMargin"
                    :containerPosition="containerPosition"
                    :rightMargin="rightMargin"
                    @orders='orders'
                    @listingDeletedFromOrder='listingDeletedFromOrder'
                    @activateBackground='activateBackground'
                    @deactivatePopup='deactivateBackground'
                />
            <!-- ///////////////////////////NOTIFICATION ICON && BOX\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ -->

                <div 
                    class="notifications" 
                >
                    <img 
                        v-if="!notIcon" 
                        style="opacity: 1"
                        v-on:click='notif'
                        :src="require('@/assets/icons/menus/notificationIco.svg')"
                    />
                    <img 
                        v-if="notIcon" 
                        v-on:click='notif'
                        style="opacity: 1"
                        :src="require('@/assets/icons/menus/notificationIcoActive.svg')"
                    />
                    <div 
                        class="newUpdateNotifs"
                        :class="{ 'newUpdateNotifsSeller': isUserFromGroup('seller') }"
                        v-on:click='notif'
                        v-if="newNotificationList.length > 0"
                    >   <p>{{this.newNotificationList.length}}</p></div>
                    
                </div>
                <div :class="{ 'notifBox': notifications, 'notifBoxGone': !notifications }" >
                    <div class="tabHeader" v-if="newNotificationList.length">
                        <p v-if="this.newNotificationList.length == 1">{{this.newNotificationList.length}} Lugemata Teavitus</p>
                        <p v-if="this.newNotificationList.length > 1">{{this.newNotificationList.length}} Lugemata Teavitust</p>
                    </div>
                    <div class="allMessagesBox" :class="{ 'allMessagesBoxEmpty':  !newNotificationList.length}">
                        <img 
                            v-if="!newNotificationList.length"
                            height="120px" width="120px" 
                            style="margin-top: 35px; margin-bottom: -95px"
                            :src="require('@/assets/icons/menus/notificationSilver.svg')"
                        />
                        <h2 
                            v-if="!newNotificationList.length" 
                            style="text-align: center; font-size: 1.8rem; color: silver; margin: 70px auto; margin-top: 120px; margin-bottom: 0px"
                        >   Lugemata Teavitused Puuduvad</h2>
                        <button 
                            class="viewAllBtn"
                            @click="notif(); $router.push({'name': 'notifications'})"
                            v-if="!newNotificationList.length && !isUserFromGroup('guest')" 
                        >   Vaata kõiki teavitusi</button>
                        <div 
                            class="firstMessage" 
                            v-for="element of newNotificationList" 
                            @click="clearNotifs(element.idNotification)"
                            :key='element.id'
                        >
                            <p class="subject">
                                <b>{{element.subject}}</b>
                            </p>
                            <p 
                                class="notificationBody">
                                {{element.text}}
                            </p>
                            <div class="dateNotif">
                                {{$dateLibrary.formatDate(element.timestamp, 'D. MMMM (HH:mm)')}}
                            </div>
                        </div>
                    </div>
                    <p 
                        class="allMessages" 
                        v-if="newNotificationList.length && !isUserFromGroup('guest')"
                        @click="clearAllNotifs()"
                    >   Märgi kõik loetuks</p>
                </div>
                
            <!-- ///////////////////////////MESSAGE BOX\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ -->
                <div 
                    class="messages" 
                    v-on:click='message'
                >
                    <img 
                        v-if="!messageIcon" 
                        style="margin-top: -2px; opacity: 1"
                        :src="require('@/assets/icons/menus/messages.svg')"
                    />
                    <img 
                        v-if="messageIcon" 
                        style="margin-top: -2px; opacity: 1" 
                        :src="require('@/assets/icons/menus/chatIcoActive.svg')"
                    />
                    <div 
                        class="newUpdateMsgs"
                        :class="{ 'newUpdateMsgsSeller': isUserFromGroup('seller') }"
                        v-if="notReadSortedList.length > 0"
                    >   <p>{{this.notReadSortedList.length}}</p></div>
                </div>  
                <div :class="{ 'messageBox': messages, 'messageBoxGone': !messages }" >
                    <div class="tabHeader" v-if="notReadSortedList.length !== 0 || readSortedList.length !== 0">
                        <p>{{this.notReadSortedList.length}} Lugemata Sõnumit</p>
                    </div>
                    <div class="allMessagesBox" :class="{ 'allMessagesBoxEmpty':  notReadSortedList.length === 0 && readSortedList.length === 0}">
                        <img 
                            v-if="notReadSortedList.length === 0 && readSortedList.length === 0" 
                            height="100px" width="100px" 
                            style="margin-top: 35px; margin-bottom: -95px"
                            :src="require('@/assets/icons/menus/messagesSilver.svg')"
                        />
                        <h2 
                            v-if="notReadSortedList.length === 0 && readSortedList.length === 0" 
                            style="text-align: center; font-size: 2rem; color: silver; width: 500px; margin: 70px auto; margin-top: 110px; margin-bottom: 20px"
                        >   Uued Sõnumid ja Vestlused Puuduvad</h2>
                        <div style="text-align: center">
                            <button 
                                class="viewAllBtn"
                                @click="message(); $router.push({'name': 'singleConversations', 'params': {'idChat': 0}})"
                                v-if="notReadSortedList.length === 0 && readSortedList.length === 0"
                            >   Vaata kõiki vestlusi</button>
                        </div>
                        <div 
                            class="firstMessage" 
                            v-for="chat of notReadSortedList" 
                            :key="chat.id" 
                            @click="openConversation(chat.idChat, chat)"
                        >
                            <div class="messageContainer" v-if="chat.lastMessage.text">
                                <div class="messageImage">
                                    <div 
                                        style="margin-right: 10px; margin-top: 7px" 
                                        class="profileImg"
                                    >
                                        <img 
                                            style="border-radius: 50px; opacity: 1" 
                                            height="65px" 
                                            width="65px" 
                                            :src="determineChatData(chat, 'coverImg')"
                                        >
                                    </div>
                                    <div class="date" style="margin-left: 16px; margin-top: -10px; margin-bottom: -9px">
                                        <p>
                                            {{$dateLibrary.formatDate(chat.lastMessage.timestamp, 'D. ddd HH:mm')}}
                                        </p>
                                    </div>
                                </div>
                                <div class="message" style="margin-left: -25px">
                                    <div class="sender">
                                        <p class="username" style="font-size: 1rem; margin-top: 14px">
                                            <b>{{ determineChatData(chat, 'dialogistName') }}<a v-if="chat.type == 'dispute'" style="color: darkred; font-weight: 400; font-size: .9rem"> (Vaidlus)</a></b>
                                        </p>
                                    </div>
                                    <p class="body">
                                        <b>{{chat.lastMessage.text}}</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div 
                            class="firstMessage" 
                            v-for="chat of slicedChatList" 
                            :key="chat.id" 
                            @click="openConversation(chat.idChat, chat)"
                        >
                            <div class="messageContainer" v-if="chat.lastMessage.text">
                                <div class="messageImage">
                                    <div 
                                        style="margin-right: 10px; margin-top: 7px" 
                                        class="profileImg"
                                    >
                                        <img 
                                            style="border-radius: 50px; opacity: 1" 
                                            height="65px" 
                                            width="65px" 
                                            :src="determineChatData(chat, 'coverImg')"
                                        >
                                    </div>
                                    <div class="date" style="margin-left: 16px; margin-top: -10px; margin-bottom: -9px">
                                        <p>
                                            {{$dateLibrary.formatDate(chat.lastMessage.timestamp, 'D. ddd HH:mm')}}
                                        </p>
                                    </div>
                                </div>
                                <div class="message" style="margin-left: -25px">
                                    <div class="sender">
                                        <p class="username" style="font-size: 1rem; margin-top: 14px">
                                            {{ determineChatData(chat, 'dialogistName') }}
                                            <a v-if="chat.type == 'dispute'" style="color: darkred; font-weight: 400; font-size: .9rem">(vaidlus)</a>
                                        </p>
                                    </div>
                                    <p class="body">{{chat.lastMessage.text}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p 
                        class="allMessages"
                        v-if="notReadSortedList.length !== 0 || readSortedList.length !== 0" 
                        @click="message(); $router.push('/kirjakast/0')"
                    >   Vaata Kõiki Sõnumeid</p>
                </div>

                <div 
                    class="profile"
                    v-if="!profileActive" 
                    v-on:click='profile'
                >
                    <img 
                        :src="require('@/assets/icons/menus/icons8-sort-down-96(1).png')"
                    >
                </div>
                <div 
                    class="profileActive" 
                    v-if="profileActive" 
                    v-on:click='profile'
                >
                    <img 
                        :src="require('@/assets/icons/menus/icons8-sort-down-96(1).png')"
                    >
                </div>
            </div>

        <!-- ///////////////////////////DROPDOWN MAIN MENU BOX\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ -->    
            <div :class="{ 'profileBoxMenu': profiles, 'profileBoxGone': !profiles }">
                <router-link 
                    :to="{ path: '/pood/'+idShop }" 
                    v-if="isUserFromGroup('seller')"
                >
                    <div class="profileButton">
                        <img 
                            height="80px" 
                            width="80px" 
                            v-if="coverImg" 
                            :src="fetchIp+'/static/images/shops/'+coverImg" 
                            alt=""
                        >
                        <div class="name">
                            <p>{{shopName}}</p>
                            <a>Vaata enda poodi</a>
                        </div>
                    </div>
                </router-link>
                
                <div class="profileButton" v-if="!isUserFromGroup('seller') && !isUserFromGroup('producer')">
                    <img 
                        height="70px" 
                        width="70px" 
                        v-if="coverImg" 
                        :src="fetchIp+'/static/images/profiles/'+coverImg" 
                        alt=""
                    >
                    <div 
                        class="name" 
                        style="margin-left: -20px"
                    >
                        <p>{{shopName}}</p>
                        <a>Ostlemiskonto</a>
                    </div>
                </div>

                <router-link 
                    :to="{ path: '/toolaud/pealeht' }"
                    v-if="!isUserFromGroup('seller')"
                >
                    <div class="dashboard">
                        <div class="iconCircle">
                            <img 
                                height="32px" 
                                width="32px" 
                                :src="require('@/assets/icons/menus/main/dashboard.svg')"
                            />
                        </div>
                        <div class="dashName">
                            <p>Töölaud</p>
                            <a>Halda tellimusi, kandeid & teavitusi</a>
                        </div>
                    </div>
                </router-link>

                <router-link 
                    :to="{ path: '/toolaud/pealeht' }"
                    v-if="isUserFromGroup('seller')"
                >
                    <div class="dashboard">
                        <div class="iconCircle">
                            <img 
                                height="32px" 
                                width="32px" 
                                :src="require('@/assets/icons/menus/main/seller/dashboard.svg')"
                            />
                        </div>
                        <div class="dashName">
                            <p>Töölaud</p>
                            <a>Halda tellimusi, kandeid & teavitusi</a>
                        </div>
                    </div>
                </router-link>

                <router-link 
                    :to="{ path: '/tehingud/vastuvotmata' }"
                    v-if="isUserFromGroup('seller')"
                >
                    <div class="profileBoxBtn">
                        <div class="iconCircle">
                            <img 
                                height="32px" 
                                width="32px" 
                                :src="require('@/assets/icons/menus/main/seller/orders.svg')"
                            />
                        </div>
                        <p>Tellimused</p>
                    </div>
                </router-link>

                <router-link 
                    :to="{ path: '/toolaud/pood' }"
                    v-if="isUserFromGroup('seller')"
                >
                    <div class="profileBoxBtn">
                        <div class="iconCircle">
                            <img 
                                height="32px" 
                                width="32px" 
                                :src="require('@/assets/icons/menus/main/seller/shopSettings.svg')"
                            />
                        </div>
                        <p>Poe seaded</p>
                    </div>
                </router-link>

                <router-link 
                    :to="{ path: '/tooted/avalikud' }"
                    v-if="isUserFromGroup('seller')"
                >
                    <div class="profileBoxBtn">
                        <div class="iconCircle">
                            <img 
                                height="32px" 
                                width="32px" 
                                :src="require('@/assets/icons/menus/main/seller/products.svg')"
                            />
                        </div>
                        <p>Minu tooted</p>
                    </div>
                </router-link>

                <a 
                    @click="profiles = false; $emit('openSupportPopup')"
                    v-if="isUserFromGroup('seller')"
                >
                    <div class="profileBoxBtn">
                        <div class="iconCircle">
                            <img 
                                style="margin-left: 2px; opacity: .8" 
                                height="32px" 
                                width="32px" 
                                :src="require('@/assets/icons/menus/main/seller/support.svg')"
                            />
                        </div>
                        <p>Kasutajatugi</p>
                    </div>
                </a>

                <router-link 
                    :to="{ path: '/' }"
                    v-if="!isUserFromGroup('seller')"
                >
                    <div class="profileBoxBtn" @click="routeChangeToHome">
                        <div class="iconCircle">
                            <img 
                                height="32px" 
                                width="32px" 
                                :src="require('@/assets/icons/menus/main/market.svg')"
                            />
                        </div>
                        <p>Turg</p>
                    </div>
                </router-link>
                <router-link 
                    :to="{ path: '/nimistu/poed' }"
                    v-if="!isUserFromGroup('seller')"
                >
                    <div class="profileBoxBtn">
                        <div class="iconCircle">
                            <img 
                                height="32px" 
                                width="32px" 
                                :src="require('@/assets/icons/menus/main/shopList.svg')"
                            />
                        </div>
                        <p>Poodide nimistu</p>
                    </div>
                </router-link>

                <router-link 
                    :to="{ path: '/orbiit' }"
                    v-if="!isUserFromGroup('seller')"
                >
                    <div class="profileBoxBtn">
                        <div class="iconCircle">
                            <img 
                                height="32px" 
                                width="32px" 
                                :src="require('@/assets/icons/menus/main/orbit.svg')"
                            />
                        </div>
                        <p>Orbiit</p>
                    </div>
                </router-link>

                <div 
                    class="logoutBtn" 
                    @click="logOut"
                >
                    <p>Logi Välja</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import core from '../../api/index'
import roundtrip from '@/ApiFetches/roundtripRegulator'
import userProfile from '@/ApiFetches/userProfile'
import markForwarded from '@/ApiFetches/markForwarded'
import logoutFetch from '@/ApiFetches/logoutFetch'
import { mixin as clickaway } from 'vue-clickaway'

import emitMarkChatRead from '@/chatBox/emitMarkChatRead'
import sortedChatList from '@/chatBox/determineIsLastMessageRead'
import workOutChatData from '@/chatBox/determineChatData'

import eventBus from '../../eventBus'

import searchBar from '@/views/menus/components/searchBar.vue'
import orderPopup from '@/views/menus/components/orderPopup.vue'

export default {
  name: 'topMenuLogged',
  components: {
      orderPopup,
      searchBar,
  },
  mixins: [ clickaway ],
    props: {
        fixedScrollPos: {},
        hideMenuScrollPos: {},
        isFixedMenuActive: {},
        isApplicationPageActive: {},
        currentActivePage: {},
        categoryType: {},
    },
  data() {
      return {
        userGroups: [],

        isMenuFixed: false,
        hideMenu: false,
        loadAnimationLogo: true,
        loadAnimationMenu: true,

        notifications: false,
        notIcon: false,
        messages: false,
        messageIcon: false,
        profiles: false,
        profileActive: false,

        newNotificationList: [],
        slicedNotificationList: [],
        slicedChatList: [],
        newMessages: [],
        idUser: '',

        shopName: '',
        coverImg: '',
        idShop: '',
        isOrderPopupActive: false,
        readChats: [],
        readSortedList: [],
        notReadChats: [],
        notReadSortedList: [],
        idUserActiveChatData: '',

        containerPosition: 'absolute',
        topMargin: '45px',
        rightMargin: '7%',
      }
  },

  methods: {
    away: function() {
        if(this.profiles === true || this.notifications === true || this.messages === true || this.isOrderPopupActive === true) {
            this.notifications = false,
            this.messages = false
            this.profiles = false;
            this.messageIcon = false
            this.notIcon = false
            this.profileActive = false
            this.isOrderPopupActive = false
            this.isOrderActive = -1
            this.$emit('deactivateBackground')
        }
    },

    handleScroll() {
        if(this.isFixedMenuActive) {
            if(window.scrollY > this.hideMenuScrollPos) {
                this.hideMenu = true
            } else {
                this.hideMenu = false
            }
            if(window.scrollY > this.fixedScrollPos) {
                this.isMenuFixed = true
            } else {
                this.isMenuFixed = false
                this.isOrderPopupActive = false
                this.$emit('deactivateBackground')
            }
        }
    },

    routeChangeToHome() {
        if(this.$route.path === '/') {
            this.$emit('homeNavigation')
        }
    },

    openOrder(idOrder, idListing) {
        this.$refs.orderComponent.openOrder(idOrder, idListing)
        this.$emit('activateBackground')
    },

    listingDeletedFromOrder() {
        this.$emit('listingDeletedFromOrder')
    },

    activateBackground() {
        this.$emit('activateBackground')
    },

    deactivateBackground() {
        this.$emit('deactivateBackground')
    },

    orders() {
        this.messages = false;
        this.profiles = false;
        this.profileActive = false
        this.notifications = false
        this.notIcon = false
        this.messageIcon = false
        this.isOrderPopupActive = !this.isOrderPopupActive
        if(!this.isOrderPopupActive) {
            this.$emit('deactivateBackground')
        } else {
            this.$emit('activateBackground')
        }
    },

    fetchOrders() {
        this.$refs.orderComponent.fetchOrders()
    },

    notif() {
        this.isOrderPopupActive = false
        this.messages = false;
        this.profiles = false;
        this.profileActive = false
        this.notifications = !this.notifications
        this.notIcon = !this.notIcon
        this.messageIcon = false
        if(this.notIcon) {
            this.activateBackground()
        } else {
            this.deactivateBackground()
        }
    },

    message() {
        this.isOrderPopupActive = false
        this.messageIcon = !this.messageIcon
        this.notIcon = false
        this.notifications = false;
        this.profiles = false;
        this.profileActive = false
        this.messages = !this.messages
        if(this.messages) {
            this.activateBackground()
        } else {
            this.deactivateBackground()
        }
    },
    profile() {
        this.isOrderPopupActive = false
        this.notIcon = false
        this.notifications = false;
        this.messages = false;
        this.profiles = !this.profiles;
        this.profileActive = !this.profileActive
        this.messageIcon = false
    },

    openConversation(idChat, chat) {
        emitMarkChatRead({
            messages: [chat.lastMessage],
            idChat: idChat,
            idUser: chat.idUser,
            socketInstance: this.$socket
        })
        this.getUserChats()
        if(chat.type == 'dispute') {
            var idDispute = []
            idDispute = chat.name.split('-')
            this.$router.push('/vaidlus/'+Number(idDispute[0]))
        } else {
            this.$router.push('/kirjakast/'+idChat)
        }
        this.message()
    },

    determineChatData(chat, dataBit) {
      return workOutChatData({
        chat,
        dataBit,
        idUserActiveChatData: this.idUserActiveChatData,
        idUser: this.idUser
      })
    },

    getUserChats() {
        this.newMessages = []
        this.slicedChatList = []
        this.notReadSortedList = []
        core.chatApi.getUserChats()
        .then((res) => {
            if(res.data.chats != null) {
                this.newMessages = res.data.chats
                const conversationsReturnObj = sortedChatList(this.newMessages, this.idUser)
                this.readChats = conversationsReturnObj.readConversations
                this.notReadChats = conversationsReturnObj.notReadConversations
                this.sort()
            }
        })
    }, 

    sort() {
      this.notReadSortedList = this.notReadChats.sort((a,b) => (a.lastMessage.timestamp < b.lastMessage.timestamp) ? 1 : -1)
      this.readSortedList = this.readChats.sort((a,b) => (a.lastMessage.timestamp < b.lastMessage.timestamp) ? 1 : -1)  

      if(this.notReadSortedList.length == 0) {
         this.slicedChatList = this.readSortedList.slice(0,5)
      }
      else if(this.notReadSortedList.length == 1) {
          this.slicedChatList = this.readSortedList.slice(0,4)
      }
      else if(this.notReadSortedList.length == 2) {
          this.slicedChatList = this.readSortedList.slice(0,3)
      }
      else if(this.notReadSortedList.length == 3) {
          this.slicedChatList = this.readSortedList.slice(0,2)
      }
      else if(this.notReadSortedList.length == 4) {
          this.slicedChatList = this.readSortedList.slice(0,1)
      }
      else if(this.notReadSortedList.length >= 5) {
          this.slicedChatList = this.readSortedList.slice(0,0)
      }
    },

    getUserNots() {
        this.newNotificationList = []
        this.slicedNotificationList = []
        core.notificationApi.getUserNotifications({
            queryParams: {
                isForwarded: false
            }
        })
        .then((res) => {
            if(res.data.notifications) {
                this.newNotificationList = res.data.notifications
            }
            this.newNotificationList.sort()
        })
    },

    getNotification(data) {
        var audio = new Audio(require('@/assets/sounds/juntos.mp3'));
        audio.play();
        this.$notify({
            group: 'notification',
            type: 'success',
            title: `${data.subject}`,
            text:  `${data.text}`
        });
        this.getUserNots()         
    },

    clearNotifs(idNotification) {
        markForwarded([idNotification])
        .then((res) => {
            if(res.status === 'success') {
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Teadaanne märgitud loetuks!',
                });
                this.getUserNots()
            }
        })
    },

    clearAllNotifs() {
        let unreadNotifs = this.newNotificationList.map((notif) => notif.idNotification)
        markForwarded(unreadNotifs)
        .then((res) => {
            if(res.status === 'success') {
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Kõik teadanded märgitud loetuks!',
                });
                this.getUserNots()
            }
            if(res.status == 'error') {
                this.$notify({
                    group: 'foo',
                    type: 'warn',
                    title: 'Teadanded on juba loetud!',
                });
            }
        })
    },

    listenPrivateMessages(data) {
        document.title = 'Uus sõnum(1)..'
        setTimeout(() => {
            document.title = 'Omatoodang.ee'
        }, 8000)
        if(data.chatData.idChat != this.$router.currentRoute.params.idChat) {
            var audio = new Audio(require('@/assets/sounds/pristine.mp3'));
            audio.play();
            this.getUserChats()
            this.$notify({
                group: 'notification',
                type: 'su',
                duration: '8000',
                title: 'Uus erasõnum',
                text: `"${data.message.message}"`
            });  
        } 
    },
    
    logOut() {
        logoutFetch()
        .then(() => {
            this.$router.go()
            this.$router.push('/')
            this.$emit('reload')
        })
    },

    isUserFromGroup(group) {
      if(this.userGroups.some(grupp => grupp === group)) {
        return true
      } else {
        return false
      }
    },

    roundtripRegulator: async function() {
      roundtrip(this.$options.name)
      .then((res) => {
        this.userGroups = res.userGroups
        this.idUser = res.idUser
        if (this.isUserFromGroup('seller')) {
            core.shopApi.getUserShops()
            .then((res) => {
                if(res.data) {
                    this.shopName = res.data.shops[0].shopName
                    this.idShop = res.data.shops[0].idShop
                    this.coverImg = res.data.shops[0].coverImg
                }
            })
        } 
        if (!this.isUserFromGroup('seller')) {
            userProfile(this.idUser)
            .then((res) => {
                if(res.data) {
                    this.shopName = res.data.profile.displayName
                    this.coverImg = res.data.profile.coverImg
                }
            })
        }
      })
      .catch((err) => {
        this.catchError = err
      }) 
    },

  },
    created: function () {
        this.roundtripRegulator()
        this.getUserChats()
        window.addEventListener('scroll', this.handleScroll)
        eventBus.$on('privateMessage', this.listenPrivateMessages)
        eventBus.$on('disputeMessage', this.listenPrivateMessages)
        eventBus.$on('notification', this.getNotification)
        this.getUserNots()
        setTimeout(() => {
            this.loadAnimationLogo = false
            this.loadAnimationMenu = false
        }, 700)
    },

    beforeDestroy() {
        eventBus.$off('privateMessage', this.listenPrivateMessages)
        eventBus.$off('disputeMessage', this.listenPrivateMessages)
        eventBus.$off('notification', this.getNotification)
        window.removeEventListener('scroll', this.handleScroll)
    },
}
</script>

<style scoped>
a {
    text-decoration: none;
}
.wrapper {
    position: relative;
    margin: 0 auto;
    width: 1420px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.wrapperSmall {
    width: 1100px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.menuContainer {
    background: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 64px;
    width: 100%;
    -webkit-box-shadow: 0px 3px 4px -3px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 4px -3px rgba(0,0,0,0.15);
    position: absolute;
    top: 0;
    z-index: 4999;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.menuContainer-noShadow {
    -webkit-box-shadow: none;
            box-shadow: none;
    border-bottom: 1px solid rgb(235,235,235);
}
.menuContainerHidden {
    background: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 64px;
    width: 100%;
    -webkit-box-shadow: 0px 3px 4px -3px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 4px -3px rgba(0,0,0,0.15);
    position: absolute;
    top: -100px;
    z-index: 4999;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.menuContainer-fixed {
    background: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 64px;
    width: 100%;
    -webkit-box-shadow: 0px 3px 4px -3px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 4px -3px rgba(0,0,0,0.15);
    top: 0;
    z-index: 4999;
    position: fixed;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.menuContainer-fixed-noShadow {
    -webkit-box-shadow: none;
            box-shadow: none;
    border-bottom: 1px solid rgb(235,235,235);
}
.topMenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: -19px;
    top: -7px;
    z-index: 130;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.menuContainer-fixed .topMenu {
    top: -7px;
}
.topMenuHidden {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: -800px;
    top: 0px;
    z-index: 130;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.notifications {
    margin: 25px 20px 0px 0px;
    opacity: 1;
}
.notifications img {
    width: 34px;
    height: 34px;
}
.menuContainer-fixed .notifications img {
    height: 34px;
    width: 34px;
}
.profile {
    border: 1px solid rgb(217, 222, 223);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
    border-radius: 50px;
    height: 36px;
    width: 36px;
    margin: 23px 20px 0px 0px;
    background: #c8d3d1b9;
    opacity: 1;
}
.profile:hover{
    opacity: 1;
}
.profile img {
    margin-top: 1px;
    margin-left: 0px;
    opacity: 0.7;
    width: 36px;
    height: 36px;
}
.menuContainer-fixed .profile {
    margin-top: 21px;
    width: 36px;
    height: 36px;
}
.menuContainer-fixed .profile img {
    height: 36px;
    width: 36px;
}
.profileActive {
    border: 1px solid rgb(201, 228, 228);
    border-radius: 50px;
    height: 36px;
    width: 36px;
    margin: 23px 20px 0px 0px;
    background: rgb(213, 235, 235);
    opacity: 0.8;
    cursor: pointer;
}
.profileActive:hover{
    opacity: 1;
}
.profileActive img {
    margin-top: 2px;
    margin-left: 0px;
    opacity: 0.8;
    width: 36px;
    height: 36px;
}
.menuContainer-fixed .profileActive {
    margin-top: 21px;
    width: 36px;
    height: 36px;
}
.menuContainer-fixed .profileActive img {
    height: 36px;
    width: 36px;
}
.notifications:hover {
    opacity: 1;
    cursor: pointer;
    -webkit-transition: .1s ease-out;
    -o-transition: .1s ease-out;
    transition: .1s ease-out
}
.notifBox {
    overflow: hidden;
    border-radius: 10px;
    width: 650px;
    height: 359px;
    position: absolute;
    top: 70px;
    right: 7%;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    background: white;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    z-index: 140;
}
.notifBoxGone {
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 2px;
    position: absolute;
    top: -550px;
    right: 4%;
    display: none;
    height: 350px;
    width: 500px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background: white;
    opacity: 0;
    z-index: 30;
}
.newUpdateNotifs {
    position: absolute;
    left: 72px;
    top: 15px;
    height: 23px;
    width: 23px;
    border: 1px solid rgba(73, 100, 98, 0);
    background: rgba(255, 82, 82, 1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    -webkit-animation: pulse-red 2s infinite;
            animation: pulse-red 2s infinite;
    border-radius: 50%;
    text-align: center;
    color: white;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}
.newUpdateNotifsSeller {
    left: 18px;
}
.newUpdateNotifs p {
    margin: 10;
    margin-top: -2px;
    font-weight: bold;
    font-size: 1.2rem;
}
.newUpdateMsgs {
    position: absolute;
    left: 134px;
    top: 15px;
    height: 23px;
    width: 23px;
    border: 1px solid rgba(73, 100, 98, 0);
    background: rgba(255, 82, 82, 1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    -webkit-animation: pulse-red 2s infinite;
            animation: pulse-red 2s infinite;
    border-radius: 20px;
    text-align: center;
    color: white;
}
.newUpdateMsgsSeller {
    left: 80px;
}
.newUpdateMsgs p {
    margin: 0;
    margin-top: -2px;
    font-weight: bold;
    font-size: 1.2rem;
}
.tabHeader {
    position: relative;
    border-bottom: 1px solid #62bd9d;
    background: #62bd9d;
    height: 40px;
}
.tabHeader p {
    margin: 0;
    padding-top: 6px;
    text-align: center;
    color: white;
    font-size: 22px;
    font-family: 'Lato', sans-serif;
}
.messages {
    margin: 25px 20px 0px 0px;
    opacity: 1
}
.messages img {
    width: 38px;
    height: 38px;
}
.menuContainer-fixed .messages img {
    height: 38px;
    width: 38px;
}
.messages:hover {
    opacity: 1;
    cursor: pointer;
    -webkit-transition: .1s ease-out;
    -o-transition: .1s ease-out;
    transition: .1s ease-out
}
.messageBox {
    border-radius: 10px;
    overflow: hidden;
    width: 650px;
    height: 359px;
    position: absolute;
    top: 70px;
    right: 7%;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    background: white;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    z-index: 130;
}
.messageBoxGone {
    border: 1px solid rgba(255, 255, 255, 0);
    position: absolute;
    top: -385px;
    right: 18%;
    display: none;
    height: 350px;
    width: 550px;
    background: white;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    opacity: 1;
    z-index: 30;
}
.allMessagesBox {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    height: 273px;
    margin-bottom: 0px;
}
.allMessagesBoxEmpty {
    height: 358px;
    text-align: center;
}
.viewAllBtn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    margin: auto;
    height: 35px;
    padding-top: 1px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #3d405600;
    border-radius: 3px;
    background: #3D4056;
    color: #EEEEEE;
    font-size: 1rem;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
}
.viewAllBtn:hover {
    background: #3D4056;
    color: #EEEEEE;
    opacity: .9;
}
.firstMessage {
    padding-bottom: 5px;
    padding-left: 15px;
    padding-top: 10px;
    background: white;
    border-bottom: 0.1px solid rgb(240, 240, 240);
}
.firstMessage:hover {
    background: rgb(247, 247, 247);
    cursor: pointer;
}
.firstMessage:hover .showMoreBtn {
    opacity: 1;
}
.messageContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.profileImg {
  border: 1px solid rgb(221, 236, 240);
  border-radius: 50px;
  height: 71px;
  width: 71px;
  margin: 10px 5px 0px 13px;
  background: rgb(231, 235, 242);
  opacity: 1;
}
.profileImg img {
  margin-top: 3px;
  margin-left: 3px;
  opacity: 1;
  cursor: pointer;
}
.sender {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    font-size: 0.9rem;
    width: 400px;
    color: rgb(112, 112, 112);
}
.username {
    width: 400px;
    color: rgb(95, 124, 124);
    font-weight: bold;
}
.from {
    color: rgb(120, 156, 156);
    width: 330px;
}
.date {
    width: 120px;
    margin-top: 8px;
    margin-left: 1px;
    font-size: .8rem;
    color: rgb(201, 201, 201);
}
.subject {
    color: rgb(3, 124, 114);
    font-family: 'DM sans', sans-serif;
    font-weight: 300;
    font-size: 1.05rem;
    padding-right: 10px;
    margin: 0;
}
.dateNotif {
    margin: 0;
    margin-top: 5px;
    font-size: .9rem;
    padding-right: 10px;
    color: rgb(190, 190, 190);
    text-align: end;
}
.body {
    font-size: .8rem;
    margin-top: -13px;
    height: 40px;
    width: 420px;
    overflow: hidden;
    word-break: inherit;
    overflow-wrap: break-word;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    color: rgb(61, 61, 61);
}
.body b {
    word-break: inherit;
    overflow-wrap: break-word;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}
.notificationBody {
    font-size: 1rem;
    color: rgb(75, 75, 75);
    margin: 0;
    margin-top: 5px;
    padding-right: 10px;
    word-break: inherit;
    font-weight: normal;
    overflow-wrap: break-word;
    font-family: 'DM sans', sans-serif;
}
.allMessages {
    position: relative;
    z-index: 100;
    text-align: center;
    color: rgb(120, 156, 156);
    background: white;
    margin-top: 0px;
    cursor: pointer;
    padding-top: 10px;
    border-top: 1px solid rgb(231, 231, 231);
}
.profileBoxMenu {
    border-radius: 7px;
    width: 360px;
    position: absolute;
    top: 63px;
    right: 0;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    background: white;
    z-index: 130;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
}
.profileBoxGone {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    border-radius: 7px;
    position: absolute;
    top: 90px;
    right: 13.5%;
    height: 423px;
    width: 360px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    display: none;
    z-index: 130;
}
.profileButton {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 10px;
    cursor: pointer;
}
.profileButton:hover {
    background: rgb(236, 236, 236);
    border-radius: 7px 7px 0px 0px;
}
.profileButton img {
    border-radius: 100px;
    margin-left: 18px;
    margin-top: 18px;
}
.profileButton p {
    font-size: 1.2rem;
    margin-top: 30px;
    margin-left: -30px;
    margin-bottom: -10px;
    color: rgb(30, 88, 93);
}
.profileButton a {
    margin-left: -30px;
    color: rgb(144, 144, 144);
}
.dashboard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    margin: 0 auto;
    border-top: 1px solid rgb(218, 218, 218);
    border-bottom: 1px solid rgb(218, 218, 218);
    padding-bottom: 10px;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.dashboard:hover {
    background: rgb(236, 236, 236);
    width: 100%;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    opacity: 1;
}
.dashboard p {
    font-size: 1.2rem;
    margin-left: 10px;
    margin-top: 18px;
    margin-bottom: -5px;
    color: rgb(79, 102, 102);
}
.dashboard a {
    font-size: .9rem;
    margin-left: 11px;
    color: rgb(155, 155, 155);
    font-family: 'DM sans', sans-serif;
}
.iconCircle {
    background: rgb(222, 231, 235);
    border-radius: 60px;
    height: 40px;
    width: 50px;
    padding-top: 10px;
    margin-top: 10px;
    margin-left: 14px;
    text-align: center;
}
.iconCircle img {
    opacity: 1;
}
.name {
    text-align: center;
    width: 100%;
    word-wrap: break-word;
}
.name p {
    word-wrap: break-word;
    width: 250px;
    margin: 20px auto;
    margin-bottom: 0px;
}
.name a {
    margin: -30px auto;
    font-family: 'DM sans', sans-serif;
}
.profileBoxBtn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: rgb(79, 102, 102);
    font-size: 1.2rem;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 5px;
}
.profileBoxBtn p {
    margin-left: 10px;
    margin-top: 23px;
}
.profileBoxBtn a {
    color: rgb(79, 102, 102);
}
.profileBoxBtn:hover {
    background: rgb(238, 238, 238);
    border-radius: 10px;
    cursor: pointer;
}
.profileBoxBtn img {
    opacity: 1;
}
.logoutBtn {
    color: rgb(136, 136, 136);
    border-top: 1px solid rgb(204, 204, 204);
    width: 80%;
    height: 50px;
    margin: 0 auto;
    text-align: center;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.logoutBtn:hover {
    background: rgb(238, 238, 238);
    width: 100%;
    border-radius: 0px 0px 7px 7px;
    cursor: pointer;
}
.logoutBtn img {
    margin-left: 5px;
    margin-top: 10px;
    margin-right: 15px;
    opacity: 0.6;
}
.profile:hover {
    opacity: 1;
    cursor: pointer;
    -webkit-transition: .1s ease-out;
    -o-transition: .1s ease-out;
    transition: .1s ease-out
}
.background {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .45;
}
.logo {
    z-index: 2000;
    position: absolute;
    left: 0;
    margin-top: 16px;
    z-index: 13;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.logoHidden {
    z-index: 2000;
    margin-left: -800px;
    position: absolute;
    margin-top: 25px;
    z-index: 13;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.logoHidden img {
    height: 35px;
}
.logo img {
    height: 35px;
}
.menuContainer-fixed .logo {
    margin-top: 15px;
}
.menuContainer-fixed .logo img {
    height: 35px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.menuContainerHidden-fixed .logo img {
    height: 35px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

@-webkit-keyframes pulse-red {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-red {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
@media (max-width: 1450px) {
    .logo {
        left: 1.5%;
    }
    .topMenu {
        right: .5%;
    }
}

</style>
