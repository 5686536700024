<template>
    <div class="mobileHeadNav" :class="{ 'mobileHeadNavRelative': isMenuRelative }" :style="[currentPage == 'communicationCentral' ? {height: '93px'}: {height: '50px'}]">
        <img 
            class="originalLogo"
            @click="$router.push({'name': 'home'}), routeChangeToHome"
            :src="require('@/assets/logos/oma.svg')" 
            alt=""
        >

        <div v-if="!isApplicationPageActive">
            <div class="orderPop" v-if="role == 'guest'">
                <orderPopupGuest
                    ref="orderComponent"
                    @orders='popup'
                    @deactivatePopup='deactivatePopup'
                    :isMobileActive="true"
                    :isOrderPopupActive='isOrderPopupActive'
                    :isGuest='true'
                    :isMenuFixed="false"
                    :topMargin="topMargin"
                    :containerPosition="containerPosition"
                />
            </div>
    
            <div class="orderPop" v-if="role != 'guest' && role != 'seller'">
                <orderPopup 
                    ref="orderComponent"
                    :isOrderPopupActive='isOrderPopupActive'
                    :isMenuFixed="false"
                    :isMobileActive="true"
                    :topMargin="topMargin"
                    :containerPosition="containerPosition"
                    @orders='popup'
                    @listingDeletedFromOrder='listingDeletedFromOrder'
                    @activateBackground='activateBackground'
                    @deactivatePopup='deactivateBackground'
                />
            </div>
            
            <div 
                class="messages"
                :class="{ 'messagesSeller': role == 'seller' }"
                v-if="role != 'guest'"
            >
                <img 
                    @click="$router.push('/kirjakast/0')"
                    :src="require('@/assets/icons/menus/messages.svg')"
                />
                <div 
                    class="newUpdateMsgs"
                    @click="$router.push('/kirjakast/0')"
                    v-if="this.unreadMessages.length > 0"
                ><p>{{this.unreadMessages.length}}</p></div>
            </div>
    
            <div class="notif">
                <notificationPopup
                    ref="notifComponent"
                    :isMobileActive='true'
                    :isNotifPopupActive='isNotifPopupActive'
                    :isIconActive='true'
                    :containerPosition='"fixed"'
                    :topMargin='"48px"'
                    :rightMargin='"-0%"'
                    :userGroups="userGroups"
                    @deactivateNotifPopup='isNotifPopupActive = false'
                    @openPopup="popup"
                />
            </div>
    
            <topMenuBurger 
                class="burgerMenu"
                @reload='$emit("reload")'
                @openSupportPopup="$emit('openSupportPopup')"
                @routeChangeToHome="routeChangeToHome"
                :role='role'
            />
            <div class="search" v-if="currentPage == 'communicationCentral'">
                <searchComponent :isGuest='true' :buttonsVisible='true'/>
            </div>
        </div>
    </div>
</template>

<script>
import core from '../../../api/index'
import eventBus from '../../../eventBus'
import sortedChatList from '@/chatBox/determineIsLastMessageRead'

import topMenuBurger from '@/views/menus/mobile/topMenuBurger.vue'
import searchComponent from '@/views/category/components/search/search.vue'
import orderPopupGuest from '@/views/menus/components/orderPopupGuest.vue'
import orderPopup from '@/views/menus/components/orderPopup.vue'
import notificationPopup from '@/views/menus/components/notificationPopup.vue'

export default {
    name: 'mobileHeadNav',
    components: {
        topMenuBurger,
        searchComponent,
        orderPopupGuest,
        orderPopup,
        notificationPopup
    },
    props: {
        currentPage: {
            required: false
        },
        role: {
            required: false
        },
        userGroups: {},
        idUser: {},
        isApplicationPageActive: {},
    },
    watch: {
        currentPage: function(newPage) {
            this.setPageBasedStyle(newPage)
        }
    },
    data() {
        return {
            isOrderPopupActive: false,
            isNotifPopupActive: false,
            isMenuRelative: false,
            unreadMessages: [],
            topMargin: '37px',
            containerPosition: 'absolute',

        }
    },  
    methods: {
        setPageBasedStyle(newPage) {
            if(newPage == 'editTransportProfile' || newPage == 'userShop' || newPage == 'shop') {
                this.isMenuRelative = true
            } else {
                this.isMenuRelative = false
            }
        },

        routeChangeToHome() {
            if(this.$route.path === '/') {
                this.$emit('homeNavigation')
            }
        },

        popup(type) {
            if(type == 'order') {
                this.isOrderPopupActive = !this.isOrderPopupActive
                this.topMargin = window.scrollY+50+'px'
            }
            if(type == 'notification') {
                this.isNotifPopupActive = !this.isNotifPopupActive
            }
            if(!this.isOrderPopupActive && !this.isNotifPopupActive) {
                this.$emit('deactivateBackground')
            } else {
                this.$emit('activateBackground')
            }
        },

        fetchOrders() {
            if(this.role == 'guest') {
                this.$refs.orderComponent.fetchOrders()
            }
            if(this.role != 'guest' && this.role != 'seller') {
                this.$refs.orderComponent.fetchOrders()
            }
        },

        deactivatePopup() {
            this.isOrderPopupActive = false
            this.$emit('deactivateBackground')
        },

        openOrder(idOrder, idListing) {
            this.$refs.orderComponent.openOrder(idOrder, idListing)
            this.$emit('activateBackground')
        },

        listingDeletedFromOrder() {
            this.$emit('listingDeletedFromOrder')
        },

        activateBackground() {
            this.$emit('activateBackground')
        },

        deactivateBackground() {
            this.isOrderPopupActive = false
            this.$emit('deactivateBackground')
        },

        getUserNots() {
            this.$refs.notifComponent.getUserNots()
        },

        getUserChats() {
            this.unreadMessages = []
            core.chatApi.getUserChats()
            .then((res) => {
                if(res.data.chats != null) {
                    let newMessages = res.data.chats
                    const conversationsReturnObj = sortedChatList(newMessages, this.idUser)
                    this.unreadMessages = conversationsReturnObj.notReadConversations
                }
            })
        }, 

        getNotification(data) {
            var audio = new Audio(require('@/assets/sounds/juntos.mp3'));
            audio.play();
            this.$notify({
                group: 'notification',
                type: 'success',
                title: `${data.subject}`,
                text:  `${data.text}`
            });
            this.getUserNots()         
        },

        listenPrivateMessages(data) {
            document.title = 'Uus sõnum(1)..'
            setTimeout(() => {
                document.title = 'Omatoodang.ee'
            }, 8000)
            if(data.chatData.idChat != this.$router.currentRoute.params.idChat) {
                var audio = new Audio(require('@/assets/sounds/pristine.mp3'));
                audio.play();
                this.getUserChats()
                this.$notify({
                    group: 'notificationMobile',
                    type: 'su',
                    duration: '8000',
                    title: 'Teil on uus erasõnum!',
                    text: `"${data.message.message}"`
                });  
            }
        },
    },
    created() {
        if(this.role != 'guest') {
            this.getUserChats()
            eventBus.$on('privateMessage', this.listenPrivateMessages)
            eventBus.$on('disputeMessage', this.listenPrivateMessages)
            eventBus.$on('notification', this.getNotification)
        }
        this.setPageBasedStyle(this.currentPage)
    },
    beforeDestroy() {
        eventBus.$off('privateMessage', this.listenPrivateMessages)
        eventBus.$off('disputeMessage', this.listenPrivateMessages)
        eventBus.$off('notification', this.getNotification)
    },
}
</script>

<style scoped>
.mobileHeadNav {
    position: fixed;
    width: 100%;
    top: 0vh;
    -webkit-transform: translateY(00%);
        -ms-transform: translateY(00%);
            transform: translateY(00%);
    background: rgb(245, 245, 245);
    z-index: 10000;
    border-bottom: 1px solid rgb(235,235,235);
    /* -webkit-box-shadow: 0px 4px 4px -2px rgba(0,0,0,.09);
    box-shadow: 0px 4px 4px -2px rgba(0,0,0,.09); */
}
.mobileHeadNavRelative {
    position: relative;
    margin-top: -50px;
}
.burgerMenu {
    position: absolute;
    top: 1px;
    right: 43px;
    -webkit-transform: translate(100%, 0);
        -ms-transform: translate(100%, 0);
            transform: translate(100%, 0);
    z-index: 15000;
}
.originalLogo {
    height: 26px;
    position: absolute;
    top: 12px;
    left: 10px;
    margin: auto;
    cursor: pointer;
}
.search {
    position: absolute;
    left: 10px;
    right: 0;
    margin: auto;
    margin-top: 50px;
    width: 93%;
}
.orderPop {
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: 0;
    margin-top: -14px;
    right: 0px;
}
.notif {
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: 0;
    margin-top: -45px;
    right: 0px;
}
.messages {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 137px;
    top: 11px;
    cursor: pointer;
}
.messages img {
    width: 32px;
    height: 32px;
}
.messagesSeller {
    right: 100px;
}
.newUpdateMsgs {
    position: absolute;
    top: -6px;
    right: -7px;
    height: 18px;
    width: 18px;
    border: 1px solid rgba(73, 100, 98, 0);
    background: rgba(255, 82, 82, 1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    -webkit-animation: pulse-red 2s infinite;
            animation: pulse-red 2s infinite;
    border-radius: 20px;
    text-align: center;
    color: white;
}
.newUpdateMsgs p {
    margin: 0;
    margin-top: -3px;
    font-weight: bold;
    font-size: 1.1rem;
}
@-webkit-keyframes pulse-red {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-red {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@media (max-width: 355px) {
    .originalLogo {
        left: 5px;
        height: auto;
        width: 45%;
    }
}
</style>