const trackedEvents = {
    visitCount: {
        isActive: false
    },
    uniqueTutorialViews: {
        isActive: true
    },
    roleBasedTutorialViews: {
        isActive: false
    },
    tutorialVideoViews: {
        isActive: true
    }
}



export default trackedEvents