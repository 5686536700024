export default function buildPostUserProducerApplications({
    apiEndpoint,
    apiTools
}) {
    return async function postUserProducerApplications(args) {
        const url = apiTools.resolveUrl({
            ...args,
            apiEndpoint: `${ apiEndpoint }`
        })
       
        const res = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            body: args.body
        });
        return await res.json();
    }
}