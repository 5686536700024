import apiTools from '../api-tools/index'
import buildGetUserBanEvent from './get-user-ban-event_fetch'

const apiEndpoint = 'user'


const getUserBanEvent = buildGetUserBanEvent({
    apiEndpoint,
    apiTools
})

const userApi = {
    getUserBanEvent
}

export default userApi