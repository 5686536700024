import 'dayjs/locale/et'
import dayjs from 'dayjs'

// Plugins
import isToday from 'dayjs/plugin/isToday'
import relativeTime from 'dayjs/plugin/relativeTime'

// Language
dayjs.locale('et')


function formatDate(rawDate, format) {
    const x = dayjs(rawDate).format(format)

    if(x == 'Invalid Date') {
        return '-'
    } else {
        return x
    }
}

function timeFromNow(date, suffix) {
    dayjs.extend(relativeTime)

    const x = dayjs(date).fromNow(suffix)

    if(!date) {
        return 'Puudub'
    } else {
        return x
    }
}

function isDateToday(date) {
    dayjs.extend(isToday)
    const x = dayjs(date).isToday()

    return x
}

const formatedDate = Object.freeze({
    formatDate,
    timeFromNow,
    isDateToday
})

export default formatedDate