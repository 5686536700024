import Dinero from 'dinero.js'
Dinero.defaultCurrency = 'EUR'
Dinero.defaultPrecision = 2

function makeDineroObj(amount) {
    if(isValidCurrencyNumber(amount) === true) {
        return Dinero({
            amount: Math.floor(Number(amount) * 100)
        })
    }
}

// allocate gives the undivided penny to the first ratio
function allocate(amount, allocations) {
    try {
        const x = makeDineroObj(amount)
        const res = x.allocate([ ...allocations ])
        let arr = []
        for(const obj of res) {
            arr.push(obj.toUnit())
        }
        return arr
    } catch (error) {
        console.log(error, 'error')
    }
}

function multiply(amounts) {
    let objs = []
    for(const amount of amounts) {
        objs.push(makeDineroObj(amount))
    }
    let first = objs[0]
    for(let i = 1; i < objs.length; i++) {
        first = first.multiply(objs[i].toUnit())
    }
    return first.toUnit()
}

function divide(amounts) {
    let objs = []
    for(const amount of amounts) {
        objs.push(makeDineroObj(amount))
    }
    let first = objs[0]
    for(let i = 1; i < objs.length; i++) {
        first = first.divide(objs[i].toUnit())
    }
    return first.toUnit()
}

function add(amounts) {
    let objs = []
    for(const amount of amounts) {
        objs.push(makeDineroObj(amount))
    }
    let first = objs[0]
    for(let i = 1; i < objs.length; i++) {
        first = first.add(objs[i])
    }
    return first.toUnit()
}

function subtract(amounts) {
    let objs = []
    for(const amount of amounts) {
        objs.push(makeDineroObj(amount))
    }
    let first = objs[0]
    for(let i = 1; i < objs.length; i++) {
        first = first.subtract(objs[i])
    }
    return first.toUnit()
}

function isValidCurrencyNumber(amount) {
    const currencyRegex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/
    return currencyRegex.test(amount)
}

function percentage(amount, percentage) {
    const x = makeDineroObj(amount)
    const res = x.percentage(percentage)
    return res.toUnit()
}

const dinero = Object.freeze({
    isValidCurrencyNumber,
    makeDineroObj,
    allocate,
    add,
    multiply,
    divide,
    subtract,
    percentage
})
export default dinero