import apiTools from '../api-tools/index'

import buildPostAdminChat from './post-shop-chat'


const apiEndpoint = 'communication'

const postAdminChat = buildPostAdminChat({
    apiEndpoint,
    apiTools
})


const orgCommunicationApi = {
    postAdminChat
}

export default orgCommunicationApi