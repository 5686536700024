import getUrl from './getUrl.js';

export default function userProfile(idUser) {
  return fetch(`${getUrl()}/v2/profile/${idUser}`, {
    method: 'GET',
    headers: {
      'Content-type':'application/json'
    },
    credentials: 'include',
  })
  .then((res) => res.json())
}