import apiTools from '../../api-tools/index'
import buildGetUserWallet from './get-user-wallet_fetch'
import buildGetWalletTransactions from './get-wallet-transactions_fetch'
import buildGetShopWallet from './get-shop-wallet_fetch'

const apiEndpoint = 'wallet'

const getUserWallet = buildGetUserWallet({
    apiEndpoint,
    apiTools
})
const getShopWallet = buildGetShopWallet({
    apiEndpoint,
    apiTools
})
const getWalletTransactions = buildGetWalletTransactions({
    apiEndpoint,
    apiTools
})


const walletApi = {
    getUserWallet,
    getShopWallet,
    getWalletTransactions
}

export default walletApi