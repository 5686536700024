export default function buildExportCsv({
    apiEndpoint,
    apiTools
}) {
    return async function exportCsv(args) {
        const url = apiTools.resolveUrl({
            ...args,
            apiEndpoint: `${ apiEndpoint }withdrawal-reqs` 
        })
       
        const res = await fetch(url, {
            method: 'GET',
            credentials: 'include',
        });
        
        return await res.blob();
        
    }
}