import getUrl from './getUrl.js';

export default function markForwarded(idNotifications) {
  return fetch(`${getUrl()}/v2/communication/mark-forwarded`, {
    method: 'POST',
    headers: {
      'Content-type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
        "item": 'notification',
        "idItem": idNotifications
    }),
  }).then(res => res.json())
}