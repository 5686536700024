const trackedEvents = {
    visitCount: {
        isActive: true
    },
    actionPopupOpened: {
        isActive: true
    },
    actionFeedbackGiven: {
        isActive: true
    },
}

export default trackedEvents