import getUrl from '../ApiFetches/getUrl'

export default function determineChatData({
    chat,
    dataBit,
    idUserActiveChatData,
    idUser
}) {

    function findClientChatData(chat, dataRequested) { // rewrite it more general findChatterChatDataByChatterRole
        const idClientChatData = chat.chatData.chatters.find(elem => elem.chatterRole == 'client')
        switch(dataRequested) {
          case 'dialogistName':
            return idClientChatData.chatterUsername
          case 'coverImg':
            return idClientChatData.chatterCoverImg
        }
    }
    // function findOrganizationChatData(chat, dataRequested) { 
    //     const idClientChatData = chat.chatData.chatters.find(elem => elem.chatterRole == 'organization' || elem.chatterRole == 'admin' || elem.chatterRole == 'mode')
    //     switch(dataRequested) {
    //       case 'dialogistName':
    //         return idClientChatData.chatterUsername
    //       case 'coverImg':
    //         return idClientChatData.chatterCoverImg
    //     }
    // }
    function findIdUserChatterRoleInAChat(chat) {
        const idUserActiveChatData = chat.chatData.chatters.find(elem => elem.idChatter == idUser)
        return idUserActiveChatData.chatterRole
    }

    if(chat) {
        const idUserChatterRole = findIdUserChatterRoleInAChat(chat)
        switch(idUserChatterRole) {
          case 'shopMessenger': {
            // chat.type = personal not possible from this point
            const dataBits = {
              dialogistName: findClientChatData(chat, dataBit),
              coverImg: (findClientChatData(chat, dataBit)) 
              ? `${getUrl()}` + '/static/images/profiles/' + findClientChatData(chat, dataBit) 
              : 'https://img.icons8.com/ios/60/000000/user-male-circle.png', // TODO: source from assets
              
              selfName: chat.shopName,
              selfCoverImg: (chat.coverImg) 
              ? `${getUrl()}` + '/static/images/shops/' + chat.coverImg 
              : 'https://img.icons8.com/ios/60/000000/user-male-circle.png' 
            }
            return dataBits[dataBit]
          }
          case 'client': {
              var dataBits
              if(chat.type == 'dispute') {
                  dataBits = {
                    dialogistName: chat.dialogistDisplayName,
                    coverImg: (chat.coverImg) ? 
                    `${getUrl()}` + '/static/images/profiles/' + chat.coverImg 
                    : 'https://img.icons8.com/ios/60/000000/user-male-circle.png',

                    selfName: idUserActiveChatData.chatterUsername,
                    selfCoverImg: (idUserActiveChatData.chatterCoverImg) 
                    ? `${getUrl()}` + '/static/images/profiles/' + idUserActiveChatData.chatterCoverImg
                    : 'https://img.icons8.com/ios/60/000000/user-male-circle.png'
                  }
              } else {
                  dataBits = {
                    dialogistName: chat.shopName,
                    coverImg: (chat.coverImg) ? 
                    `${getUrl()}` + '/static/images/shops/' + chat.coverImg 
                    : 'https://img.icons8.com/ios/60/000000/user-male-circle.png',

                    selfName: idUserActiveChatData.chatterUsername,
                    selfCoverImg: (idUserActiveChatData.chatterCoverImg) 
                    ? `${getUrl()}` + '/static/images/profiles/' + idUserActiveChatData.chatterCoverImg
                    : 'https://img.icons8.com/ios/60/000000/user-male-circle.png'
                  }
              }
            return dataBits[dataBit]
          }
          case 'admin':
          case 'mode':
          case 'organization': {
            const chatDataOfNotIdUser = chat.chatData.chatters.find(elem => elem.idChatter !== idUser)

            const dataBits = {
              dialogistName: chatDataOfNotIdUser.chatterUsername,
              coverImg: (chatDataOfNotIdUser.chatterCoverImg) ? 
                getUrl() + '/static/images/profiles/' + chatDataOfNotIdUser.chatterCoverImg 
                : 'https://img.icons8.com/ios/60/000000/user-male-circle.png',

              selfName: idUserActiveChatData.chatterUsername,
              selfCoverImg: (idUserActiveChatData.chatterCoverImg) 
              ? `${getUrl()}` + '/static/images/profiles/' + idUserActiveChatData.chatterCoverImg
              : 'https://img.icons8.com/ios/60/000000/user-male-circle.png'
            }
            return dataBits[dataBit]
          }
          default:
          case 'user': { // supposes the addition of chat type 'group'
            // if(chat.type == 'personal') {
              const chatDataOfNotIdUser = chat.chatData.chatters.find(elem => elem.idChatter !== idUser)
              const dataBits = {
                dialogistName: chatDataOfNotIdUser.chatterUsername,
                coverImg: (chatDataOfNotIdUser.chatterCoverImg) ? 
                getUrl() + '/static/images/profiles/' + chatDataOfNotIdUser.chatterCoverImg 
                : 'https://img.icons8.com/ios/60/000000/user-male-circle.png',

                selfName: idUserActiveChatData.chatterUsername,
                selfCoverImg: (idUserActiveChatData.chatterCoverImg) 
                ? getUrl() + '/static/images/profiles/' + idUserActiveChatData.chatterCoverImg
                : 'https://img.icons8.com/ios/60/000000/user-male-circle.png'
              }
              return dataBits[dataBit] 
            // }
          }
        }
      }
}