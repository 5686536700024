import getApiData from './get-api-data'

const version = getApiData('version')
const mainURL = getApiData('mainURL')

function resolveUrl({
    pathParam,
    queryParams,
    apiEndpoint
}) {
    const pathParameter = (pathParam) ? `/${ pathParam }`: ``

    let params = queryParams
    
    let arr = []

    if(queryParams) {
        for(const [key, value] of Object.entries(queryParams)) {
            if(Array.isArray(value)) {
                delete params[key]
                value.forEach(val => {
                    const x = encodeUri({
                        [key + '[]']: val
                    })
                    arr.push(x)
                })
            }
        }
    }
    const encodedQueryString = encodeUri(queryParams)
    let arrQueryString = addArrItemsToQueryString(arr)
    arrQueryString = (encodedQueryString.length) ? '&' + arrQueryString : '?' + arrQueryString

    function addArrItemsToQueryString(arr) {
        if(arr.length) {
            let str = arr.join('&')
            return str
        } else { return '' }
    }

    const queryString = (encodedQueryString.length) ? `?${ encodedQueryString }`: ``

    return `${ mainURL }/${ version }/${ apiEndpoint }${ pathParameter }${ queryString }${ arrQueryString }`
}
function encodeUri(obj) {
    var str = [];
    for (var p in obj)
    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    return str.join("&");
}

const apiTools = {
    encodeUri,
    resolveUrl
}
export default apiTools