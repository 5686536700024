import Vue from 'vue'
import VueRouter from 'vue-router'

// const home = () => import('../views/home/Home.vue')
const Register = () => import('../views/loginAndRegister/register.vue')
const Login = () => import('../views/loginAndRegister/login.vue')
const communicationCentral = () => import('../views/orbit/viewPanels.vue')
const emailConfirmation = () => import('../views/loginAndRegister/emailConfirmtion/confirmationPage.vue')

//MESSAGES
const singleConversations = () => import('../views/messages/singleConversation.vue')

//LISTINGS
const category = () => import('../views/category/categoryPage.vue')
const searchListings = () => import('../views/category/searchListings.vue')
const singleListing = () => import('../views/listing/userListing.vue')
const createListing = () => import('../views/dashboard/shopProducts/makeListings/makeListing.vue')
const editListing = () => import('../views/dashboard/shopProducts/editListing/editListing.vue')

//TRANSPORT PROFILE
const editTransportProfile = () => import('../views/dashboard/transportProfile/editProfile.vue')

//PROFILE && SHOP
const userShop = () => import('../views/shop/shop.vue')
const editShop = () => import('../views/dashboard/shopData/editShop.vue')
const browseShops = () => import('../views/browseShops/browseShops.vue')

//DASHBOARD
const dashboard = () => import('../views/dashboard/viewPanels/viewPanels.vue')
const userOrders = () => import('../views/dashboard/userOrders/dashOrders.vue')
const nonFormalizedOrders = () => import('../views/dashboard/userOrders/nonFormalizedOrders.vue')
const shopDeals = () => import('../views/dashboard/shopDeals/dashDeals.vue')
const shopProducts = () => import('../views/dashboard/shopProducts/dashProducts.vue')
const transactionsOverview = () => import('../views/dashboard/dashWallet.vue')
const shopStats = () => import('../views/dashboard/shopStats/statsPage.vue')
const shopMarketing = () => import('../views/dashboard/shopMarketing/shopMarketing.vue')
const userStats = () => import('../views/dashboard/userStats/statsPage.vue')
const disputes = () => import('../views/dashboard/dispute/disputes.vue')
const notifications = () => import('../views/dashboard/notifications/notificationPage.vue')

//ORDERING
const formalizeOrder = () => import('../views/dashboard/ordering/formalizeOrder.vue')
const orderPayment = () => import('../views/dashboard/ordering/orderPayment.vue')
const finalOrderPage = () => import('../views/dashboard/ordering/finalOrderPage.vue')

//SETTINGS
const allSettings = () => import('../views/dashboard/settings/allSettings.vue')

//BECOME SELLER
const fullSellerForm = () => import('../views/applications/fullSellerForm.vue')
const sellerContactForm = () => import('../views/applications/sellerContactForm.vue')

//LEGAL ENTITY
const legalEntity = () => import('../views/legalEntity/legalMain.vue')

//404 NOT FOUND
const notFound = () => import('../views/404/pageNotFound.vue')
const resourceNotFound = () => import('../views/404/notFound.vue')
const underConstruction = () => import('../views/404/underConstruction.vue')
const bannedPage = () => import('../views/404/bannedPage.vue')

//ADMIN
const adminViewPanel = () => import('../views/adminControlPanel/adminViewPanel.vue')
const controlPanel = () => import('../views/adminControlPanel/controlPanel.vue')
const sellerApplications = () => import('../views/adminControlPanel/sellerApplications.vue')
const producerApplications = () => import('../views/adminControlPanel/producerApplications.vue')
const adminDisputes = () => import('../views/adminControlPanel/adminDisputes.vue')
const adminSupport = () => import('../views/adminControlPanel/adminSupport.vue')
const adminWithdrawalReq = () => import('../views/adminControlPanel/adminWithdrawalReq.vue')
const adminNotifications = () => import('../views/adminControlPanel/notifications.vue')
const adminPasswords = () => import('../views/adminControlPanel/passwords.vue')
const dataTests = () => import('../views/adminControlPanel/dataTests.vue')
const adminTagRequests = () => import('../views/adminControlPanel/adminTagRequests.vue')
const adminCategories = () => import('../views/adminControlPanel/categories/adminCategories.vue')
const producerTagsAdmin = () => import('../views/adminControlPanel/producerTags.vue')
const adminTesting = () => import('../views/adminControlPanel/adminTesting.vue')
const adminTestingReg = () => import('../views/adminControlPanel/adminTestingReg.vue')
const adminTestUser = () => import('../views/adminControlPanel/adminTestUser.vue')
const testingStats = () => import('../views/adminControlPanel/testingStats.vue')
const adminLogin = () => import('../views/adminControlPanel/adminLogin.vue')

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: category,
        meta: { customTrackerName: 'home', tracker: true }
    },

    {
        path: '/registreeru',
        name: 'Register',
        component: Register,
        meta: { customTrackerName: 'register', tracker: true }
    },

    {
        path: '/sisselogimine',
        name: 'Login',
        component: Login,
        meta: { customTrackerName: 'login', tracker: true }
    },

    {
        path: '/orbiit',
        name: 'communicationCentral',
        component: communicationCentral,
        meta: { customTrackerName: 'communicationCentral', tracker: true }
    },

    {
        path: '/kinnitus/e-kiri/:conf',
        name: 'emailConfirmation',
        component: emailConfirmation,
        meta: { customTrackerName: 'emailConfirmation', tracker: true }
    },

    //MESSAGES
    {
        path: '/kirjakast/:idChat',
        name: 'singleConversations',
        component: singleConversations,
        meta: { customTrackerName: 'singleConversation', tracker: true }
    },

    //LISTINGS
    {
        path: '/kategooria/:idListingTagParent/:idListingTag',
        name: 'category',
        component: category,
        meta: {tracker: true }
    },

    {
        path: '/otsing',
        name: 'searchListings',
        component: searchListings,
        props: true,
        meta: { customTrackerName: 'searchListings', tracker: true }
    },

    {
        path: '/kuulutus/:id',
        name: 'singleListing',
        component: singleListing,
        meta: { customTrackerName: 'singleListing', tracker: true }
    },

    {
        path: '/kaupleja/kuulutus',
        name: 'createListing',
        component: createListing,
        meta: { customTrackerName: 'createListing', tracker: true }
    },

    {
        path: '/muuda/kuulutus/:idListing',
        name: 'editListing',
        component: editListing,
        meta: { customTrackerName: 'editListing', tracker: true }
    },

    //PROFILE && SHOP
    {
        path: '/pood/:id',
        name: 'userShop',
        component: userShop,
        props: true,
        meta: { customTrackerName: 'userShop', tracker: true}
    },

    {
        path: '/muuda/pood',
        name: 'editShop',
        component: editShop,
        meta: { customTrackerName: 'editShop', tracker: true }
    },

    {
        path: '/nimistu/poed',
        name: 'browseShops',
        component: browseShops,
        meta: { customTrackerName: 'browseShops', tracker: true, props: true }
    },

    {
        path: '/tarneprofiil/muutmine',
        name: 'editTransportProfile',
        component: editTransportProfile,
        meta: { customTrackerName: 'editTransportProfile', tracker: true }
    },

    {
        path: '/turundus',
        name: 'shopMarketing',
        component: shopMarketing,
        meta: { customTrackerName: 'shopMarketing', tracker: true }
    },

    {
        path: '/toolaud/:activeGroup',
        name: 'dashboard',
        component: dashboard,
        meta: { customTrackerName: `dashboard`, tracker: true }
    },

    {
        path: '/tellimused/:status',
        name: 'userOrders',
        component: userOrders,
        meta: { customTrackerName: 'userOrders', tracker: true }
    },

    {
        path: '/vormistamata-tellimused',
        name: 'nonFormalizedOrders',
        component: nonFormalizedOrders,
        meta: { customTrackerName: 'nonFormalizedOrders', tracker: true }
    },

    {
        path: '/tehingud/:status',
        name: 'shopDeals',
        component: shopDeals,
        meta: { customTrackerName: 'shopDeals', tracker: true }
    },

    {
        path: '/tooted/:status',
        name: 'shopProducts',
        component: shopProducts,
        meta: { customTrackerName: 'shopProducts', tracker: true }
    },

    {
        path: '/kannete-ulevaade',
        name: 'transactionsOverview',
        component: transactionsOverview,
        meta: { customTrackerName: 'transactionsOverview', tracker: true }
    },

    {
        path: '/statistika/:pagename',
        name: 'shopStats',
        component: shopStats,
        meta: { customTrackerName: 'shopStats', tracker: true }
    },

    {
        path: '/kasutaja/arvustused',
        name: 'userStats',
        component: userStats,
        meta: { customTrackerName: 'userStats', tracker: false }
    },

    {
        path: '/vaidlus/:idDeal',
        name: 'disputes',
        component: disputes,
        meta: { customTrackerName: 'disputes', tracker: true }
    },

    {
        path: '/kasutaja/teavitused',
        name: 'notifications',
        component: notifications,
        meta: { customTrackerName: 'notifications', tracker: true }
    },
  
  //ORDERING
    {
        path: '/tellimus/vormistamine/:idOrder/:idShop',
        name: 'formalizeOrder',
        component: formalizeOrder,
        meta: { customTrackerName: 'formalizeOrder', tracker: true }
    },

    {
        path: '/tellimus/maksmine/:idOrder/:idDeal',
        name: 'orderPayment',
        component: orderPayment,
        meta: { customTrackerName: 'orderPayment', tracker: false }
    },
    
    {
        path: '/tellimus/:idOrder/:idDeal',
        name: 'finalOrderPage',
        component: finalOrderPage,
        meta: { customTrackerName: 'finalOrderPage', tracker: true }
    },
    
    //SETTINGS
    {
        path: '/seaded/:page',
        name: 'allSettings',
        component: allSettings,
        meta: { customTrackerName: 'allSettings', tracker: true }
    },
    
    //BECOME SELLER
    {
        path: '/kaupleja/avaldus',
        name: 'becomeSeller',
        component: fullSellerForm,
        meta: { customTrackerName: 'becomeSeller', tracker: true }
    },

    {
        path: '/poe-loomine',
        name: 'sellerContactForm',
        component: sellerContactForm,
        meta: { customTrackerName: 'sellerContactForm', tracker: true }
    },

    {
        path: '/tingimused/:currentPage',
        name: 'legalEntity',
        component: legalEntity,
        meta: { customTrackerName: 'legalEntity', tracker: true }
    },

    //404 PAGE NOT FOUND
    {
        path: '/puudub/404',
        name: '404',
        component: notFound,
        meta: { customTrackerName: 'page404', tracker: true }
    },

    {
        path: '/puudub/404',
        name: 'resource404',
        props: true,
        component: resourceNotFound,
        meta: { customTrackerName: 'resource404', tracker: true }
    },

    {
        path: '/puudub/lisamisel',
        name: 'underConstruction',
        component: underConstruction,
        meta: { customTrackerName: 'underConstruction', tracker: false }
    },

    {
        path: '/banned',
        name: 'bannedPage',
        component: bannedPage,
        meta: { customTrackerName: 'bannedPage', tracker: false }
    },

    //ADMIN
    {
        path: '/admin/toolaud',
        name: 'adminViewPanel',
        component: adminViewPanel,
        meta: { customTrackerName: 'adminViewPanel', tracker: true }
    },

    {
        path: '/admin/kontrollpaneel',
        name: 'controlPanel',
        component: controlPanel,
        meta: { customTrackerName: 'adminControlPanel', tracker: false }
    },

    {
        path: '/admin/avaldused',
        name: 'sellerApplications',
        component: sellerApplications,
        meta: { customTrackerName: 'adminSellerApplications', tracker: false }
    },

    {
        path: '/admin/tootja-avaldus',
        name: 'producerApplications',
        component: producerApplications,
        meta: { customTrackerName: 'adminProducerApplications', tracker: false }
    },

    {
        path: '/admin/vaidlused',
        name: 'adminDisputes',
        component: adminDisputes,
        meta: { customTrackerName: 'adminDisputes', tracker: false }
    },

    {
        path: '/admin/kasutajatugi',
        name: 'adminSupport',
        component: adminSupport,
        meta: { customTrackerName: 'adminSupport', tracker: false }
    },

    {
        path: '/admin/valjakanded',
        name: 'adminWithdrawalReq',
        component: adminWithdrawalReq,
        meta: { customTrackerName: 'adminWithdrawalReq', tracker: false }
    },

    {
        path: '/admin/teavitused',
        name: 'adminNotifications',
        component: adminNotifications,
        meta: { customTrackerName: 'adminNotifications', tracker: false }
    },

    {
        path: '/admin/paroolid',
        name: 'adminPasswords',
        component: adminPasswords,
        meta: { customTrackerName: 'adminPasswords', tracker: false }
    },

    {
        path: '/admin/andmed/testid',
        name: 'dataTests',
        component: dataTests,
        meta: { customTrackerName: 'adminDataTests', tracker: false }
    },

    {
        path: '/admin/kategooriad/paringud',
        name: 'adminTagRequests',
        component: adminTagRequests,
        meta: { customTrackerName: 'adminTagRequests', tracker: false }
    },

    {
        path: '/admin/kategooriad',
        name: 'adminCategories',
        component: adminCategories,
        meta: { customTrackerName: 'adminCategories', tracker: false }
    },

    {
        path: '/admin/marksonad',
        name: 'producerTagsAdmin',
        component: producerTagsAdmin,
        meta: { customTrackerName: 'producerTagsAdmin', tracker: false }
    },

    {
        path: '/admin/testimine/koodid',
        name: 'adminTesting',
        component: adminTesting,
        meta: { customTrackerName: 'adminTesting', tracker: false }
    },

    {
        path: '/admin/testimine/registreerimine',
        name: 'adminTestReg',
        component: adminTestingReg
    },

    {
        path: '/admin/testimine/kasutaja',
        name: 'adminTestUser',
        component: adminTestUser,
        meta: { customTrackerName: 'adminTestUser', tracker: false }
    },

    {
        path: '/admin/testimine/kasutaja/statistika',
        name: 'testingStats',
        component: testingStats,
        meta: { customTrackerName: 'testingStats', tracker: false }
    },

    {
        path: '/admin/login',
        name: 'adminLogin',
        component: adminLogin,
        meta: { customTrackerName: 'adminLogin', tracker: false }
    },

    {
        path: '/:shopPageUrl',
        name: 'shop',
        component: userShop,
        meta: { customTrackerName: 'userShop(URL)', tracker: true }
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.afterEach(() => {
    window.scrollTo(0, 0)
})

export { routes }
export default router
