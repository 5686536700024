export default function determineIsLastMessageRead(chats, idUser) {

    var readConversations = []
    var notReadConversations = []

    for(const chat of chats) {
        // TEMPORARY CHAT FIX -- NEEDS ATTENTION
        if(chat.chatData.chatters.find(elem => elem.idChatter == idUser) != undefined) {
            const userChatData = chat.chatData.chatters.find(elem => elem.idChatter == idUser)

            if(chat.lastMessage.idMessage > userChatData.idLastReadMessage) {
                notReadConversations.push(chat)
            } else {
                readConversations.push(chat)
            }
        }
    }
    return {
        notReadConversations,
        readConversations
    }
}