import listingTagApi from './listing-tag/index.js'
import producerTagApi from './producer-tag/index.js'
import filterApi from './filter/index.js'

const tagApi = {
    listingTagApi,
    producerTagApi,
    filterApi
}

export default tagApi