import tutorialCenter from './trackingTests/tutorialCenter'
import orderProcess from './trackingTests/orderProcess'
import merchantList from './trackingTests/merchantList'
import userActionsPage from './trackingTests/userActionsPage'
import faqPage from './trackingTests/faqPage'

const trackingCategories = {
    tutorialCenter: {
        isActive: true,
        tutorialCenter
    },
    orderProcess: {
        isActive: true,
        orderProcess
    },
    merchantList: {
        isActive: true,
        merchantList
    },
    userActionsPage: {
        isActive: false,
        userActionsPage
    },
    faqPage: {
        isActive: true,
        faqPage
    }
}

export default trackingCategories