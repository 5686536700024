<template>
    <div class="componentWrapper">
        <div class="orderPopupIcon" :class="{ 'orderPopupIconFixed': isMenuFixed, 'orderPopupIconMobile': isMobileActive }" @click="orders" v-if="isIconActive">
            <img 
                v-if="!isOrderPopupActive"
                :src="require('@/assets/icons/menus/orderIcon.svg')"
            />
            <img 
                v-if="isOrderPopupActive"
                :src="require('@/assets/icons/menus/orderIcoActive.svg')"
            />
            <div 
                class="ordersAmountIcon" 
                v-if="userOrders.length > 0"
            >   <p>{{userOrders.length}}</p></div>
        </div>
        
        <div class="popupContainer" :style="{ 'position': containerPosition, 'top': topMargin, 'right': rightMargin }" v-if="isOrderPopupActive" v-on-clickaway="clickAway">
            <div style="text-align: center">
                <img 
                    v-if="userOrders === ''"
                    height="100px" width="100px" 
                    style="margin-top: 45px; margin-bottom: -95px"
                    :src="require('@/assets/icons/menus/orderSilver.svg')"
                />
                <h2 
                    class="noOrderNotification"
                    v-if="userOrders === '' && !isGuest" 
                >   Vormistamist Vajavad Tellimused Puuduvad</h2>
                <button 
                    class="viewAllBtn"
                    @click="openOrderPage"
                    v-if="userOrders === '' && !isGuest" 
                >   Vaata kõiki tellimusi</button>
            </div>

            <div class="content" v-if="userOrders">
                <img 
                    class="shopImage"
                    :src="fetchIp+'/static/images/shops/'+activeOrder.shopCoverImg"
                    alt=""
                >
                <div class="header">
                    <img 
                        class="previousArrow"
                        @click="changeActiveOrder('previous')"
                        v-if="userOrders.length > 1"
                        :src="require('@/assets/icons/menus/down-arrow(3).svg')" 
                        alt=""
                    >
                    <div class="nameWrapper">
                        <p class="shopName">{{activeOrder.shopName}}</p>
                        <span v-if="userOrders.length > 1">({{activeOrderIndex + 1}}/{{userOrders.length}})</span>
                    </div>
                    <img 
                        class="nextArrow"
                        @click="changeActiveOrder('next')"
                        v-if="userOrders.length > 1"
                        :src="require('@/assets/icons/menus/down-arrow(3).svg')" 
                        alt=""
                    >
                </div>

                <div class="contentHeader">
                    <p v-if="orderListings.length == 1">{{orderListings.length}} toode</p>
                    <p v-else>{{orderListings.length}} toodet</p>
                    <p>{{round(orderTotalAmount, 2)}}€</p>
                </div>

                <div class="productTable">
                    <div class="tableIndex">
                        <p>Eseme nimi</p>
                        <p id="priceIndex">Hind</p>
                        <p>Kogus</p>
                        <p>Summa</p>
                        <p></p>
                    </div>

                    <div class="tableContent">
                        <div 
                            class="loader"
                            v-if="orderLoaderAnim" 
                        ></div>
                        <div 
                            class="item"
                            v-else
                            v-for="(element, index) of orderListings"
                            :key="element.id"
                        >
                            <p class="title" @click="orders(); $router.push('/kuulutus/'+element.idListing)">{{element.title}}</p>
                            <p id="priceIndex">{{element.price}}€</p>
                            <p style="disply: flex">
                                <img 
                                    class="changeQtyBtn"
                                    :class="{ 'changeQtyBtnHidden': !isMobileActive && index != 0 }"
                                    @click="changeQty(element.idListing, element.qty, 'subtract')"
                                    :src="require('@/assets/icons/menus/remove.svg')" 
                                    alt=""
                                >
                                    {{element.qty}}
                                <img 
                                    class="changeQtyBtn"
                                    :class="{ 'changeQtyBtnHidden': !isMobileActive && index != 0 }"
                                    @click="changeQty(element.idListing, element.qty, 'add')"
                                    :src="require('@/assets/icons/menus/plus(2).svg')" 
                                    alt=""
                                >
                            </p>
                            <p>{{round(element.qty * element.price, 2)}}€</p>
                            <p 
                                :class="{ 'changeQtyBtnHidden': !isMobileActive && index != 0 }"
                                id="removeItemBtn"
                                @click="removeItem(element.idListing)"
                            >   
                                <img 
                                    class="closeBtn"
                                    height="12px"
                                    width="12px"
                                    :src="require('@/assets/icons/categories/close(2).svg')" 
                                    alt=""
                                >
                            </p>
                        </div>
                    </div>
                </div>

                <div class="buttons">
                    <button @click="deleteOrder(activeOrder.idOrder)">KUSTUTA</button>
                    <button @click="$router.push('/tellimus/vormistamine/'+activeOrder.idOrder+'/'+activeOrder.idShop); $emit('orders', 'order'); tracker()">VORMISTA</button>
                </div>

                <div class="footer">
                    <p 
                        class="allOrders" 
                        @click="$emit('orders', 'order'); $router.push('/vormistamata-tellimused')"
                    >   Vaata Kõiki Tellimusi</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import core from '../../../api/index'
import { mixin as clickaway } from 'vue-clickaway'

export default {
    name: 'orderPopup',
    mixins: [ clickaway ],
    props: {
        isMobileActive: {
            default: false
        },

        containerPosition: {
            default: 'absolute'
        },

        topMargin: {
            default: '70px'
        },

        rightMargin: {
            default: '7%'
        },

        isIconActive: {
            default: true
        },

        isOrderPopupActive: {
            required: true
        },

        isGuest: {
            default: false
        },

        isMenuFixed: {}
    },
    data() {
        return {
            userOrders: '',
            activeOrder: '',
            activeOrderIndex: 0,

            orderLoaderAnim: true,
            orderListings: '',
            orderTotalAmount: '',
        }
    },

    methods: {
        clickAway() {
            this.$emit('orders', 'order')
        },

        openOrder(idOrder, idListing) {
            this.idListingActive = idListing
            for(const element of this.userOrders) {
                if(element.idOrder == idOrder) {
                    this.activeOrder = element
                }
            }
            setTimeout(() =>{
                this.$emit('orders', 'order')
                if(this.activeOrder) {
                    this.fetchOrderContent(this.activeOrder.idOrder)
                }
            }, 100)
        },

        tracker() {
            this.$track.customEvent('Alustatud vormistamist(kiirvaateaknast)', 'orderProcess', 'formalizePageVisits')
        },

        openOrderPage() {
            this.$emit('orders', 'order') 
            this.$router.push({'name': 'userOrders', params: {'status': 'makstud'}})
        },

        orders() {
            this.$emit('orders', 'order')
            if(this.activeOrder) {
                this.fetchOrderContent(this.activeOrder.idOrder)
            }
        },

        round(num, decimalPlaces = 0) {
            num = Math.round(num + "e" + decimalPlaces);
            return Number(num + "e" + -decimalPlaces);
        },

        changeQty(idListing, currentQty, method) {
            this.orderLoaderAnim = true
            var newQty = currentQty
            if(method == 'subtract') {
                newQty = currentQty - 1
            }
            if(method == 'add') {
                newQty = currentQty + 1
            }
            var sendBody = []
            for(const element of this.orderListings) {
                if(element.idListing == idListing) {
                    sendBody.push({
                        idListing: idListing,
                        qty: newQty
                    })
                } else {
                    sendBody.push({
                        idListing: element.idListing,
                        qty: element.qty
                    })
                }
            }
            core.orderApi.patchItemQuantities({
                pathParam: this.activeOrder.idOrder,
                body: sendBody
            })
            .then((res) => {
                if(res.status == 'success') {
                    this.fetchOrderContent(this.activeOrder.idOrder)
                }
                if (res.message === "Quantity must be more than zero.") {
                    this.orderLoaderAnim = false
                    this.$notify({
                        group: 'foo',
                        type: 'warn',
                        title: 'Toote kogus ei saa olla väiksem kui üks!',
                    });
                }
                
            })
        },

        changeActiveOrder(direction) {
            const length = this.userOrders.length - 1
            switch(direction) {
                case 'previous': {
                    if(this.activeOrderIndex == 0) {
                        this.activeOrder = this.userOrders[length]
                        this.activeOrderIndex = length
                    } else {
                        this.activeOrderIndex --
                        this.activeOrder = this.userOrders[this.activeOrderIndex]
                    }
                    this.fetchOrderContent(this.activeOrder.idOrder)
                    break
                }
                case 'next': {
                    if(this.activeOrderIndex == length) {
                        this.activeOrder = this.userOrders[0]
                        this.activeOrderIndex = 0
                    } else {
                        this.activeOrderIndex ++
                        this.activeOrder = this.userOrders[this.activeOrderIndex]
                    }
                    this.fetchOrderContent(this.activeOrder.idOrder)
                    break
                }
            }
        },

        fetchOrders() {
            fetch(this.fetchIp+'/v2/order/user?isFormalized=false&limiter=10', {
                method: 'GET',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((res) => {
                if(res.data) {
                    if(res.data.orders != null) {
                        this.$emit('ordersCount', res.data.orders.length)
                        this.userOrders = res.data.orders
                        this.activeOrder = res.data.orders[0]
                        this.fetchOrderContent(this.userOrders[0].idOrder)
    
                        const index = this.activeOrderIndex + 1
                        if(this.userOrders.length < index) {
                            this.activeOrderIndex = this.userOrders.length - 1
                        }
                    } else {
                        this.userOrders = ''
                        this.$emit('ordersCount', 0)
                    }
                    if (!res.data) {
                        this.userOrders = ''
                        this.$emit('ordersCount', 0)
                    }
                }
            })
        },

        fetchOrderContent(idOrder) {
            this.orderLoaderAnim = true
            fetch(this.fetchIp+'/v2/order/'+idOrder, {
                method: 'GET',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((res) => {
                if (res.data != null) {
                    this.orderLoaderAnim = false
                    this.orderTotalAmount = res.data.order.totalAmount
                    this.orderListings = res.data.order.listings
                } else {
                    this.fetchOrders()
                }
            })
        },

        removeItem(idListing) {
            this.orderLoaderAnim = true
            core.orderApi.removeItemFromOrder({
                pathParam: this.activeOrder.idOrder,
                body: {
                    idListing: idListing
                }
            })
            .then((res) => {
                if(res.status === 'success') {
                    if(idListing == this.idListingActive) {
                        this.$emit('listingDeletedFromOrder')
                    }
                    this.errors = ''
                    this.$notify({
                        group: 'foo',
                        type: 'warn',
                        title: 'Toode eemaldatud!',
                    });
                    this.fetchOrderContent(this.activeOrder.idOrder) 
                }
                if(this.orderListings.length == 1 && res.status == 'success') {
                    this.$track.customEvent('Tellimus kustutatud', 'orderProcess', 'orderDeletedCount')
                }
            })
        },

        deleteOrder(idOrder) {
            this.$emit('orders', 'order')
            this.$fire({
                title: `Olete kindel, et soovite ${this.activeOrder.shopName} poe tellimuse kustutada?`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Kustuta',
                cancelButtonText: 'Tühista'
            }).then((res) => {
                if(res.value) {
                    fetch(this.fetchIp+'/v2/order/'+idOrder, {
                        method: 'DELETE',
                        headers: {
                            'Content-type':'application/json'
                        },
                        credentials: 'include',
                    })
                    .then((res) => res.json())
                    .then((res) => {
                        if(res.status === 'success') {
                            this.$track.customEvent('Tellimus kustutatud', 'orderProcess', 'orderDeletedCount')
                            this.fetchOrders()
                            this.$notify({
                                group: 'foo',
                                type: 'warn',
                                title: 'Tellimus kustutatud!',
                            });
                        }
                    })
                }
            })
        },
    },

    created() {
        if(!this.isGuest) {
            this.fetchOrders()
        }
    }
       
}
</script>

<style scoped>
.componentWrapper {
    position: relative;
}
.orderPopupIcon {
    margin: 25px 20px 0px 0px;
    opacity: 1;
    cursor: pointer;
}
.orderPopupIcon:hover {
    opacity: 1;
}
.orderPopupIcon img {
    width: 34px;
    height: 34px;
}
.orderPopupIconFixed img {
    width: 34px;
    height: 34px;
}
.orderPopupIconMobile img {
    position: absolute;
    right: 57px;
    width: 30px;
    height: 30px;
}
.ordersAmountIcon {
    position: absolute;
    left: 22px;
    top: 15px;
    height: 21px;
    width: 21px;
    border: 1px solid rgba(73, 100, 98, 0);
    background: rgba(255, 177, 66, 1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
            box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
    -webkit-animation: pulse-yellow 2s infinite;
            animation: pulse-yellow 2s infinite;
    border-radius: 20px;
    text-align: center;
    color: white;
}
.orderPopupIconMobile .ordersAmountIcon {
    top: -7px;
    right: 52px;
    left: auto;
    height: 18px;
    width: 18px;
}
.ordersAmountIcon p {
    margin: 0;
    margin-top: -3px;
    font-weight: bold;
    font-size: 1.2rem;
}
.orderPopupIconMobile .ordersAmountIcon p {
    font-size: 1.1rem;
    margin-top: -3px;
}
.orderPopupIconFixed .ordersAmountIcon {
    left: 22px;
}
.noOrderNotification {
    margin-top: 120px; 
    text-align: center; 
    font-size: 1.8rem; 
    color: silver;
    padding-left: 25px;
    padding-right: 25px;
}
.viewAllBtn {
    position: absolute;
    bottom: 35px;
    left: 0;
    right: 0;
    margin: auto;
    height: 35px;
    margin-top: -5px;
    padding-top: 1px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #3d405600;
    border-radius: 3px;
    background: #3D4056;
    color: #EEEEEE;
    font-size: 1rem;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
}
.viewAllBtn:hover {
    background: #3D4056;
    color: #EEEEEE;
    opacity: .9;
}
.popupContainer {
    position: relative;
    border-radius: 10px;
    width: 650px;
    height: 359px;
    top: 70px;
    right: 7%;
    background: white;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    overflow: hidden;
}
.shopImage {
    position: absolute;
    height: 200px;
    width: 200px;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    opacity: .12;
    z-index: 0;
}
.header {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #62bd9d;
    background: #62bd9d;
    height: 40px;
}
.nameWrapper {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 40px;
    width: fit-content;
}
.shopName {
    margin: 0;
    margin-top: 6px;
    color: white;
    font-size: 24px;
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
}
span {
    margin-top: 5px;
    height: fit-content;
    width: fit-content;
    font-size: 22px;
    margin-left: 5px;
    color: rgb(50, 50, 50);
}
.previousArrow {
    position: absolute;
    left: 10px;
    height: 40px;
    width: 25px;
    -webkit-transform: rotate(90deg) translateZ(0);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
    cursor: pointer;
}
.nextArrow {
    position: absolute;
    right: 10px;
    height: 40px;
    width: 25px;
    -webkit-transform: rotate(270deg) translateZ(0);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
    cursor: pointer;
}
.contentHeader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    margin: 15px auto;
    margin-bottom: 25px;
    border-bottom: 1px solid rgb(230,230,230);
}
.contentHeader p {
    margin: 0;
    margin-top: 0px;
}
.contentHeader p:first-child {
    font-size: 20px;
    font-family: 'DM sans', sans-serif;
    color: rgb(50,50,50);
}
.contentHeader p:last-child {
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    margin-left: auto;
    padding-right: 3px;
    color: rgb(50,50,50);
}
.productTable {
    margin-top: -5px;
}
.tableIndex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 2px;
    padding-right: 3px;
    padding-left: 3px;
}
.tableIndex p {
    margin: 0;
    width: 15%;
    text-align: center;
    font-size: 18px;
    color: rgb(50,50,50);
    font-family: 'DM sans', sans-serif;
}
.tableIndex p:first-child {
    width: 52%;
    text-align: start;
}
.tableIndex p:last-child {
    width: 3%;
    cursor: pointer;
    color: gray
}
.tableContent {
    height: 150px;
    overflow: auto;
    scrollbar-width: thin;
    position: relative;
    z-index: 100;
}
.item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #ececec;
    padding-right: 3px;
    padding-left: 3px;
}
.item p {
    margin: 0 auto;
    width: 15%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    color: rgb(75,75,75);
    font-family: 'DM sans', sans-serif;
}
.item p:first-child {
    width: 52%;
    text-align: start;
}
.item p:last-child {
    width: 3%;
    cursor: pointer;
}
.title:hover {
    cursor: pointer;
    color: #64C3A2
}
.changeQtyBtn {
    opacity: .7;
    margin-bottom: -5px;
    margin-right: 3px;
    margin-left: 2px;
    cursor: pointer;
    height: 20px;
    width: 10px;
}
.changeQtyBtn:hover {
    opacity: .9;
}
.changeQtyBtnHidden {
    opacity: 0;
}
.item:hover .changeQtyBtnHidden {
    opacity: .7;
}
.item:hover .changeQtyBtnHidden:hover {
    opacity: 0.9;
}

.buttons {
    margin: 11px auto;
    margin-bottom: 11px;
    width: 90%;
    text-align: end;
}
.buttons button {
    width: 120px;
    border-radius: 2px;
    color: white;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    border: 1px solid #dddddd34;
    opacity: .9;
}
.buttons button:hover {
    opacity: 1;
    cursor: pointer;
}
.buttons button:first-child {
    margin-right: 7px;
    background: #FF5252;
}
.buttons button:last-child {
    margin-left: 7px;
    background: #64C3A2;
}
.allOrders {
    text-align: center;
    color: rgb(120, 156, 156);
    margin-top: -2px;
    cursor: pointer;
    padding-top: 10px;
    border-top: 1px solid rgb(231, 231, 231);
}
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  margin: 20px auto;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  z-index: 10;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-webkit-keyframes pulse-yellow {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
            box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
  }
  
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
            box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
  }
  
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
            box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
}
@keyframes pulse-yellow {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
            box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
  }
  
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
            box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
  }
  
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
            box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
}
@media (max-width: 749px) {
    .popupContainer {
        left: 50px;
        right: 0;
        margin: auto;
    }
}
@media (max-width: 750px) {
    .popupContainer {
        width: 95%;
        left: 0px;
        border-radius: 15px;
        left: 50%;
        -webkit-transform: translate(-50%,0%);
        -ms-transform: translate(-50%,0%);
            transform: translate(-50%,0%);
    }
    .currentIndex {
        right: 0%;
        left: 92%;
    }
}
@media (max-width: 550px) {
    #priceIndex {
        display: none;
    }
    .tableIndex p {
        width: 22.5%;
    }
    .item p {
        width: 22.5%;
    }
    .previousArrow {
        left: 5px;
    }
    .nextArrow {
        right: 5px;
    }
}
@media (max-width: 500px) {
    .nameWrapper {
        width: 100%;
    }
    span {
        position: absolute;
        right: 35px;
        margin-top: 4px;
    }
    .shopName {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        margin-top: 4px;
        width: 300px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }
}
@media (max-width: 430px) {
    .shopName {
        width: 200px;
    }
    .buttons {
        position: relative;
        width: 92%;
    }
    .buttons button {
        position: absolute;
        width: 49%;
    }
    .buttons button:first-child {
        margin-right: 0px;
        left: 0;
    }
    .buttons button:last-child {
        margin-left: 0px;
        right: 0;
    }
}
@media (max-width: 420px) {
    .noOrderNotification {
        font-size: 1.6rem;
    }
}
@media (max-width: 375px) {
    .item p {
        font-size: 15px;
    }
}
</style>
