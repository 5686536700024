import apiTools from '../api-tools/index'
import buildGetUserNotifications from './get-user-notifications_fetch'


const apiEndpoint = 'notification'

const getUserNotifications = buildGetUserNotifications({
    apiEndpoint,
    apiTools
})


const notificationApi = {
    getUserNotifications
}
export default notificationApi