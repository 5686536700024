const trackedEvents = {
    visitCount: {
        isActive: false
    },
    visitsFromMainBanner: {
        isActive: true
    },
    randomMerchantGenerateBtn: {
        isActive: true
    },
    shopVisitsCount: {
        isActive: true
    },
}

export default trackedEvents