import chatApi from './chat/index'
import userApi from './user/index'
import feedbackApi from './feedback/index'
import dealApi from './deal/index'
import walletApi from './finance/wallet'
import notificationApi from './notification/index'
import shopApi from './shop/index'
import shopInquiryApi from './shopInquiry/index'
import orderApi from './order/index'
import authApi from './auth/index'
import tagApi from './tag/index'
import filterApi from './tag/filter/index'
import listingApi from './listing/index'
import orgCommunicationApi from './orgCommunication/index'
import orgApi from './org/index'
import orgTaskApi from './orgTask/index'
import producerApi from './producer/index'
import salePointsApi from './salePoints/index'

const core = {
    chatApi,
    userApi,
    feedbackApi,
    dealApi,
    walletApi,
    notificationApi,
    shopApi,
    shopInquiryApi,
    orderApi,
    authApi,
    tagApi,
    filterApi,
    listingApi,
    orgCommunicationApi,
    orgApi,
    orgTaskApi,
    producerApi,
    salePointsApi
}


export default core