<template>
    <div class="componentWrapper" v-if="cookieConsentShow">
        <p>Kasutades Omatoodang.ee lehte, nõustute kasutus- ja privaatsustingimustega. <br> Loe lähemalt vajutades <a @click="$router.push('/tingimused/privaatsus')">SIIA</a></p>
        <button @click="acceptCookies">Nõustu</button>
    </div>
</template>

<script>

export default {
    name: 'cookieConsent',
    props: {
        
    },
    data() {
        return {
            cookieConsentShow: false
        }
    },

    methods: {
        acceptCookies() {
            localStorage.setItem('cookieConsent', true)
            this.$emit('cookieConsentAccepted')
        }
    },

    created() {
        setTimeout(() => {
            this.cookieConsentShow = true
        }, 1500)
    }
       
}
</script>

<style scoped>
.componentWrapper {
    position: fixed;
    bottom: 30px;
    left: 25px;
    z-index: 1000;
    width: 420px;
    padding-left: 25px;
    padding-right: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: white;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.18);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.18);
}
.componentWrapper p {
    width: 290px;
    font-size: .9rem;
    color: rgb(75, 75, 75);
}
.componentWrapper a {
    color: steelblue;
    cursor: pointer;
}
.componentWrapper a:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}
button {
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 130px;
    height: 34px;
    padding-bottom: 2px;
    border: 1px solid #dddddd00;
    border-radius: 5px;
    background: rgb(255, 255, 255);
    font-size: 1.1rem;
    cursor: pointer;
    background: #64C3A2;
    color: #EEEEEE;
    font-family: 'Lato', sans-serif;
}
button:hover {
    opacity: .9;
}

@media (max-width: 500px) {
    .componentWrapper {
        width: 80%;
        left: 10px;
    }
    .componentWrapper p {
        width: 80%;
        font-size: .9rem;
        color: rgb(75, 75, 75);
    }
    button {
        width: 100px;
        top: 60%;
    }
}
</style>
